const i18n = {
    "zh-cn": {
        "All Rights Reserved": "版权所有 ®",
        "Company YuYouMob": "天津鱼游信息科技有限公司",
        "privacy": "隐私政策",
        "https://beian.miit.gov.cn/": "津ICP备2020008222号-1",
        "Login Title": "国内移动视频广告变现优化专家",
        "Login Description": "为开发者提供专业的流量变现服务，为国内优质开发者而生，7x24 小时服务，提升您的收益。",
        "Login Button": "立即开通",
        "Login Card Title 0": "全面支持",
        "Login Card Description 0": "全面支持国内安卓和 iOS 的平台广告变现",
        "Login Card Title 1": "持续优化",
        "Login Card Description 1": "协助设计广告场景，提高广告变现效益，持续优化收益",
        "Login Card Title 2": "全新智能",
        "Login Card Description 2": "数据透明，基于大数据算法，提升变现效益",
        "Login Card Title 3": "全面支持",
        "Login Card Description 3": "全面支持国内安卓和 iOS 的平台广告变现",
        "Has Account": "已有账号",
        "GoTo Login": "马上登录",
        "Login Title 2": "产品介绍",
        "Register": "注册",
        "Login": "登录",
        "Username": "用户名",
        "Remember me": "记住我",
        'Please input your username!': "请输入用户名",
        'Please input your password!': "请输入密码",
        'Please input your email!': "请输入邮箱",
        'Please input your first name!': "请输入姓",
        'Please input your last name!': "请输入名",
        "The two passwords that you entered do not match!": "密码不一致",
        "Login Fail": "登录失败，请检查用户名与密码",
        "Register Success": "注册成功",
        "Welcome to Register Title": "欢迎使用 YuYouMob",
        "Welcome to Register Subtitle 1": "请您于 24 小时内到邮件箱 ",
        "Welcome to Register Subtitle 2": " 通过邮件激活您的账号",
        "Register Active Success": "账号激活成功！",
        "Register Active Success Subtitle": "欢迎使用 YuYouMob！您的账号已激活，请前往登录",
        "Register Fail": "注册失败",
        "Notification": "通知消息",
        "Logout": "退出登录",
        "Overview": "数据概览",
        "Top 30 Days": "最近 30 天表现最好的应用",
        "Empty Product": "虚位以待",
        "Estimaated Revenue": "预估收益",
        "Last Day Revenue": "昨日预估收益",
        "7 Days Revenue": "最近 7 天预估收益",
        "30 Days Revenue": "最近 30 天预估收益",
        "30 Days charts": "最近 30 天走势",
        "User Settings": "用户设置",
        "Manage Product": "应用管理",
        "Manage Placement": "广告位管理",
        "First Name": "姓",
        "Last Name": "名",
        "Password": "密码",
        "Confirm Password": "确认密码",
        "E-mail": "邮箱地址",
        "Phone Number": "联系电话",
        "QQ Number": "QQ 号",
        "WeiXin Number": "微信号",
        "Company": "公司名称",
        "Address": "详细地址",
        "Save": "保存",
        "Product ID": "应用 ID",
        "Product Name": "应用名称",
        "Platform": "平台",
        "AppStore": "苹果商店",
        "GooglePlay": "Google Play",
        "ChineseAndroid": "国内安卓",
        "Screen Orientation": "屏幕方向",
        "horizontal": "横屏",
        "vertical": "竖屏",
        "Product Status": "应用状态",
        "Unreview": "未审核",
        "Running": "运行中",
        "Disable": "禁用",
        "Reject": "审核未通过",
        "Reviewing": "审核中",
        "Product review confirm": "确定要提交审核吗？",
        "Edit": "编辑",
        "Review Product": "提交审核",
        "Application Review Step 1": "SDK 接入",
        "SDK install info": "SDK 接入信息",
        "SDK not support iOS 14 ATT": "未适配 iOS 14 以上系统",
        "SDK not support iOS 14 ATT description -999": "未使用 iOS 14 以上系统进行测试或未调用 requirePermissions 向用户申请追踪权限",
        "SDK not support iOS 14 ATT description -1": "未正确引入 ATTrackingManager.framework",
        "SDK not support iOS 14 ATT description -2": "Info.plist 中未正确配置 NSUserTrackingUsageDescription",
        "AD Adapters Count": "接入广告商插件数量",
        "SDK install error title": "SDK 接入错误",
        "SDK install error descript": "未成功接入广告聚合 SDK 或接入广告聚合 SDK 后未进行调用",
        "Application Review Step 2": "参数验证",
        "AD Parameter review info title": "GADApplicationIdentifier 验证",
        "AD Parameter review info descript": "需确保在您应用的 AndroidManifest.xml 文件中添加上方您的 AdMob 应用 ID ",
        "AD Parameter review info descript ios": "需确保在您应用的 Info.plist 文件中添加上方您的 AdMob 应用 ID ",
        "AD Parameter review info url": "具体参看 AdMob 官方文档",
        "AD Parameter review error title": "未在应用中添加 GADApplicationIdentifier",
        "AD Parameter review error descript": "请确保您的 AdMob 应用 ID已经置入您的应用中",
        "Application Review Step 3": "提交审核",
        "Application Review Check List": "提交审核前请检查",
        "Application Review Debug Check": "确保 Debug 模式已经关闭",
        "Application Review Download URL Check": "确保应用的下载链接可用",
        "Application Package SHA1 help title": "如何计算 SHA1",
        "Application Package SHA1 help content 1": "打开命令行执行命令",
        "Application Package SHA1 help content 2": "keytool -list -v -keystore XXXXXX.keystore",
        "Application Package SHA1 help content 3": "XXXXXX.keystore 为您应用打包时使用的证书文件",
        "Application Package SHA1 help content 4": "输入正确的密码后回车，在输出的信息中找到 SHA1 的值",
        "Create New Product": "注册新应用",
        "Application register require reCAPTCHA verify": "注册新应用需要进行 reCAPTCHA 验证；如果新应用注册失败，请刷新页面确保右下角 reCAPTCHA 验证成功",
        "Product Icon": "应用图标",
        "You can only upload JPG/PNG file!": "图标只能是 JPG 或 PNG 格式",
        "Image must smaller than 2MB!": "图标文件大小不得超过 2MB",
        "Download URL": "下载地址",
        "Search From AppStore": "在商店中搜索",
        "Search Fail": "搜索失败",
        "Product Description": "应用简介",
        "Close": "关闭",
        "Bundle": "包名",
        "Product Category": "应用分类",
        "Required and not empty": "必填项且不可为空",
        "Saving...": "保存中...",
        "Save successful": "保存成功！",
        "Save failure": "保存失败~",
        "Select Product": "选择应用",
        "Select Placement": "选择广告场景",
        "Select AD Network": "选择广告商",
        "Select AD Control": "选择控制项",
        "Create New Placement": "注册新广告位",
        "Placement Description Tips": "描述广告点出现的位置和频次设置，例如：关卡结束后观看激励视频获得双倍金币，没有观看频次限制等",
        "Placement ID": "广告位 ID",
        "Placement Name": "广告位名称",
        "Placement Category": "广告位类型",
        "Placement Size": "广告位尺寸",
        "Placement Status": "广告位状态",
        "Placement Description": "广告位简介",
        "rewarded_video": "激励视频",
        "interstitial_video": "插屏视频",
        "interstitial": "静态插屏",
        "banner": "Banner",
        "splash": "开屏广告",
        "native": "流媒体广告",
        "Width": "宽度",
        "Height": "高度",
        "Placement Floor": "广告位底价",
        "Enable or not": "是否启用",
        "Enable": "启用",
        "Valid": "有效",
        "Invalid": "失效",
        "Financial Settings": "财务信息",
        "Company Tab": "企业主体",
        "Company Address": "公司地址",
        "Unified credit code": "纳税人识别号",
        "Currency": "结算币种",
        "Currency Symbol": "￥",
        "Currency Symbol USD": "$",
        "Payee Name": "收款人姓名",
        "Access Number": "银行卡号",
        "Bank Name": "收款银行全称",
        "Bank Tips": "需要具体到支行",
        "Bank Address": "收款银行地址",
        "Personal Tab": "个人主体",
        "ID Name": "身份证姓名",
        "ID Number": "身份证号",
        "CNY": "人民币",
        "USD": "美元",
        "Financial Statement Review": "结算账单审核",
        "Edit Financial Statement" : "编辑结算账单",
        "Download Financial Statement": "下载结算账单",
        "Exchange Rate": "结算汇率",
        "Financial Statement": "账单结算",
        "Exchange Revenue": "结算金额",
        "Create Financial Settings First": "请先完善您的财务信息才能确保结算顺利！",
        "Financial Statement Generate Date": "当月 5 个工作日后生成上个自然月财务结算账单，财务结算周期为 N + 2，即：5 月份的结算账单将于 7 月份进行结算",
        "Financial Statement Generate Limit": "结算金额需高于 ￥3000 元以上可以申请结算",
        "Security Settings": "安全设置",
        "Security Key": "秘钥",
        "IP White List": "IP 地址白名单",
        "Reset Key": "重置秘钥",
        "Do you want to reset your secret key?": "是否确定要重置秘钥？",
        "IP White List Tips": "用于设置 API 请求的 IP 地址白名单，多个 IP 地址请以英文逗号分隔",
        "Menu User": "用户账户相关",
        "Menu AD": "广告应用相关",
        "Menu Admin": "高级管理功能",
        "Manage AD Parameter": "广告参数",
        "Manage AD Control": "控制参数",
        "Create New Parameter": "新建广告参数",
        "Create New Control": "新建控制参数",
        "AD Name": "广告商名称",
        "TouTiao": "穿山甲",
        "GuangDianTong":"优量汇",
        "KuaiShou":"快手联盟",
        "Control Name": "控制项",
        "Control Target": "控制目标",
        "Control Key": "控制属性",
        "Control Value": "控制值",
        "Manage Income Divide": "分成设置",
        "Bill Review": "账单审核",
        "Edit Bill": "编辑账单",
        "Bill Fetch": "数据拉取",
        "Fetch Bill": "拉取账单",
        "Download Excel": "下载数据报表",
        "Original Statistics": "原始数据",
        "Manage Alarm": "监控报警",
        "Menu Statistics Analysis": "数据相关",
        "AD Statistics Analysis": "广告数据统计",
        "User Statistics Analysis": "用户数据统计",
        "Search": "搜索",
        "Export Excel": "导出数据表",
        "Last 30 Days": "近 30 天",
        "Last 7 Days": "近 7 天",
        "Yesterday": "昨天",
        "Statistics Filter": "数据筛选",
        "All": "全部",
        "Filter By AD Category": "按广告类型筛选",
        "Filter By AD Placement": "按广告位筛选",
        "Date": "日期",
        "Start Date": "开始日期",
        "End Date": "结束日期",
        "Requests": "请求量",
        "Available": "填充量",
        "Impressions": "曝光量",
        "Request": "请求",
        "Fill": "填充",
        "Fill Rate": "填充率",
        "Show": "展示",
        "Show Rate": "展示率",
        "Play": "播放",
        "Complete": "完播",
        "Click": "点击",
        "Click Rate": "点击率",
        "Installs": "激活量",
        "Revenue": "预估收益",
        "eCPM": "eCPM",
        "Total": "总计",
        "Revenue Analysis": "收入统计分析",
        "AD Analysis": "广告行为统计分析",
        "Create New Income Divide": "新建分成配置",
        "Request Divide": "请求分成",
        "Fill Divide": "填充分成",
        "Show Divide": "展示分成",
        "Play Divide": "播放分成",
        "Complete Divide": "完播分成",
        "Click Divide": "点击分成",
        "Revenue Divide": "收入分成",
        "eCPM Price": "eCPM 价格",
        "eCPM Model": "eCPM 模式",
        "eCPM Model Default": "默认无保底",
        "eCPM Model Revenue": "保底 eCPM 调整展示",
        "eCPM Model Impression": "保底 eCPM 调整收入",
        "Volatility": "上下浮动",
        "Description": "备注",
        "Next": "下一步",
        "Weight": "权重",
        "Percentage": "占比",
        "Weight Placeholder": "权重数值越大优先级越高",
        "Percentage Placeholder": "目标用户人数占比",
        "Create New Bill": "录入广告商账单",
        "AD Network": "广告商",
        "AD Network APP ID": "广告商后台注册 APP ID",
        "AD Network APP ID placeholder": "如果没有可以为空",
        "AD Network APP Name": "广告商后台注册应用名称",
        "AD Network APP Name placeholder": "如果没有可以为空",
        "Country": "国家地区",
        "Country placeholder": "符合 ISO-3166 标准的两位国家代码",
        "Timezone": "时区",
        "AD Network Placement": "广告商后台注册的广告位ID",
        "AD Network AD Category": "广告商返回的广告位类型标识",
        "AD Network AD Category placeholder": "如果没有可以为空",
        "Status": "状态",
        "Autoreviewed": "自动审核",
        "Manualreviewed": "人工审核",
        "Only Revenue": "只显示有收入账单",
        "Only Reviewed": "只显示已审核账单",
        "Review all bills": "一键审核当前全部账单",
        "Are you sure to review all ad bills?": "确定要将当前账单全部公布？",
        "Publish or not": "是否公布",
        "Review User": "审核人",
        "AD Network Name": "广告插件名称",
        "Manage ADNetwork": "广告插件",
        "Create New ADNetwork":"新注册广告插件",
        "Required Key": "必要参数定义",
        "Placement Key": "广告位参数定义",
        "Add": "Add",
        "Create New Notification": "新建通知消息",
        "Title": "标题",
        "Content": "内容",
        "Receiver": "收件人",
        "Send email or not": "是否发送邮件",
        "Sender": "发件人",
        "Notification Send Email Warning": "注意！！！收件人为空时选择发送邮件则将向全体用户发送~",
        "Manage AD Plan": "广告售卖",
        "Select AD Plan": "选择广告售卖计划",
        "Select AD Plan Target": "选择广告售卖目标",
        "Create New AD Plan": "新建广告售卖计划",
        "Plan Name": "计划名称",
        "Edit AD Plan User": "设定售卖人群",
        "Edit AD Plan Target": "设定售卖目标",
        "Edit AD Plan Strategy": "制定售卖策略",
        "Edit AD Plan Limit": "制定售卖上限",
        "Finish AD Plan": "完成售卖计划",
        "AD Plan": "广告售卖计划",
        "Optional": "可选",
        "Create New __adplan_user": "新建人群筛选规则",
        "Control Key __adplan_user": "人群筛选规则",
        "Create New __adplan_target": "新建售卖规则",
        "Control Key __adplan_target": "售卖规则",
        "Create New __adplan_strategy": "新建售卖策略",
        "Control Key __adplan_strategy": "售卖策略",
        "Create New __adplan_limit": "新建售卖上限",
        "Control Key __adplan_limit": "售卖上限目标",
        "AD Plan Default Target": "默认售卖",
        "Filter by application and placement": "按应用与广告位筛选",
        "Filter by AD plan and target": "按广告售卖计划筛选",
        "Filter by AD network": "按广告商筛选",
        "AD Impression Percentage": "广告展示占比",
        "AD Show Times User Percentage": "观看次数分布",
        "Times": "次",
        "Users": "人",
        "Bundle ID": "应用包名",
        "Bundle Version": "应用版本",
        "SDK Version": "广告 SDK 版本",
        "AD Adapters": "接入广告商插件",
        "App Sign": "应用签名",
        "Product Package Info": "应用接入信息",
        "Manage AD Waterfall": "广告瀑布流",
        "Create New AD Waterfall": "新建广告瀑布流",
        "Waterfall Name": "瀑布流名称",
        "Floor": "层级",
        "Reserve Price": "底价",
        "AD Slot": "广告位",
        "Select AD Waterfall": "选择广告瀑布流方案",
        "SDK Document": "SDK 接入文档",
        "iOS Document": "iOS 接入文档",
        "Android Document": "Android 接入文档",
        "Unity Document": "Unity 接入文档",
        "User Analysis": "用户统计",
        "Register User": "新增",
        "Active User": "活跃",
        "AD View User":"观看人数",
        "AD View Rate":"广告观看率",
        "AD Analysis AD View User":"广告人均观看",
        "Retention User": "留存",
        "User Register And Active": "用户新增与活跃",
        "User Retention Heat Map": "用户留存热力图",
        "User Retention Rate 1day": "次留",
        "User Retention Rate 2day": "2 日留存",
        "User Retention Rate 3day": "3 日留存",
        "User Retention Rate 5day": "5 日留存",
        "User Retention Rate 7day": "7 日留存",
        "User Retention Rate 10day": "10 日留存",
        "User Retention Rate 15day": "15 日留存",
        "User Retention Rate 30day": "30 日留存",
        "Statistics Analysis Revenue Warning": "请注意！广告收益数据统计略有延迟，预计当天晚 20:00 前才能完整返回前一天的广告收益，敬请谅解~",
        "Statistics Analysis Currency Warning": "请注意！广告收益统一以人民币（CNY）单位显示，美元结算收益部分因受汇率浮动影响可能会跟随浮动变化，最终收益以结账单为准，因此带来的不便，敬请谅解~",
        "Download SDK": "点击下载 SDK",
        "Download SDK Tips Title": "请联系工作人员索取~",
        "Download SDK Tips Content": "通过邮件 developer@yuyoumob.com 或直接联系工作人员索取最新版本 SDK",
        "Download SDK Tips Email": "发送邮件",
        "Android SDK only support chinese market Warning": "请注意！Android 平台 SDK 仅适用于国内安卓市场，暂不支持海外 Google Play，敬请谅解~",
        "Import SDK": "导入广告 SDK",
        "Support iOS 14": "适配 iOS 14",
        "AD Work Flow": "广告调用流程",
        "Initialize SDK": "初始化 SDK",
        "Use Rewarded Video AD": "使用激励视频广告",
        "Use Interstitial AD": "使用静态插屏广告",
        "Use Interstitial Video AD": "使用插屏视频广告",
        "Add AD Delegate": "广告行为监听",
        "Add Video AD Delegate": "视频广告特有行为监听",
        "Add Reward AD Delegate": "激励视频广告特有行为监听",
        "Add Implementations": "添加依赖",
        "Add Other Linker Flags": "设置编译选项",
        "Support HTTP": "开启 HTTP 支持",
        "Add Permissions": "添加权限申请",
        "Import iOS SDK descript text": "将 SDK 中的 framework 和 bundle 全部导入到 iOS 工程中。",
        "Add iOS implementations descript text": "请将下列依赖库添加到 iOS 工程中。",
        "Add iOS linker flags descript text": "在 Xcode 中选择项目对应 Targets 中的 Build Settings 选项卡里找到 Other Linker Flags 编译选项，在其中增加 -ObjC",
        "iOS linker flags alert text": "注意 -ObjC 的大小写，请严格按照大小写书写，且不要忘记前面的 “-”",
        "Support iOS HTTP descript text": "在 Xcode 中选择项目对应 Targets 中的 Info 选项卡里编辑 Custom iOS Target Properties 在其中增加 App Transport Security Settings 设置值为 YES",
        "Support iOS HTTP descript text 2": "在 Info.plist 文件中正确配置后可以看到形如下面的配置项目：",
        "Add iOS permissions descript text": "在 Xcode 中选择项目对应的 Info.plist 文件，点中选择进入编辑状态，在编辑栏中找到 Information Property List 点击右边的 + 号，在展开的下拉菜单中选择添加如下几个权限：",
        "Add iOS permissions code message": "在 Info.plist 文件中正确配置后可以看到形如下面的配置项目：",
        "Support iOS 14 descript text": "从 iOS14 开始将支持 AppTrackingTransparency（简称ATT）和 SKAdNetwork。需要开发者针对 iOS14 做特殊的适配。",
        "Add AppTrackingTransparency": "AppTrackingTransparency 权限申请",
        "Add AppTrackingTransparency descript text": "如同上一节介绍的“添加权限申请”，在 Xcode 中选择项目对应的 Info.plist 文件，点中选择进入编辑状态，在编辑栏中找到 Information Property List 点击右边的 + 号，在展开的下拉菜单中选择添加",
        "Add AppTrackingTransparency code message": "在 Info.plist 文件中正确配置后可以看到形如下面的配置项目：",
        "Add SKAdNetworks": "SKAdNetworks ID 配置",
        "Add SKAdNetworks descript text 1": "SKAdNetwork 是 Apple 针对 iOS 14 ATT 的归因解决方案，可在保护用户隐私的同时完成广告归因。 使用 SKAdNetwork 后，即使 IDFA 不可用，也可以正确获得广告应用安装的归因结果。 为了支持使用 Apple 的转化跟踪 SKAdNetwork，需要在 Info.plist 文件中增加如下广告商的归因 SKAdNetwork ID",
        "Add SKAdNetworks descript text 2": "在 Xcode 中选择项目对应的 Info.plist 文件，点中选择进入编辑状态，在编辑栏中找到 Information Property List 点击右边的 + 号，在展开的下拉菜单中选择添加",
        "Add SKAdNetworks descript text 3": "将用到的广告商插件对应的 SKAdNetworkIdentifier 以字典的形式加入到刚刚创立的 SKAdNetworkItems 数组中。",
        "Add SKAdNetworks descript text 4": "广告商对应的 SKAdNetworkIdentifier 如下：",
        "Add SKAdNetworks code message": "在 Info.plist 文件中正确配置后可以看到形如下面的配置项目：",
        "Initialize iOS SDK descript text": "在 ViewController.m 的 viewDidLoad 方法中调用 SDK 初始化方法进行 SDK 的初始化工作。",
        "Initialize iOS SDK code message": "初始化 SDK 代码",
        "SDK Parameters": "所需参数及解释：",
        "SDK Parameter App ID": "在平台注册的应用 ID",
        "SDK Parameter Secret Key": "在平台注册的账号的安全秘钥",
        "SDK Parameter Is Debug": "在开发测试阶段开启测试模式",
        "SDK Parameter AD Placement": "在平台注册的广告位 ID",
        "Load AD": "加载广告",
        "Load AD code message": "加载广告示例代码",
        "Show AD": "播放广告",
        "Show AD code message": "播放广告示例代码",
        "Add iOS AD Delegate descript text": "激励视频广告、静态插屏广告、插屏视频广告均可以通过 load 方法传入 delegate 来监听如下事件用来响应广告的不同行为。",
        "AD Delegate code message": "广告行为监听示例代码",
        "onAdLoaded": "广告加载完毕",
        "onAdLoadFail": "广告加载失败",
        "onAdShow": "广告成功展示",
        "onAdShowFail": "广告展示失败",
        "onAdClick": "广告被点击",
        "onAdClose": "广告被关闭",
        "onAdVideoStartPlay": "广告视频开始播放",
        "onAdVideoPlayComplete": "广告视频播放完毕",
        "onAdVideoSkip": "广告视频被用户跳过",
        "onRewardedToUser": "激励视频广告达到激励条件，可以给予玩家奖励激励",
        "Add iOS Video AD Delegate descript text": "激励视频广告、插屏视频广告等视频类型的广告均可以通过 setVideoAdDelegate 方法传入 delegate 来监听如下事件用来响应视频广告的不同行为。",
        "Add iOS Rewarded Video AD Delegate descript text": "激励视频广告可以通过 setRewardedVideoAdDelegate 方法传入 delegate 来监听如下事件用来响应激励视频广告的不同行为。",
        "Import Android SDK descript text": "Android 广告 SDK 下载并解压后本身是一个可以构建并可以运行的 Demo 示例工程。",
        "Add Android implementations descript text 1": "请将 Demo 示例工程中 app/libs 文件夹下的所有 aar 文件导入到你的工程中。",
        "Add Android implementations descript text 2": "并参照 Demo 示例工程中 app/build.gralde 文件中的 implementation 配置引入需要的依赖。",
        "Add Android permissions descript text": "请参照 Demo 示例工程中 app/src/main/AndroidManifest.xml 文件中权限申请的配置，将所需的权限引入到你的工程中。",
        "Add Proguard": "Proguard 混淆配置",
        "Add Android proguard descript text": "如果你的工程开启了 Progurad 混淆，那么请参照 Demo 示例工程中 app/proguard-rules.pro 文件的混淆配置引入到你的工程的混淆配置中。",
        "Initialize Android SDK descript text": "在 Activity 的 onCreate 方法中调用 SDK 初始化方法进行 SDK 的初始化工作。",
        "Initialize Android SDK code message": "初始化 SDK 代码",
        "Add Android AD Delegate descript text": "激励视频广告、静态插屏广告、插屏视频广告均可以通过 setAdListener 方法传入 com.yuyoumob.admediation.sdk.AdListener 来监听如下事件用来响应广告的不同行为。",
        "Add Android Video AD Delegate descript text": "激励视频广告、插屏视频广告等视频类型的广告均可以通过 setVideoAdListener 方法传入 com.yuyoumob.admediation.sdk.VideoAdListener 来监听如下事件用来响应视频广告的不同行为。",
        "Add Android Rewarded Video AD Delegate descript text": "激励视频广告可以通过 setRewardedVideoAdListener 方法传入 com.yuyoumob.admediation.sdk.RewardedVideoAdListener 来监听如下事件用来响应激励视频广告的不同行为。",
        "Import Unity SDK descript text 1": "将下载到的 unitypackage 双击导入到你的 Unity 工程中即可。",
        "Import Unity SDK descript text 2": "SDK 附带一个 Demo 演示场景",
        "Import Unity SDK descript text 3": "你可以新建一个空白的 Unity 工程，导入 SDK 后，通过展示这个场景构建一个 Demo 程序来体验我们的广告服务。",
        "Import Unity SDK descript text 4": "SDK 附带的 Demo 演示场景的脚本文件",
        "Import Unity SDK descript text 5": "你可以通过阅读这部分代码来学习 SDK 的使用。",
        "AD Work Flow descript text": "同一个广告场景的广告，在调用加载方法加载广告后，要在接收到广告加载失败的回调，或将广告进行展示，接收到广告关闭的回调后，方可进行下一个广告的加载。同一个广告场景的广告资源在消耗掉前切勿多次进行加载。",
    }
};


export function trans(key, options={}) {
    var userLang = options.userLang;
    if (!userLang) {
        userLang = navigator.language || navigator.userLanguage;
    }
    if (!!userLang) {
        userLang = (''+userLang).toLocaleLowerCase();
    }
    var lang = i18n[userLang];
    if (!lang) {
        if (!!options.defaultValue) {
            return options.defaultValue;
        }
        console.warn(userLang+' not found in i18n so could not to translation: '+key);
        return options.defaultValue || key;
    }
    var ret = lang[key];
    if (!ret) {
        if (!!options.defaultValue) {
            return options.defaultValue;
        }
        console.warn(key+' not found in i18n '+userLang);
        return options.defaultValue || key;
    }
    return ret;
};
