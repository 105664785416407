import React from 'react';
import { Select } from 'antd';
import { GetRequest } from './Ajax';

const { Option } = Select;

export class UserSelect extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            current: null,
            options: []
        };
    };

    componentDidMount(){
        this.fetch();
    };

    fetch(){
        GetRequest('/users')
            .then(resp => {
                var options = [];
                for (var i in resp.data) {
                    const user = resp.data[i];
                    var name = user.username;
                    var value = user.id;
                    if (!!this.props.showkey) {
                        if (Array.isArray(this.props.showkey)) {
                            name = this.props.showkey.map(k=>!!k.key?k:{key:k, prefix:' '}).filter(k=>!!user[k.key]).map(k=>(k.prefix||'')+user[k.key]).join('');
                        } else if ("string"=== typeof this.props.showkey && !!user[this.props.showkey]) {
                            name = user[this.props.showkey];
                        }
                    } else {
                        if (user.first_name && user.last_name) {
                            name = user.first_name + " . "+user.last_name
                        }
                        if (user.company) {
                            name = (name || "") + " - "+user.company;
                        }
                    }
                    if (!!this.props.usevalue && !!user[this.props.usevalue]) {
                        value = user[this.props.usevalue];
                    }
                    options.push(
                        <Option key={user.id} value={value}>
                            {name}
                        </Option>
                    );
                }
                this.setState({
                    current: resp.data.current,
                    options: options
                });
            });
    };

    render(){
        return (
            <div>
                {this.state.options.length > 1 &&
            <Select
                {...this.props}
                showSearch
                defaultValue={this.state.current}
                filterOption={(input, option)=>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                }
            >
                {this.state.options}
            </Select>
                }
            </div>
        );
    };
};
