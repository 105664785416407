import React from 'react';
import { Tabs, Form, Input, Row, Col, Button, Modal, message } from 'antd';
import { SaveOutlined, RedoOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';

const { TabPane } = Tabs;

const state = getState();

export class UserSettings extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.state = {
            loading: false,
            resetKeyLoading: false,
            resetKeyConfirm: false,
            user: {
                first_name: "",
                last_name: "",
                password: "",
                repassword: "",
                email: "",
                phone: "",
                qq: "",
                weixin: "",
                company: "",
                address: "",
                secret_key: ""
            }
        }
    }

    fetch() {
        GetRequest('/user')
            .then(resp => {
                this.setState({user: resp.data});
                this.formRef.current.resetFields();
            });
    }

    componentDidMount() {
        this.fetch();
        this.unsubscribe = state.subscribe(() => {
            if (!!state.getState().token) {
                this.fetch();
            }
        });
    }

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    };

    compareToFirstPassword = (rule, value) => {
        if (!!value && value !== this.formRef.current.getFieldValue('password')) {
            return Promise.reject('Tow passwords that you enter is inconsistent!');
        } else {
            return Promise.resolve();
        }
    };

    confirmResetKey = () => {
        this.setState({resetKeyConfirm: true});
    }

    handleResetKey = () => {
        this.setState({resetKeyLoading: true});
        PostRequest('/user/reset_key', {})
            .then(resp => {
                this.setState({
                    user: resp.data,
                    resetKeyLoading: false,
                    resetKeyConfirm: false
                });
                this.formRef.current.resetFields();
            }).catch(error=>{
                this.setState({
                    resetKeyLoading: false,
                    resetKeyConfirm: false
                })
            });
    }

    handleSubmit = (values) => {
        this.setState({loading: true});
        PostRequest('/user', values)
            .then(resp => {
                this.setState({
                    loading: false,
                    user: resp.data});
                message.success(_("Save successful"))
            }).catch(error=>{
                this.setState({
                    loading: false
                });
                message.error(_("Save failure"));
            });
    }

    render() {
        const formItemLayout = {
            labelCol: {
              xs: { span: 12 },
              sm: { span: 4 },
            },
            wrapperCol: {
              xs: { span: 36 },
              sm: { span: 20 },
            },
          };
        const tailFormItemLayout = {
          wrapperCol: {
            xs: {
              span: 36,
              offset: 12,
            },
            sm: {
              span: 20,
              offset: 4,
            },
          },
        };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("User Settings")} key="user_settings">
                        <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleSubmit} initialValues={this.state.user} >
                            <Form.Item label={_("Security Key")}>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item name={['ad_mediation_user', 'secret_key']} noStyle>
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Button icon={<RedoOutlined/>} loading={this.state.resetKeyLoading} onClick={this.confirmResetKey}>{_("Reset Key")}</Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item label={_("First Name")} name='first_name'>
                                <Input />
                            </Form.Item>
                            <Form.Item label={_("Last Name")} name='last_name'>
                                <Input />
                            </Form.Item>
                            <Form.Item label={_("Password")} name='password' >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label={_("Confirm Password")} name='repassword'
                                rules={[
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ]}>
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label={_("E-mail")} name='email'
                                rules={[
                                    {
                                        type: "email"
                                    }
                                ]} >
                                <Input />
                            </Form.Item>
                            <Form.Item label={_("Phone Number")} name={['ad_mediation_user', 'phone']} >
                                <Input />
                            </Form.Item>
                            <Form.Item label={_("QQ Number")} name={['ad_mediation_user', 'qq']} >
                                <Input />
                            </Form.Item>
                            <Form.Item label={_("WeiXin Number")} name={['ad_mediation_user', 'weixin']} >
                                <Input />
                            </Form.Item>
                            <Form.Item label={_("Company")} name={['ad_mediation_user', 'company']}>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item label={_("Address")} name={['ad_mediation_user', 'address']}>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item {...tailFormItemLayout} >
                                <Button icon={<SaveOutlined/>} loading={this.state.loading} size="large" type="primary" htmlType="submit" >{_("Save")}</Button>
                            </Form.Item>
                        </Form>
                        <Modal
                            title={_("Reset Key")}
                            icon={<ExclamationCircleOutlined />}
                            visible={this.state.resetKeyConfirm}
                            onOk={this.handleResetKey}
                            confirmLoading={this.state.resetKeyLoading}
                            onCancel={()=>{this.setState({resetKeyConfirm:false});}}
                        >
                            <p>{_("Do you want to reset your secret key?")}</p>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}