import React from 'react';
import { Menu } from 'antd';
import { 
    TableOutlined, DashboardOutlined, AppstoreOutlined, 
    ProjectOutlined, LineChartOutlined, UserOutlined,
    SettingOutlined, IdcardOutlined, BankOutlined, ToolOutlined,
    FilterOutlined, CloudDownloadOutlined, DollarOutlined, 
    ControlOutlined, ApiOutlined, NotificationOutlined, BarcodeOutlined,
    BarsOutlined, ReadOutlined, AccountBookOutlined, AuditOutlined,
    BarChartOutlined, PieChartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { GetRequest } from './Ajax';
import { trans as _ } from './i18n';

const { SubMenu } = Menu;

export class SiderMenu extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            menus: {}
        };
    };

    fetch(){
        GetRequest('/menu')
            .then(resp => {
                this.setState({menus: resp.data});
            })
            .catch(error => {
                this.setState({menus: {}});
            });
    };

    componentDidMount(){
        this.fetch();
    };

    render() {
        return (
            <Menu
                style={{ width: 200 }}
                defaultSelectedKeys={['/main/overview']}
                defaultOpenKeys={['submenu_application', 'submenu_statistics_analysis', 'submenu_user', 'submenu_admin']}
                mode="inline"
            >
                <SubMenu
                    key="submenu_application"
                    title={
                        <span>
                            <TableOutlined />
                            <span>{_("Menu AD")}</span>
                        </span>
                    }
                >
                    <Menu.Item key="/main/overview">
                        <Link to="/main/overview"><DashboardOutlined />{_("Overview")}</Link>
                    </Menu.Item>
                    <Menu.Item key="/main/application">
                        <Link to="/main/application"><AppstoreOutlined />{_("Manage Product")}</Link>
                    </Menu.Item>
                    <Menu.Item key="/main/placement">
                        <Link to="/main/placement"><ProjectOutlined />{_("Manage Placement")}</Link>
                    </Menu.Item>
                    <Menu.Item key="/main/sdk_document">
                        <Link to="/main/sdk_document"><ReadOutlined />{_("SDK Document")}</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="submenu_statistics_analysis"
                    title={<span><BarChartOutlined /><span>{_("Menu Statistics Analysis")}</span></span>}
                >
                    <Menu.Item key="/main/user_statistics_analysis">
                        <Link to="/main/user_statistics_analysis"><PieChartOutlined />{_("User Statistics Analysis")}</Link>
                    </Menu.Item>
                    <Menu.Item key="/main/ad_statistics_analysis">
                        <Link to="/main/ad_statistics_analysis"><LineChartOutlined />{_("AD Statistics Analysis")}</Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu
                    key="submenu_user"
                    title={
                        <span>
                            <UserOutlined />
                            <span>{_("Menu User")}</span>
                        </span>
                    }
                >
                    <Menu.Item key="/main/user_settings">
                        <Link to="/main/user_settings"><IdcardOutlined />{_("User Settings")}</Link>
                    </Menu.Item>
                    <Menu.Item key="/main/financial_settings">
                        <Link to="/main/financial_settings"><BankOutlined />{_("Financial Settings")}</Link>
                    </Menu.Item>
                    <Menu.Item key="/main/financial_statement">
                        <Link to="/main/financial_statement"><AccountBookOutlined />{_("Financial Statement")}</Link>
                    </Menu.Item>
                </SubMenu>
                {Object.keys(this.state.menus).length > 0 && <SubMenu
                    key="submenu_admin"
                    title={
                        <span>
                            <ToolOutlined />
                            <span>{_("Menu Admin")}</span>
                        </span>
                    }
                >

                    {!!this.state.menus["restapi.manage_publishers_bill_divide"] && <Menu.Item key="bill_fetch">
                        <Link to="/main/bill_fetch"><CloudDownloadOutlined />{_("Bill Fetch")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.read_other_bill"] && <Menu.Item key="bill_review">
                        <Link to="/main/bill_review"><DollarOutlined />{_("Bill Review")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_ad_waterfall"] && <Menu.Item key="/main/adwaterfall">
                        <Link to="/main/adwaterfall"><BarsOutlined />{_("Manage AD Waterfall")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_ad_plan"] && <Menu.Item key="/main/adplan">
                        <Link to="/main/adplan"><BarcodeOutlined />{_("Manage AD Plan")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_adnetwork"] && <Menu.Item key="/main/adnetwork">
                        <Link to="/main/adnetwork"><ApiOutlined/>{_("Manage ADNetwork")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_ad_parameter"] && <Menu.Item key="/main/parameter">
                        <Link to="/main/parameter"><SettingOutlined/>{_("Manage AD Parameter")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_ad_control"] && <Menu.Item key="/main/control">
                        <Link to="/main/control"><ControlOutlined/>{_("Manage AD Control")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_publishers_income_divide"] && <Menu.Item key="/main/income_divide">
                        <Link to="/main/income_divide"><FilterOutlined />{_("Manage Income Divide")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_financial_statement"] && <Menu.Item key="statement_review">
                        <Link to="/main/statement_review"><AuditOutlined />{_("Financial Statement Review")}</Link>
                    </Menu.Item>}

                    {!!this.state.menus["restapi.manage_notification"] && <Menu.Item key="manage_notification">
                        <Link to="/main/notification"><NotificationOutlined />{_("Notification")}</Link>
                    </Menu.Item>}

                </SubMenu>}
            </Menu>
        );
    };
}
