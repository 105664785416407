import React from 'react';
import { Row, Modal, Button, Collapse, Tag, Tabs, Form, Typography,
    Space, Avatar, Input, Switch, message, InputNumber, Cascader } from 'antd';
import { PlusOutlined, EditOutlined, CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { AppSelect } from './AppSelect';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import { PlacementSelect } from './PlacementSelect';

const state = getState();

export class Waterfall extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            step: 0,
            user_id: state.getState().userId,
            product_uid: "",
            product: {},
            placement_uid: "",
            waterfall: {},
            placement_parameters: [],
            waterfall_list: [],
        };
    }

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.setState({
                    user_id: state.getState().userId,
                    product_uid: "",
                    product: {},
                    placement_uid: "",
                    waterfall: {},
                    placement_parameters: [],
                    waterfall_list: [],
                });
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleSelectProduct = (uid, entry) => {
        this.setState({
            product_uid: uid,
            product: entry,
            placement_uid: "",
            waterfall: {},
            placement_parameters: [],
            waterfall_list: [],
        });
    }

    handleSelectPlacement = (uid, entry) => {
        if (!!uid && !!this.state.product_uid) {
            this.setState({loading: true, placement_uid: uid})
            GetRequest('/waterfall/'+this.state.product_uid+'/'+uid)
                .then(resp => {
                    this.setState({
                        loading: false,
                        waterfall: {},
                        waterfall_list: [...resp.data.waterfall_list],
                        placement_parameters: [...resp.data.placement_parameters],
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        waterfall: {},
                        placement_parameters: [],
                        waterfall_list: [],
                    });
                });
        }
    }

    handleCreateNew = () => {
        this.setState({
            modalVisible: true,
            waterfall: {
                id: null,
                name: '',
                status: true,
                description: '',
            }
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleEdit = (entry) => {
        this.setState({
            modalVisible: true,
            waterfall: entry
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        this.setState({saveLoading: true});
        var url = '/waterfall/'+this.state.product_uid+'/'+this.state.placement_uid;
        if (!!this.state.waterfall && !!this.state.waterfall.id) {
            url = url + '/' + this.state.waterfall.id;
        }
        PostRequest(url, values)
            .then(resp => {
                var waterfall = resp.data;
                var waterfall_list = [];
                if (!!this.state.waterfall.id) {
                    waterfall_list = this.state.waterfall_list.map(entry => {
                        if (entry.id===waterfall.id) {
                            return waterfall;
                        } else {
                            return entry;
                        }
                    });
                } else {
                    waterfall_list = [waterfall, ...this.state.waterfall_list];
                }
                this.setState({
                    saveLoading: false,
                    waterfall_list: waterfall_list,
                    waterfall: waterfall
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    handleAddWaterfallItem = (entry, values, order_by) => {
        if (!!values && Array.isArray(values.ad) && values.ad.length > 1) {
            var adnetwork = values.ad[0];
            var ad_placement = values.ad[1];
            var ad_parameters = this.state.placement_parameters.filter(pp => pp.ad===adnetwork)[0];
            if (!!ad_parameters && Array.isArray(ad_parameters.params)) {
                var parameters = ad_parameters.params.filter(ap => !!ap.ad_placement && ap.ad_placement===ad_placement)[0];
                if (!!parameters) {
                    if (undefined===values.status) {
                        values.status = true;
                    }
                    var data = {
                        order_by: order_by,
                        ad: adnetwork,
                        status: values.status,
                        floor: values.floor,
                        reserve_price: values.reserve_price,
                        placement_parameters: JSON.stringify(parameters),
                    };
                    var url = '/waterfall_item/'+this.state.product_uid+'/'+this.state.placement_uid+'/'+entry.id;
                    this.setState({saveLoading: true});
                    PostRequest(url, data)
                        .then(resp => {
                            this.setState({
                                saveLoading: false,
                                waterfall_list: [...resp.data],
                            });
                            message.success(_("Save successful"));
                        })
                        .catch(error => {
                            this.setState({saveLoading: false});
                            message.error(_("Save failure"));
                        });
                }
            }
        }
    }

    render(){
        const { TabPane } = Tabs;
        const { Title } = Typography;
        const { Panel } = Collapse;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage AD Waterfall")} key="manage_ad_waterfall">
                        <Row>
                            <AppSelect 
                                size="large" 
                                style={{width: 400, marginBottom: 16, marginRight: 4}} 
                                user_id={this.state.user_id}
                                value={this.state.product_uid}
                                onChange={this.handleSelectProduct} />
                            <PlacementSelect
                                size="large"
                                style={{width: 400, marginBottom: 16, marginRight: 4}} 
                                product_uid={this.state.product_uid}
                                value={this.state.placement_uid}
                                onChange={this.handleSelectPlacement} />
                            <Button 
                                icon={<PlusOutlined />} 
                                size="large" 
                                type="primary"
                                disabled={ !(!!this.state.product_uid && !!this.state.placement_uid) }
                                onClick={this.handleCreateNew}
                            >{_("Create New AD Waterfall")}</Button>
                        </Row>
                        <Collapse accordion defaultActiveKey={Array.isArray(this.state.waterfall_list) && !!this.state.waterfall_list[0] ? [this.state.waterfall_list[0].id] : ['1']}>
                            {this.state.waterfall_list.map(entry => (
                                <Panel header={entry.status ? entry.name : <span><Tag color="red">{_("Disable")}</Tag>{entry.name}</span>} key={entry.id} extra={<EditOutlined onClick={evt => {this.handleEdit(entry);}} />}>
                                    {Array.isArray(entry.items) && entry.items.map((ep, epi) => {
                                        var placement_parameters = JSON.parse(ep.placement_parameters);
                                        var initialValues = {
                                            ad: [ep.ad, placement_parameters.ad_placement],
                                            floor: ep.floor,
                                            reserve_price: ep.reserve_price,
                                            status: ep.status,
                                        };
                                        var valid = false;
                                        if (Array.isArray(this.state.placement_parameters)) {
                                            var adPlacementParams = this.state.placement_parameters.filter(pp => pp.ad===ep.ad)[0];
                                            if (!!adPlacementParams && Array.isArray(adPlacementParams.params)) {
                                                var found = adPlacementParams.params.filter(app => {
                                                    var f = false;
                                                    for (var ppk in placement_parameters) {
                                                        f = undefined!==app[ppk] && placement_parameters[ppk] === app[ppk];
                                                        if (false===f) {
                                                            break;
                                                        }
                                                    }
                                                    return f;
                                                })[0];
                                                valid = !!found;
                                            }
                                        }
                                        return (<Form key={epi} layout="inline" style={{marginBottom: 16}} initialValues={initialValues} onFinish={(values) => {this.handleAddWaterfallItem(entry, values, epi); }}>
                                        <Form.Item name="ad" label={<span>{valid ? <Tag color="success">{_("Valid")}</Tag> : <Tag color="error">{_("Invalid")}</Tag>}{_("AD Slot")}</span>}>
                                            <Cascader 
                                                style={{width: 400, }}
                                                options={this.state.placement_parameters.map(em=>{
                                                    return {
                                                        value: em.ad, 
                                                        label: em.name, 
                                                        children: em.params.map(p=>{
                                                            return {
                                                                value: p.ad_placement,
                                                                label: p.ad_placement,
                                                            }
                                                        })
                                                    }})
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item name="floor" label={_("Floor")}>
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item name="reserve_price" label={_("Reserve Price")}>
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item name="status" valuePropName="checked">
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button loading={this.state.saveLoading} icon={<SaveOutlined/>} htmlType="submit"></Button>
                                        </Form.Item>
                                    </Form>);})}
                                    <Form layout="inline" onFinish={(values) => {this.handleAddWaterfallItem(entry, values, entry.items.length); }}>
                                        <Form.Item name="ad" label={_("AD Slot")}>
                                            <Cascader 
                                                style={{width: 400, }}
                                                options={this.state.placement_parameters.map(em=>{
                                                    return {
                                                        value: em.ad, 
                                                        label: em.name, 
                                                        children: em.params.map(p=>{
                                                            return {
                                                                value: p.ad_placement,
                                                                label: p.ad_placement,
                                                            }
                                                        })
                                                    }})
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item name="floor" label={_("Floor")}>
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item name="reserve_price" label={_("Reserve Price")}>
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button loading={this.state.saveLoading} icon={<PlusOutlined/>} htmlType="submit"></Button>
                                        </Form.Item>
                                    </Form>
                                </Panel>
                            ))}
                        </Collapse>
                        <Modal
                            visible={this.state.modalVisible}
                            title={this.state.product ? <Space><Avatar shape="square" src={this.state.product.icon} size={32} alt={this.state.product.uid} /><Title level={3}>{this.state.waterfall.name||_("Create New AD Waterfall")}</Title></Space>: ""}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.waterfall}>
                                <Form.Item label={_("Waterfall Name")} name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: _("Required and not empty")
                                        }
                                    ]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("Placement Description")} name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message: _("Required and not empty")
                                        }
                                    ]}>
                                    <Input.TextArea rows={6} placeholder={_("Description")} />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}