import React from 'react';
import { Row, Modal, Button, Tag, Tabs, Table, Form, Typography, Steps,
    Space, Avatar, DatePicker, Input, Switch, message, InputNumber } from 'antd';
import { PlusOutlined, EditOutlined, CloseOutlined, SaveOutlined, StepForwardOutlined } from '@ant-design/icons';
import { AppSelect } from './AppSelect';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { PlacementSelect } from './PlacementSelect';
import { PlanControl } from './PlanControl';

const state = getState();

export class Plan extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            step: 0,
            user_id: state.getState().userId,
            product_uid: "",
            product: {},
            plans: [],
            plan: {
                id: null,
                placement_uid: '',
                name: '',
                status: true,
                weight: 0,
                percentage: 100,
                description: '',
                date: [moment().add(1, 'days'), moment().add(31, 'days')],
            },
            target: null,
            strategy: null,
        };
        this.unsubscribe = null;
    };

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.setState({
                    user_id: state.getState().userId,
                    product_uid: "",
                    plans: []
                });
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleSelectProduct = (puid, entry) => {
        if (!!puid) {
            this.setState({loading: true});
            GetRequest('/adplan/'+puid)
                .then(resp => {
                    this.setState({
                        loading: false,
                        product_uid: puid,
                        product: entry,
                        plans: [...resp.data],
                    });
                }).catch(error => {
                    this.setState({loading: false});
                });
        }
    }

    handleCreateNew = () => {
        this.setState({
            modalVisible: true,
            plan: {
                id: null,
                placement_uid: '',
                name: '',
                status: true,
                weight: 0,
                percentage: 100,
                description: '',
                date: [moment().add(1, 'days'), moment().add(31, 'days')],
            }
        });
    }

    handleEdit = (entry) => {
        if (!!entry.start_time && !!entry.end_time) {
            entry['date'] = [
                moment(entry.start_time, "YYYY-MM-DD"), 
                moment(entry.end_time, "YYYY-MM-DD")];
        }
        this.setState({
            modalVisible: true,
            plan: entry
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        values['start_time'] = values.date[0].format("YYYY-MM-DD 00:00:00");
        values['end_time'] = values.date[1].format("YYYY-MM-DD 00:00:00");
        var url = "/adplan/"+this.state.product_uid;
        if (!!this.state.plan.id) {
            url += "/"+this.state.plan.id;
        }
        this.setState({saveLoading: true});
        PostRequest(url, values)
            .then(resp => {
                var plan = resp.data;
                var plans = [];
                if (!!this.state.plan.id) {
                    plans = this.state.plans.map(entry => {
                        if (entry.id===plan.id) {
                            return plan;
                        } else {
                            return entry;
                        }
                    });
                } else {
                    plans = [plan, ...this.state.plans];
                }
                this.setState({
                    saveLoading: false,
                    plan: plan,
                    plans: plans
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    handleNextStep = (entry) => {
        this.setState({
            step: 1,
            plan: entry,
        });
    }

    handleTargetStep = (entry) => {
        this.setState({
            step: 2,
        })
    }

    handleStrategyStep = (entry) => {
        this.setState({
            step: 3,
            target: entry,
        });
    }

    handleFinalStep = (entry) => {
        this.setState({
            step: 4,
            strategy: entry,
        });
    }

    handleFinishPlan = (entry) => {
        this.setState({
            step: 0,
            plan: {},
            target: null,
            strategy: null,
        });
    }

    render(){
        const columns = [
            {
                title: _("Plan Name"),
                render: (txt, entry) => entry.name
            },
            {
                title: _("Enable or not"),
                render: (txt, entry) => {
                    if (!!entry && !!entry.status) {
                        return (<Tag color="green">{_("Enable")}</Tag>);
                    } else {
                        return (<Tag color="red">{_("Disable")}</Tag>);
                    }
                }
            },
            {
                title: _("Percentage"),
                render: (txt, entry) => entry.percentage+" %"
            },
            {
                title: _("Weight"),
                render: (txt, entry) => entry.weight
            },
            {
                title: _("Start Date"),
                render: (txt, entry) => moment(entry.start_time, "YYYY-MM-DD").format("YYYY-MM-DD")
            },
            {
                title: _("End Date"),
                render: (txt, entry) => moment(entry.end_time, "YYYY-MM-DD").format("YYYY-MM-DD")
            },
            {
                render: (txt, entry) => {
                    return (<Button icon={<EditOutlined />} onClick={(evt)=>{this.handleEdit(entry);}}>{_("Edit")}</Button>);
                }
            },
            {
                render: (txt, entry) => {
                return (<Button icon={<StepForwardOutlined />} onClick={(evt)=>{this.handleNextStep(entry);}}>{_("Next")}</Button>);
                }
            },
        ];
        const { TabPane } = Tabs;
        const { Title } = Typography;
        const { RangePicker } = DatePicker;
        const { Step } = Steps;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage AD Plan")} key="manage_adplan">
                        <Row>
                            <Steps className="plan-steps" current={this.state.step}>
                                <Step title={_("Create New AD Plan")}></Step>
                                <Step title={_("Edit AD Plan User")} subTitle={"("+_("Optional")+")"}></Step>
                                <Step title={_("Edit AD Plan Target")}></Step>
                                <Step title={_("Edit AD Plan Strategy")}></Step>
                                <Step title={_("Finish AD Plan")}></Step>
                            </Steps>
                        </Row>
                        {0 === this.state.step && (<div>
                        <Row>
                            <AppSelect 
                                size="large" 
                                style={{width: 400, marginBottom: 16, marginRight: 4}} 
                                user_id={this.state.user_id}
                                value={this.state.product_uid}
                                onChange={this.handleSelectProduct} />
                            <Button 
                                icon={<PlusOutlined />} 
                                size="large" 
                                type="primary"
                                disabled={ !this.state.product_uid || this.state.product_uid.length===0 }
                                onClick={this.handleCreateNew}
                            >{_("Create New AD Plan")}</Button>
                        </Row>
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={this.state.plans}
                            loading={this.state.loading}
                            pagination={false}
                        />
                        </div>)}
                        {1 === this.state.step && (<div>
                        <Title level={3}>{_("Edit AD Plan User")}</Title>
                        <Title level={4}>{_("AD Plan")+": "+this.state.plan.name+" [ "+this.state.plan.percentage+"% ]"}</Title>
                        <PlanControl plan={this.state.plan} category="__adplan_user" show_next="true" next_label={_("Next")} onNextStep={(entry)=>{this.handleTargetStep(entry);}}/>
                        </div>)}
                        {2 === this.state.step && (<div>
                        <Title level={3}>{_("Edit AD Plan Target")}</Title>
                        <Title level={4}>{_("AD Plan")+": "+this.state.plan.name+" [ "+this.state.plan.percentage+"% ]"}</Title>
                        <PlanControl plan={this.state.plan} category="__adplan_target" show_next_col="true" onNextStep={(entry)=>{this.handleStrategyStep(entry);}}/>
                        </div>)}
                        {3 === this.state.step && (<div>
                        <Title level={3}>{_("Edit AD Plan Strategy")}</Title>
                        <Title level={4}>{_("AD Plan")+": "+this.state.plan.name+" [ "+this.state.plan.percentage+"% ]"}</Title>
                        <PlanControl plan={this.state.plan} plan_target={this.state.target} category="__adplan_strategy" show_next_col="true" onNextStep={(entry)=>{this.handleFinalStep(entry);}} />
                        </div>)}
                        {4 === this.state.step && (<div>
                        <Title level={3}>{_("Edit AD Plan Limit")}</Title>
                        <Title level={4}>{_("AD Plan")+": "+this.state.plan.name+" [ "+this.state.plan.percentage+"% ]"}</Title>
                        <PlanControl plan={this.state.plan} plan_strategy={this.state.strategy} category="__adplan_limit" show_next="true" onNextStep={(entry)=>{this.handleFinishPlan(entry);}} />
                        </div>)}
                        <Modal
                            visible={this.state.modalVisible}
                            title={this.state.product ? <Space><Avatar shape="square" src={this.state.product.icon} size={32} alt={this.state.product.uid} /><Title level={3}>{this.state.plan.name||_("Create New AD Plan")}</Title></Space>: ""}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.plan}>
                                <Form.Item label={_("Select Placement")} name="placement_uid" rules={[
                                        {
                                            required: true,
                                            message: _("Required and not empty")
                                        }
                                    ]}>
                                    <PlacementSelect 
                                        product_uid={this.state.product_uid} />
                                </Form.Item>
                                <Form.Item label={_("Plan Name")} name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: _("Required and not empty")
                                        }
                                    ]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={_("Percentage")} name="percentage" rules={[
                                    {
                                        required: true,
                                        message: _("Percentage Placeholder")
                                    }
                                ]}>
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')} />
                                </Form.Item>
                                <Form.Item label={_("Weight")} name="weight" placeholder={_("Weight Placeholder")} rules={[
                                        {
                                            required: true,
                                            message: _("Weight Placeholder")
                                        }
                                    ]}>
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("Date")} name="date"
                                    rules={[
                                        {type: 'array', required: true, message: 'Please select time!'}
                                    ]}
                                >
                                    <RangePicker />
                                </Form.Item>
                                <Form.Item label={_("Placement Description")} name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message: _("Required and not empty")
                                        }
                                    ]}>
                                    <Input.TextArea rows={6} placeholder={_("Description")} />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}
