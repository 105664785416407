import React from 'react';
import { Select } from 'antd';
import { trans as _ } from './i18n';

const { Option } = Select;

export class PlacementCategorySelect extends React.Component {
    render(){
        return (
            <Select {...this.props} >
                {!!this.props.showall && <Option value="">{_("All")}</Option>}
                <Option value="rewarded_video">{_("rewarded_video")}</Option>
                <Option value="interstitial">{_("interstitial")}</Option>
                <Option value="interstitial_video">{_("interstitial_video")}</Option>
            </Select>
        );
    };
};
