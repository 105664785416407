import React from 'react';
import { Select } from 'antd';
import { GetRequest } from './Ajax';
import { trans as _ } from './i18n';

const { Option } = Select;

export class ADPlanSelect extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            options: []
        };
        this.unmount = false;
        this.adPlanList = [];
        this.adPlanMap = {};
    };

    fetch = ()=>{
        if (!!this.props.product_uid) {
            var url = '/adplan/'+this.props.product_uid;
            GetRequest(url)
            .then(resp => {
                this.adPlanList = resp.data;
                this.adPlanMap = {};
                for (var i in resp.data) {
                    var entry = resp.data[i];
                    this.adPlanMap[entry.id] = entry;
                }
                this.filter();
                if (!!this.props.onLoaded) {
                    this.props.onLoaded(this.adPlanMap);
                }
            });
        } else {
            this.filter();
        }
    };

    filter = () => {
        var options = [];
        if (this.props.showall==='true') {
            options.push(
                <Option key="adplan_all" value="">
                    {_("All")}
                </Option>
            );
        }
        if (!!this.props.product_uid && !!this.props.placement_uid) {
            this.adPlanList.filter(entry => {
                if (entry.placement_uid !== this.props.placement_uid) {
                    return false;
                }
                return true;
            }).forEach(entry => {
                options.push(
                    <Option key={entry.id} value={entry.id}>
                        {entry.name}
                    </Option>
                );
            });
        }
        if (!this.unmount) {
            this.setState({options: options});
        }
    }

    componentDidMount(){
        this.fetch();
    };

    componentWillUnmount(){
        this.unmount = true;
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.product_uid !== this.props.product_uid) {
            this.fetch();
        }
        if (prevProps.placement_uid !== this.props.placement_uid) {
            this.filter();
        }
    }

    handleProductChange(value){
        if (!!this.props.onChange) {
            this.props.onChange(value, this.adPlanMap[value]||{});
        }
    };

    render() {
        return (
            <Select
                {...this.props}
                showSearch
                placeholder={_("Select AD Plan")}
                optionFilterProp="children"
                onChange={(v)=>{this.handleProductChange(v)}}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
            >
                {this.state.options}
            </Select>
        );
    };
};
