import React from 'react';
import { Select } from 'antd';
import { GetRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';

const { Option } = Select;

const state = getState();

export class PlacementSelect extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            options: []
        };
        this.unmount = false;
        this.unsubscribe = null;
        this.placementList = [];
        this.placementMap = {};
    };

    fetch(){
        this.placementMap = {};
        this.placementList = [];
        
        if (!!this.props.product_uid) {
            GetRequest('/placements/'+this.props.product_uid)
                .then(resp => {
                    this.placementList = resp.data;
                    for (var i in resp.data) {
                        var product = resp.data[i];
                        this.placementMap[product.uid] = product;
                        
                    }
                    this.filter();
                    if (!!this.props.onLoaded) {
                        this.props.onLoaded(this.placementMap);
                    }
                });
        } else {
            this.filter();
        }
    };

    filter = () => {
        var options = [];
        if (!!this.props.showall && !this.props.placement_uid) {
            options.push(
                <Option key="placement_all" value="">
                    {_("All")}
                </Option>
            );
        }
        if (!!this.props.product_uid) {
            this.placementList.filter(entry => {
                if (!!this.props.placement_uid && entry.uid !== this.props.placement_uid) {
                    return false;
                }
                if (!!this.props.placement_category && entry.category !== this.props.placement_category) {
                    return false;
                }
                return true;
            }).forEach(entry => {
                options.push(
                    <Option key={entry.uid} value={entry.uid}>
                        {entry.name}
                    </Option>
                );
            });
        }
        if (!this.unmount) {
            this.setState({options: options});
        }
    }

    componentDidMount(){
        this.fetch();
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.fetch();
            }
        });
    };

    componentWillUnmount(){
        this.unmount = true;
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.product_uid !== this.props.product_uid) {
            this.fetch();
        }
        if (prevProps.placement_uid !== this.props.placement_uid) {
            this.filter();
        }
        if (prevProps.placement_category !== this.props.placement_category) {
            this.filter();
        }
    }

    handleProductChange(value){
        if (!!this.props.onChange) {
            this.props.onChange(value, this.placementMap[value]);
        }
    };

    render() {
        return (
            <Select
                {...this.props}
                showSearch
                placeholder={_("Select Placement")}
                optionFilterProp="children"
                onChange={(v)=>{this.handleProductChange(v)}}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
            >
                {this.state.options}
            </Select>
        );
    };
};
