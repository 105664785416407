import React from 'react';
import { Tabs, Form, Input, Button, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _} from './i18n';
import { CurrencySelect } from './CurrencySelect';

const { TabPane } = Tabs;

const state = getState();

export class FinancialSettings extends React.Component {

    companyFormRef = React.createRef();
    personalFormRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            personal: null,
            company: null
        };
        this.unsubscribe = null;
    };

    fetch(){
        GetRequest('/financial_settings')
        .then(resp => {
            this.setState({
                personal: resp.data.personal || null,
                company: resp.data.company || null
            });
            if (!!this.companyFormRef && !!this.companyFormRef.current) {
                this.companyFormRef.current.resetFields();
            }
            if (!!this.personalFormRef && !!this.personalFormRef.current) {
                this.personalFormRef.current.resetFields();
            }
        });
    };

    componentDidMount(){
        this.fetch();
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.fetch();
            }
        });
    };

    componentWillUnmount(){
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    };

    handleSubmit = (values) => {
        var url = '/financial_settings';
        if (!!values.credit_code) {
            url += '/company';
            this.setState({
                loading: true,
                personal: null
            });
        } else if (!!values.id_number) {
            url += "/personal";
            this.setState({
                loading: true,
                company: null
            });
        } else {
            this.setState({loading: true});
        }
        PostRequest(url, values)
            .then(resp => {
                if (!!resp.data.credit_code) {
                    this.setState({
                        loading: false,
                        company: resp.data,
                        personal: null
                    });
                    this.companyFormRef.current.resetFields();
                } else if (!!resp.data.id_number) {
                    this.setState({
                        loading: false,
                        company: null,
                        personal: resp.data
                    });
                    this.personalFormRef.current.resetFields();
                }
                message.success(_("Save successful"))
            }).catch(error=>{
                console.log('err = ',error);
                this.setState({
                    loading: false
                });
                message.error(_("Save failure"));
            });
    };

    render(){
        const formItemLayout = {
            labelCol: {
              xs: { span: 12 },
              sm: { span: 4 },
            },
            wrapperCol: {
              xs: { span: 36 },
              sm: { span: 20 },
            },
          };
        const tailFormItemLayout = {
          wrapperCol: {
            xs: {
              span: 36,
              offset: 12,
            },
            sm: {
              span: 20,
              offset: 4,
            },
          },
        };
        return (
            <div className="card-container">
            <Tabs type="card">
                {this.state.company && <TabPane tab={_("Company Tab")} key="company">
                    <Form {...formItemLayout} ref={this.companyFormRef} onFinish={this.handleSubmit} initialValues={this.state.company} >
                        <Form.Item label={_("Company")} name='company_name' 
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={_("Company Address")} name='company_address' 
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={_("Unified credit code")} name='credit_code'
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={_("Currency")} name='currency'
                            rules={[
                                {
                                    required: true
                                }
                            ]}>
                            <CurrencySelect />
                        </Form.Item>
                        <Form.Item label={_("Payee Name")} name="payee_name"
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={_("Access Number")} name='bank_account'
                            rules={[
                                    {
                                        required: true,
                                        message: _("Required and not empty")
                                    }
                                ]}>
                                <Input />
                        </Form.Item>
                        <Form.Item label={_("Bank Name")} name='bank_full_name'
                            rules={[
                                    {
                                        message: _("Bank Tips")
                                    },
                                    {
                                        required: true,
                                        message: _("Required and not empty")
                                    }
                                ]}>
                                <Input />
                        </Form.Item>
                        <Form.Item label={_("Bank Address")} name='bank_address'
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} >
                            <Button 
                                type="primary" 
                                icon={<SaveOutlined/>} 
                                size="large"
                                htmlType="submit" 
                                loading={this.state.loading} 
                            >{_("Save")}
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>}
                {this.state.personal && <TabPane tab={_("Personal Tab")} key="personal">
                    <Form {...formItemLayout} ref={this.personalFormRef} onFinish={this.handleSubmit} initialValues={this.state.personal} >
                        <Form.Item label={_("ID Name")} name='id_name' 
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={_("ID Number")} name='id_number'
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={_("Currency")} name='currency'
                            rules={[
                                {
                                    required: true
                                }
                            ]}>
                            <CurrencySelect />
                        </Form.Item>
                        <Form.Item label={_("Payee Name")} name="payee_name"
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={_("Access Number")} name='bank_account'
                            rules={[
                                    {
                                        required: true,
                                        message: _("Required and not empty")
                                    }
                                ]}>
                                <Input />
                        </Form.Item>
                        <Form.Item label={_("Bank Name")} name='bank_full_name'
                            rules={[
                                    {
                                        message: _("Bank Tips")
                                    },
                                    {
                                        required: true,
                                        message: _("Required and not empty")
                                    }
                                ]}>
                                <Input />
                        </Form.Item>
                        <Form.Item label={_("Bank Address")} name='bank_address'
                            rules={[
                                {
                                    required: true,
                                    message: _("Required and not empty")
                                }
                            ]}>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} >
                            <Button 
                                type="primary" 
                                icon={<SaveOutlined/>} 
                                size="large"
                                htmlType="submit" 
                                loading={this.state.loading} 
                            >{_("Save")}
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>}
            </Tabs>
            </div>
        );
    };
};
