import React from 'react';
import { Table, Tag, Button, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { GetRequest } from './Ajax';
import { trans as _ } from './i18n';

export class PlacementList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            data: []
        };
    };

    componentDidMount(){
        if (this.props.product_uid) {
            this.fetch({
                product_uid: this.props.product_uid
            });
        }
    };

    fetch(params){
        if (!params || !params.product_uid) {
            this.setState({data: []});
            return;
        }
        this.setState({loading: true});
        GetRequest('/placements/'+params.product_uid)
            .then(resp => {
                this.setState({loading: false});
                if (!!resp.data) {
                    for (var i in resp.data) {
                        if (!resp.data[i]['key']) {
                            resp.data[i]['key'] = i;
                        }
                    }
                }
                this.setState({
                    loading: false,
                    data: resp.data,
                });
            });
    };

    updatePlacement(placement){
        var found = false;
        var placements = this.state.data;
        if (!!placement && !!placements) {
            for (var i in placements) {
                if (placements[i]['uid']===placement.uid) {
                    found = true;
                    for (var key in placement) {
                        placements[i][key] = placement[key];
                    }
                    break;
                }
            }
            if (!found) {
                placement['key'] = placements.length;
                placements = [placement, ...placements];
            }
            this.setState({data: placements});
        }
    };

    handlePlacementEdit = (evt, placement) => {
        if (!!this.props.onEdit) {
            this.props.onEdit(placement);
        }
    };

    render() {
        var self = this;
        const { Paragraph } = Typography;
        const columns = [
            {
                title: _("Placement ID"),
                render: (text, entry) => (<Paragraph copyable>{entry.uid}</Paragraph>)
            },
            {
                dataIndex: 'name',
                title: _("Placement Name"),
            },
            {
                title: _("Placement Category"),
                render: (text, placement) => {
                    return _(placement.category);
                }
            },
            {
                title: _("Placement Size"),
                render: (text, placement) => {
                    return placement.width+" x "+placement.height;
                }
            },
            {
                title: _("Placement Status"),
                render: (text, placement) => {
                    if (placement.status===true) {
                        return (<Tag color="green">{_("Running")}</Tag>);
                    } else {
                        return (<Tag color="red">{_("Disable")}</Tag>);
                    }
                }
            },
            {
                dataIndex: 'description',
                title: _("Placement Description")
            },
            {
                render: (text, placement) => {
                    if (this.props.noActions) {
                        return (<span />);
                    } else {
                        return (<Button icon={<EditOutlined />} onClick={(evt)=>{self.handlePlacementEdit(evt, placement);}}>{_("Edit")}</Button>);
                    }
                }
            }
        ];
        const {loading, data} = this.state;
        return (
            <Table
                rowKey="uid"
                columns={columns}
                dataSource={data}
                loading={loading}
                pagination={false}
             />
        );
    };
};
