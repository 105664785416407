import React from 'react';
import logo from './logo.svg';
import './App.less';
import { Layout, ConfigProvider, Drawer, Empty, Collapse, Tag } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { SiderMenu } from './SiderMenu';
import { UserSelect } from './UserSelect';
import { UserInfo } from './UserInfo';
import { getState } from './Reducer';
import { Route, Switch } from 'react-router-dom';
import { Overview } from './Overview';
import { Application } from './Application';
import { Placement } from './Placement';
import { UserStatisticsAnalysis } from './UserStatisticsAnalysis';
import { ADStatisticsAnalysis } from './ADStatisticsAnalysis';
import { UserSettings } from './UserSettings'
import { FinancialSettings } from './FinancialSettings';
import { FinancialStatement } from './FinancialStatement';
import { FinancialStatementReview } from './FinancialStatementReview';
import { Parameter } from './Parameter';
import { Control } from './Control';
import { Plan } from './Plan';
import { Waterfall } from './Waterfall';
import { IncomeDivide } from './IncomeDivide';
import { BillFetch } from './BillFetch';
import { BillReview } from './BillReview';
import { ADNetwork } from './ADNetwork';
import { Notification } from './Notification';
import { Document } from './Document';
import { trans as _ } from './i18n';
import { GetRequest, PostRequest } from './Ajax';
import moment from 'moment';
import 'moment/locale/zh-cn';

const { Header, Sider, Content, Footer } = Layout;

const state = getState();

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notificationDrawerVisible: false,
      notificationBadgeCount: 0,
      notificationList: []
    }
    this.unsubscribe = null;
  };

  componentDidMount() {
    this.unsubscribe = state.subscribe(() => {
      if (!state.getState().token) {
        this.props.history.push("/login");
      }
    });
    this.fetchNotifications();
  };

  componentWillUnmount() {
    if (!!this.unsubscribe) {
      this.unsubscribe();
    }
  };

  handleUserSwitch(uid){
    state.dispatch({
      type: 'USER_SWITCH',
      payload: uid
    })
  };

  fetchNotifications = () => {
    GetRequest('/notifications')
      .then(resp => {
        var lastRead = moment(resp.data.last_read);
        var notificationList = resp.data.notifications.map(entry => {
          entry.isNew = lastRead.isBefore(moment(entry.update_time));
          return entry;
        });
        this.setState({
          notificationList: notificationList,
          notificationBadgeCount: notificationList.filter(entry=>entry.isNew).length
        });
      })
      .catch(error => {
        this.setState({
          notificationList: [],
          notificationBadgeCount: 0
        });
      })
  }

  readNotifications = () => {
    PostRequest('/notifications');
  }

  onNotificationClose = () => {
    this.setState({
      notificationDrawerVisible: false
    });
    this.fetchNotifications();
  }

  handleUserInfoClick = (evt) => {
    if (evt==='notification') {
      this.setState({
        notificationBadgeCount: 0,
        notificationDrawerVisible: true
      });
      this.readNotifications()
    } else if (evt==='logout') {
      state.dispatch({
        type: 'NEED_LOGIN'
      });
    } else {
      console.log('Unknown: ', evt);
    }
  }

  render() {
    const { Panel } = Collapse;
    return (
      <ConfigProvider locale={zhCN}>
          <div className="App">
            <Layout>
              <Header>
                <img
                  src={logo}
                  shape="square"
                  style={{ float: 'left', height: '48px', margin: "8px 24px 8px 0" }}
                  alt="" />
                <UserSelect
                  style={{ float: 'left', width: '400px', margin: "12px 24px 12px 0" }}
                  size="large"
                  showkey={[{key:"email"},{key:"first_name", prefix:" - "},{key: "last_name", prefix: " . "}]}
                  onChange={this.handleUserSwitch}
                />
                <UserInfo
                  style={{ float: 'right', color: 'white', lineHeight: '64px', height: '64px',}}
                  badge_count={this.state.notificationBadgeCount}
                  onMenuItemClick={this.handleUserInfoClick}
                />
              </Header>
              <Layout>
                <Sider><SiderMenu></SiderMenu></Sider>
                <Content ref="content">
                  <Switch>
                    <Route path="/main/overview" component={ Overview } />
                    <Route path="/main/application" component={ Application } />
                    <Route path="/main/placement" component={ Placement } />
                    <Route path="/main/user_statistics_analysis" component={ UserStatisticsAnalysis } />
                    <Route path="/main/ad_statistics_analysis" component={ ADStatisticsAnalysis } />
                    <Route path="/main/user_settings" component={ UserSettings } />
                    <Route path="/main/financial_settings" component={ FinancialSettings } />
                    <Route path="/main/financial_statement" component={ FinancialStatement } />
                    <Route path="/main/adnetwork" component={ ADNetwork } />
                    <Route path="/main/parameter" component={ Parameter } />
                    <Route path="/main/control" component={ Control } />
                    <Route path="/main/income_divide" component={ IncomeDivide } />
                    <Route path="/main/bill_fetch" component={ BillFetch } />
                    <Route path="/main/bill_review" component={ BillReview } />
                    <Route path="/main/statement_review" component={ FinancialStatementReview } />
                    <Route path="/main/notification" component={ Notification } />
                    <Route path="/main/adplan" component={ Plan } />
                    <Route path="/main/adwaterfall" component={ Waterfall } />
                    <Route path="/main/sdk_document" component={ Document } />
                  </Switch>

                </Content>
              </Layout>
              <Footer style={{ textAlign: 'center' }}>
                  <p>
                      <span>{_("All Rights Reserved")+" "+moment().format("YYYY")+" "+_("Company YuYouMob")}</span>
                      <span> | </span>
                      <a target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn/">{_("https://beian.miit.gov.cn/")}</a>
                      <span> | </span>
                      <a href="/privacy.html" target="_blank"rel="noopener noreferrer" >{_("privacy")}</a>
                  </p></Footer>
            </Layout>
            <Drawer
                title={_("Notification")}
                width="30%"
                placement="right"
                closable={false}
                onClose={this.onNotificationClose}
                visible={this.state.notificationDrawerVisible}
                key="notification_drawer"
            >
                {(this.state.notificationList.length>0 ? (
                  <Collapse defaultActiveKey={[0]} bordered={false}>
                    {this.state.notificationList.map((entry, idx) => {
                      return (
                        <Panel header={<div>{entry.title}{entry.isNew && <Tag color="error" >new</Tag>}</div>} key={idx}>
                          <p>{entry.content}</p>
                          <p style={{textAlign: "right"}}>{moment(entry.update_time).format("YYYY-MM-DD HH:mm:ss")}</p>
                        </Panel>
                      );
                    })}
                  </Collapse>
                ) : (<Empty />))}
            </Drawer>
          </div>
        </ConfigProvider>
    );
  };
}

export default App;
