import React from 'react';
import { Modal, Button, Table, Form, Input, Select, Switch, message, InputNumber, Popover } from 'antd';
import { PlusOutlined, CloseOutlined, SaveOutlined, 
    MinusCircleOutlined, StepForwardOutlined, CheckOutlined } from '@ant-design/icons';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import { PlacementSelect } from './PlacementSelect';
import { ADNetworkSelect } from './ADNetworkSelect';
import { PlacementCategorySelect } from './PlacementCategorySelect';
import { ADWaterfallSelect} from './ADWaterfallSelect';

const state = getState();

export class PlanControl extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            user_id: state.getState().userId,
            controls: [],
            template: [],
            waterfall_list: [],
            id: null,
            rules: {},
            target: "",
            key: "",
            status: true,
            int_val:0,
            float_val: 0,
            str_val: ['1','2','3'],
        };
        this.unsubscribe = null;
    };

    componentDidMount = () => {
        this.fetch();
    }

    fetch = () => {
        if (!!this.props.plan) {
            this.setState({loading: true});
            GetRequest('/control/'+this.props.plan.product.uid+'/adplan_'+this.props.plan.id)
                .then(resp => {
                    var rules = {};
                    var template = resp.data.template.filter(entry=>entry.id===this.props.category)[0];
                    if (!!template) {
                        rules = template.rules;
                    }
                    var controls = resp.data.controls.map(entry=>{
                        if (!!entry.str_val && (typeof entry.str_val === 'string' || entry.str_val instanceof String)) {
                            entry.str_val = JSON.parse(entry.str_val);
                        }
                        return entry;
                    });
                    controls = controls.filter(entry => {
                        if (!!rules) {
                            if (this.props.category==='__adplan_limit' && !!this.props.plan_strategy && Array.isArray(this.props.plan_strategy.str_val)) {
                                return this.props.plan_strategy.str_val.indexOf(entry.key) >= 0;
                            } else if (this.props.category==='__adplan_strategy') {
                                return (entry.key==='__ad_strategy' || entry.key==='__ad_waterfall') && entry.target===this.props.plan_target.target;
                            } else if (Array.isArray(rules.key_choices)) {
                                return !!rules.key_choices.filter(kc=>kc.value===entry.key)[0];
                            }
                        }
                        return false;
                    });
                    this.setState({
                        loading: false,
                        template: [...resp.data.template],
                        controls: controls,
                        waterfall_list: [...resp.data.waterfall_list],
                        category: this.props.category,
                        key: "",
                        status: true,
                        int_val:0,
                        float_val: 0,
                        str_val: [],
                        rules: rules,
                    });
                }).catch(error => {
                    this.setState({loading: false});
                });
        }
    }

    handleCreateNew = () => {
        this.setState({
            id: null,
            modalVisible: true
        });
    }

    handleKeySelect = (value) => {
        this.setState({key: value});
        if (!!this.state.rules && Array.isArray(this.state.rules.key_choices)) {
            var option = this.state.rules.key_choices.filter(entry=>entry.value===value)[0];
            if (!!option && !!option.value_type) {
                this.setState({
                    rules: {...this.state.rules, value_type: option.value_type, value_choices: option.value_choices}
                });
                if (!!this.formRef.current) {
                    setTimeout(()=>{
                        this.formRef.current.resetFields();
                    }, 0);
                }
            }
        }
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        var data = {
            category: "adplan_"+this.props.plan.id,
            target: values.target,
            key: values.key,
            status: values.status,
            int_val: values.int_val,
            float_val: values.float_val,
            data: values.str_val || []
        };
        var value_type = "";
        if (!!this.state.rules && !!this.state.rules.value_type) {
            value_type = this.state.rules.value_type;
            if (value_type==='by_key') {
                var option = this.state.rules.key_choices.filter(t=>t.value===values.key)[0];
                if (!!option && !!option.value_type) {
                    value_type = option.value_type;
                }
            }
        }
        if (!!this.state.rules && !!this.state.rules.target_choices) {
            var target_choices = this.state.rules.target_choices;
            if ((""+target_choices).substr(0,2)==="__") {
                if (this.props.category==='__adplan_strategy' && !!this.props.plan_target) {
                    data["target"] = this.props.plan_target.target;
                } else if (this.props.category==='__adplan_limit' && !!this.props.plan_strategy) {
                    data["target"] = this.props.plan_strategy.target;
                } else {
                    data["target"] = this.state.rules.target_choices;
                }
            } else if (target_choices==="by_kv") {
                if (value_type==="int_val") {
                    data["target"] = values.key+"_"+values.int_val;
                } else if (value_type==="float_val") {
                    data["target"] = values.key+"_"+ Number.parseFloat(values.float_val).toFixed(2);
                } else {
                    data["target"] = values.key;
                }
            }
        }
        if (!!this.state.rules && !!this.state.rules.key_choices) {
            var key_choices = this.state.rules.key_choices;
            if (key_choices==='same_target') {
                data['key'] = values.target || "";
            }
        }
        if (Array.isArray(data.data)) {
            data.data = data.data.map(e=>''+e);
        }
        this.setState({saveLoading: true});
        var url = '/control/'+this.props.plan.product.uid;
        if (!!data.category) {
            url += '/'+data.category;
        }
        if (!!data.target) {
            url += '/'+data.target;
        }
        if (!!data.key) {
            url += '/'+data.key;
        }
        PostRequest(url, data)
            .then(resp => {
                if (!!resp.data.str_val && (typeof resp.data.str_val === 'string' || resp.data.str_val instanceof String)) {
                    resp.data.str_val = JSON.parse(resp.data.str_val);
                }
                var controls = [];
                if (!!this.state.controls.filter(entry=>entry.id===resp.data.id)[0]) {
                    controls = this.state.controls.map(entry => {
                        if (entry.id===resp.data.id) {
                            return resp.data;
                        } else {
                            return entry;
                        }
                    });
                } else {
                    controls = [resp.data, ...this.state.controls];
                }
                this.setState({
                    saveLoading: false,
                    controls: controls
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    handleNextStep = (entry) => {
        if (!!this.props.onNextStep) {
            this.props.onNextStep(entry);
        }
    }

    render(){
        const columns = [
            {
                title: _("Control Key "+this.props.category),
                render: (text, entry) => {
                    if (!!entry.name) {
                        return entry.name;
                    }
                    var temp = this.state.template.filter(t=>t.id===this.props.category)[0] || {};
                    if (!!temp.rules && Array.isArray(temp.rules.key_choices)) {
                        var kc = temp.rules.key_choices.filter(kc => kc.value===entry.key)[0];
                        if (!!kc && !!kc.format) {
                            return kc.format.replace(/\{\{int_val\}\}/g, ''+entry.int_val).replace(/\{\{float_val\}\}/g, ''+Number.parseFloat(entry.float_val).toFixed(2));
                        }
                        if (!!kc && !!kc.name) {
                            return kc.name;
                        }
                    }
                    return entry.key || _("All")
                }
            },
            {
                title: _("Control Value"),
                render: (text, entry) => {
                    var temp = this.state.template.filter(t=>t.id===this.props.category)[0] || {};
                    if (!temp.rules) {
                        return entry.name || "Nil";
                    }
                    var value_type = temp.rules.value_type;
                    if (value_type==='by_key') {
                        var option = temp.rules.key_choices.filter(t=>t.value===entry.key)[0];
                        if (!!option && !!option.value_type) {
                            value_type = option.value_type;
                        }
                    }
                    if (value_type==='int_val') {
                        return entry.int_val;
                    } else if (value_type==='float_val') {
                        return Number.parseFloat(entry.float_val).toFixed(2);
                    } else if (value_type==='str_val') {
                        var popoverTitle = entry.key || _("All");
                        if (!!temp.rules && Array.isArray(temp.rules.key_choices)) {
                            var kc = temp.rules.key_choices.filter(kc => kc.value===entry.key)[0];
                            if (!!kc && !!kc.name) {
                                popoverTitle = kc.name;
                            }
                        }
                        var popoverContent = (<div>{entry.str_val}</div>);
                        if (Array.isArray(entry.str_val)) {
                            if (entry.key==='__ad_waterfall' && Array.isArray(this.state.waterfall_list)) {
                                popoverContent = (<div>{entry.str_val.map((e,i)=>{
                                    var w = this.state.waterfall_list.filter(wf=>''+wf.id===e)[0];
                                    if (!!w && !!w.name) {
                                        return (<p key={i}>{w.name}</p>)
                                    }
                                    return (<p key={i}>{e}</p>);
                                })}</div>);
                            } else {
                                popoverContent = (<div>{entry.str_val.map((e,i)=>(<p key={i}>{e}</p>))}</div>);
                            }
                        }
                        return (<Popover title={popoverTitle} content={popoverContent}>...</Popover>);
                    }
                    return entry.name || "Nil";
                }
            },
            {
                title: _("Status"),
                render: (text, obj) => {
                    return (<Switch checked={obj.status} onChange={(checked, evt)=>{
                        var url = '/control/'+this.props.plan.product.uid;
                        if (!!obj.category) {
                            url += '/'+obj.category;
                        }
                        if (!!obj.target) {
                            url += '/'+obj.target;
                        }
                        if (!!obj.key) {
                            url += '/'+obj.key;
                        }
                        PostRequest(url, {category: obj.category, target: obj.target, key: obj.key, status: checked})
                            .then(resp => {
                                this.setState({controls: this.state.controls.map(p => {
                                    if (resp.data.id === p.id) {
                                        return resp.data;
                                    }
                                    return p;
                                })});
                            });
                    }} />);
                }
            }
        ];
        if (this.props.show_next_col==="true") {
            columns.push({
                render: (txt, entry) => {
                    return (<Button icon={<StepForwardOutlined />} onClick={(evt)=>{this.handleNextStep(entry);}}>{_("Next")}</Button>);
                }
            });
        }
        const { Option } = Select;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
              xs: { span: 24, offset: 14 },
              sm: { span: 16, offset: 6 },
            },
          };
        return (
            <div>
                <Button 
                    icon={<PlusOutlined />} 
                    style={{marginBottom:'16px'}}
                    size="large" 
                    type="primary"
                    onClick={this.handleCreateNew}
                >{_("Create New "+this.props.category)}</Button>
                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={this.state.controls}
                    loading={this.state.loading}
                    pagination={false}
                />
                {!!this.props.show_next && this.props.show_next==='true' && (<Button
                    icon={<CheckOutlined />}
                    style={{marginTop: '16px'}}
                    size="large"
                    type="primary"
                    onClick={()=>{this.handleNextStep(null);}}
                >{!!this.props.next_label ? this.props.next_label : _("Finish AD Plan")}</Button>)}
                <Modal
                    visible={this.state.modalVisible}
                    title={!!this.state.plan ? this.state.plan.name: ""}
                    width={"70%"}
                    onCancel={this.handleModalClose}
                    onOk={this.handleModalSave}
                    footer={[
                        <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                        <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                    ]}
                >
                    <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state}>
                        <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        {!!this.state.rules && 
                            !!this.state.rules.target_choices && 
                            this.state.rules.target_choices==='placement' && 
                            <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                <PlacementSelect 
                                    showall="true" 
                                    product_uid={this.state.product_uid}
                                    placement_uid={this.state.id ? this.state.target : null} />
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.target_choices && 
                            this.state.rules.target_choices==='category' && 
                            <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                <PlacementCategorySelect 
                                    value={this.state.id ? this.state.target : null} />
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.target_choices && 
                            this.state.rules.target_choices==='adnetwork' && 
                            <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                <ADNetworkSelect />
                            </Form.Item>}
                        {!!this.state.rules &&
                            !!this.state.rules.target_choices &&
                            Array.isArray(this.state.rules.target_choices) &&
                            <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                <Select>
                                    {this.state.rules.target_choices.filter(entry=>!this.state.id || entry.value===this.state.key).map(entry=>(
                                        <Option key={entry.value}>{entry.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.key_choices && 
                            this.state.rules.key_choices==='placement' && 
                            <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                <PlacementSelect 
                                    showall="true" 
                                    product_uid={this.state.product_uid}
                                    placement_uid={this.state.id ? this.state.key : null} />
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.key_choices && 
                            this.state.rules.key_choices==='category' && 
                            <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                <PlacementCategorySelect 
                                    value={this.state.id ? this.state.key : null} />
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.key_choices && 
                            this.state.rules.key_choices==='adnetwork' && 
                            <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                <ADNetworkSelect ad_include={!!this.props.plan_strategy ? this.props.plan_strategy.str_val : null} />
                            </Form.Item>}
                        {!!this.state.rules &&
                            !!this.state.rules.key_choices &&
                            Array.isArray(this.state.rules.key_choices) &&
                            <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                <Select onChange={(value, option) => {this.handleKeySelect(value);}}>
                                    {this.state.rules.key_choices.filter(entry=>!this.state.id || entry.value===this.state.key).map(entry=>(
                                        <Option key={entry.value}>{entry.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.value_type &&
                            this.state.rules.value_type==='int_val' && 
                            <Form.Item label={_("Control Value")} name="int_val">
                                <InputNumber />
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.value_type &&
                            this.state.rules.value_type==='float_val' && 
                            <Form.Item label={_("Control Value")} name="float_val">
                                <InputNumber />
                            </Form.Item>}
                        {!!this.state.rules && 
                            !!this.state.rules.value_type &&
                            this.state.rules.value_type==='str_val' && 
                            <Form.List name="str_val">
                                {(fields, {add, remove}) => {
                                    return (<div>
                                        {fields.map((field, idx) => (
                                            <Form.Item
                                                {...(idx === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                label={idx===0?_("Control Value"):""}
                                                required={false}
                                                key={idx}
                                            >
                                                {this.state.rules.value_choices==='adnetwork' &&
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                >
                                                    <ADNetworkSelect style={{ width: '86%' }} />
                                                </Form.Item>}
                                                {this.state.rules.value_choices==='adwterfall' &&
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                >
                                                    <ADWaterfallSelect
                                                        style={{ width: '86%' }} 
                                                        product_uid={this.props.plan.product.uid}
                                                        placement_uid={this.props.plan.placement_uid}
                                                    />
                                                </Form.Item>}
                                                {this.state.rules.value_choices===undefined &&
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                >
                                                    <Input style={{ width: '86%' }} />
                                                </Form.Item>}
                                                {fields.length > 1 ? (
                                                    <MinusCircleOutlined
                                                        className="form-item-delete-btn"
                                                        style={{ margin: '0 8px' }}
                                                        onClick={() => {
                                                            remove(field.name);
                                                        }}
                                                     />
                                                ) : null}
                                            </Form.Item>
                                        ))}
                                        <Form.Item {...formItemLayoutWithOutLabel}>
                                            <Button
                                                type="dashed"
                                                onClick={()=>{
                                                    add();
                                                }}
                                                style={{ width: '86%' }}
                                            >
                                                <PlusOutlined />{_("Add")}
                                            </Button>
                                        </Form.Item>
                                    </div>);
                                }}
                            </Form.List>}
                        </Form>
                </Modal>
            </div>
        );
    }
}