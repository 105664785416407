import React from 'react';
import { Row, Button, Tabs, Table, Form, InputNumber, DatePicker, message, Tooltip, Switch, Modal, Avatar, Alert } from 'antd';
import { CloseOutlined, SaveOutlined, EditOutlined, DownloadOutlined } from '@ant-design/icons';
import { GetRequest, PostRequest, DownloadRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

export class FinancialStatementReview extends React.Component {

    queryRef = React.createRef();
    formRef = React.createRef();

    constructor(props){
        super(props);
        this.unsubscribe = null;
        this.state = {
            loading: false,
            modalVisible: false,
            saveLoading: false,
            recordsLoading: false,
            downloading: false,
            query: {
                date: moment().subtract(1, 'months'),
            },
            company: null,
            personal: null,
            statement_list: [],
            statement: {},
            records: [],
            pagination: {
                defaultPageSize: 31,
                showSizeChanger: true
            },
        };
    }

    componentDidMount(){
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.handleQuery();
            }
        });
        this.handleQuery();
    };

    componentWillUnmount(){
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

    handleQuery = (values) => {
        if (!!this.queryRef.current) {
            this.queryRef.current.submit();
        }
    }

    handleQueryDo = (values) => {
        if (!this.state.loading) {
            this.setState({
                loading: true,
                query: values
            });
            GetRequest('/financial_statement_review?month='+values.date.format('YYYY-MM-01'))
                .then(resp => {
                    this.setState({
                        ...resp.data,
                        loading: false,
                        statement: {},
                        records: [],
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        company: null,
                        personal: null,
                        statement_list: [],
                        statement: {},
                        records: [],
                    });
                });
        }
    }

    handleStatementReviewEdit = (entry) => {
        this.setState({
            modalVisible: true,
            statement: entry
        });
        setTimeout(()=>{
            if (!!this.formRef.current) {
                this.formRef.current.resetFields();
            }
            this.setState({recordsLoading: true});
            GetRequest('/financial_statement_review_records/'+entry.product_uid+'/'+entry.month_id+'/'+entry.currency)
                .then(resp => {
                    this.setState({
                        recordsLoading: false,
                        records: resp.data
                    });
                })
                .catch(error => {
                    this.setState({
                        recordsLoading: false,
                        records: []
                    });
                });
        }, 0);
    }

    handleModalClose = () => {
        this.setState({
            modalVisible: false,
            statement: {},
        });
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        var data = {...values};
        if (!!values.status) {
            data['status'] = 2;
        } else {
            data['status'] = 0;
        }
        this.setState({saveLoading: true});
        PostRequest('/financial_statement_review/'+this.state.statement.product_uid+'/'+this.state.statement.uid, data)
            .then(resp => {
                this.setState({
                    saveLoading: false,
                    statement_list: this.state.statement_list.map(entry => {
                        if (entry.uid===resp.data.uid){
                            return resp.data;
                        } else {
                            return entry;
                        }
                    })
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    handleUpdateStatementStatus = (entry, stat) => {
        var data = {
            revenue: entry.revenue,
            currency: entry.currency,
            exchange_rate: entry.exchange_rate,
        };
        if (!!stat) {
            data['status'] = 2;
        } else {
            data['status'] = 0;
        }
        this.setState({saveLoading: true});
        PostRequest('/financial_statement_review/'+entry.product_uid+'/'+entry.uid, data)
            .then(resp => {
                this.setState({
                    saveLoading: false,
                    statement_list: this.state.statement_list.map(entry => {
                        if (entry.uid===resp.data.uid){
                            return resp.data;
                        } else {
                            return entry;
                        }
                    })
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    handleDownloadStatement = () => {
        if (!!this.queryRef && !!this.queryRef.current) {
            var date = undefined;
            var dateField = this.queryRef.current.getFieldValue('date');
            if (!!dateField){
                date = moment(dateField).format('YYYY-MM-DD');
            }
            if (!!date && (!!this.state.company || !!this.state.personal)) {
                this.setState({downloading: true});
                DownloadRequest(
                    "/financial_statement_review_pdf/"+date,
                    {file_name: 'yuyoumob-REVIEW-'+date+".pdf"}
                ).then(resp => {
                    this.setState({downloading: false});
                }).catch(error => {
                    this.setState({downloading: true});
                });
            } else {
                message.error(_("Create Financial Settings First"));
            }
        }
    }

    render() {
        const { TabPane } = Tabs;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const columns = [{
            title: _("Date"),
            dataIndex: 'month_id',
        },{
            render: (txt, entry) => <Avatar shape="square" size={48} src={entry.product_icon} alt={entry.product_name} />
        },
        {
            title: _("Product Name"),
            render: (txt, entry) => {
            return (<Tooltip title={entry.product_uid}>
                <span>{entry.product_name}</span>
            </Tooltip>);
            }
        },{
            title: _("Revenue"),
            render: (txt, entry) => (entry.currency==="CNY"?_("Currency Symbol"):_("Currency Symbol USD"))+entry.revenue_fmt
        },{
            title: _("Currency"),
            dataIndex: "currency",
        },{
            title: _("Exchange Rate"),
            render: (txt, entry) => Number.parseFloat(entry.exchange_rate).toFixed(2)
        },{
            title: _("Exchange Revenue"),
            render: (text, entry) => _("Currency Symbol")+entry.exchange_revenue
        },{
            title: _("Status"),
            render: (txt, entry) => {
                return (<Tooltip title={entry.status===0?_("Unreview"):entry.status===1?_("Autoreviewed"):_("Manualreviewed")}>
                    <Switch
                        checked={entry.status>0}
                        onChange={() => {this.handleUpdateStatementStatus(entry, !(entry.status>0)); }}
                    />
                </Tooltip>);
            }
        },
        {
            render: (text, entry) => {
                return (<Button
                    icon={<EditOutlined />}
                    onClick={(evt)=>{this.handleStatementReviewEdit(entry);}}>{_("Edit")}</Button>);
            }
        }];
        const recordsColumn = [
            {
                title: _("Revenue"),
                render: (txt, entry) => Number.parseFloat(entry.revenue).toFixed(2)
            },{
                title: _("Currency"),
                dataIndex: "currency",
            },{
                title: _("Exchange Rate"),
                render: (txt, entry) => Number.parseFloat(entry.exchange_rate).toFixed(2)
            },{
                title: _("Review User"),
                render: (txt, entry) => (<div>{entry.user.username}<br/>{entry.user.email}</div>)
            },
            {
                title: _("Date"),
                render: (txt, entry) => entry.create_time
            }
        ];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Financial Statement Review")} key="financial_statement_review">
                        <Row>
                            <Form layout="inline" ref={this.queryRef}
                                onValuesChange={this.handleQuery}
                                onFinish={this.handleQueryDo}
                                initialValues={this.state.query}>
                                <Form.Item name="date">
                                    <DatePicker
                                        picker="month"
                                        size="large"
                                        style={{width: 300, marginBottom: 16, marginRight: 4}}
                                     />
                                </Form.Item>
                                {(!!this.state.company || !!this.state.personal) && Array.isArray(this.state.statement_list) && this.state.statement_list.length>0 && <Form.Item>
                                    <Button
                                        icon={<DownloadOutlined />}
                                        size="large"
                                        type="primary"
                                        loading={this.state.downloading}
                                        onClick={this.handleDownloadStatement}
                                    >{_("Download Financial Statement")}</Button>
                                </Form.Item>}
                                </Form>
                        </Row>
                        {!this.state.company && !this.state.personal && <Alert
                            style={{marginBottom:24}}
                            message={_("Create Financial Settings First")}
                            type="error"
                            showIcon />}
                        <Table
                            rowKey="uid"
                            columns={columns}
                            dataSource={this.state.statement_list}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            summary={data => {
                                var totalExchangeRevenue = 0.0;
                                data.forEach(({exchange_revenue,}) => {
                                    totalExchangeRevenue += Number.parseFloat((''+exchange_revenue).replaceAll(/,/g, ''));
                                });
                                return (<Table.Summary.Row>
                                    <Table.Summary.Cell>{_("Total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(totalExchangeRevenue)}</Table.Summary.Cell>
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                </Table.Summary.Row>);
                            }}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={_("Edit Financial Statement")}
                            width={"60%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.statement}>
                                <Form.Item label={_("Revenue")} name="revenue">
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item label={_("Exchange Rate")} name="exchange_rate">
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item label={_("Publish or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Form>
                            <Table
                                rowKey="id"
                                columns={recordsColumn}
                                dataSource={this.state.records}
                                loading={this.state.recordsLoading}
                                pagination={false}
                            />
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}