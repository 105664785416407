import React from 'react';
import {
    Form,
    Input,
    InputNumber,
    Switch,
  } from 'antd';
import { PlacementCategorySelect } from './PlacementCategorySelect';
import { trans as _} from './i18n';

 export class PlacementForm extends React.Component {

    formRef = React.createRef();

    submit() {
        this.formRef.current.submit();
    }

    reset() {
        this.formRef.current.resetFields();
    }

    render() {
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        return (
            <Form {...formItemLayout} ref={this.formRef} onFinish={this.props.onFinish} initialValues={this.props.placement} >
                <Form.Item label={_("Placement Name")} name="name"
                    rules={[
                            {
                                required: true,
                                message: _("Required and not empty")
                            }
                        ]}>
                    <Input />
                </Form.Item>
                {!this.props.placement.category &&
                <Form.Item label={_("Placement Category")} name="category">
                    <PlacementCategorySelect />
                </Form.Item>
                }
                <Form.Item label={_("Width")} name="width"
                    rules={[
                            {
                                required: true,
                                message: _("Required and not empty")
                            }
                        ]
                    }>
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item label={_("Height")} name="height"
                    rules={[
                            {
                                required: true,
                                message: _("Required and not empty")
                            }
                        ]
                    }>
                    <InputNumber min={0} />
                </Form.Item>
                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={_("Placement Description")} name="description"
                    rules={[
                            {
                                required: true,
                                message: _("Required and not empty")
                            }
                        ]}>
                    <Input.TextArea rows={6} placeholder={_("Placement Description Tips")} />
                </Form.Item>
            </Form>
        );
    };
  };
