import React from 'react';
import { ConfigProvider, Result, Button } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Link } from "react-router-dom";
import { trans as _ } from './i18n';

export class Active extends React.Component {
    render() {
        return (<ConfigProvider locale={zhCN}>
            <Result
                status="success"
                title={_("Register Active Success")}
                subTitle={_("Register Active Success Subtitle")}
                extra={[
                    <Button type="primary"><Link to="/login">{_("GoTo Login")}</Link></Button>]}
            />
        </ConfigProvider>);
    }
}
