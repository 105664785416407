import React from 'react';
import { Select, Avatar } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import { GetRequest } from './Ajax';
import { trans as _ } from './i18n';

const { Option } = Select;

export class AppSelect extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            options: []
        };
        this.appMap = {};
    };

    fetch(){
        GetRequest('/applications/'+this.props.user_id)
        .then(resp => {
            var options = [];
            this.appMap = {};
            if (!!this.props.showall) {
                options.push(
                    <Option key="product_all" value="">
                        <Avatar style={{marginRight:'8px'}} shape="square" size={32} icon={<UnorderedListOutlined />} />{_("All")}
                    </Option>
                );
            }
            for (var i in resp.data) {
                var product = resp.data[i];
                this.appMap[product.uid] = product;
                options.push(
                    <Option key={product.uid} value={product.uid}>
                        <Avatar style={{marginRight:'8px'}} shape="square" size={32} src={product.icon} alt={product.name} />{product.name}
                    </Option>
                );
            }
            this.setState({
                options: options
            });
            if (!!this.props.onLoaded) {
                this.props.onLoaded(this.appMap);
            }
        }).catch(error => {
            var options = [];
            this.appMap = {};
            if (!!this.props.showall) {
                options.push(
                    <Option key="product_all" value="">
                        <Avatar style={{marginRight:'8px'}} shape="square" size={32} icon={<UnorderedListOutlined />} />{_("All")}
                    </Option>
                );
            }
            this.setState({
                options: options
            });
        });
    };

    componentDidMount(){
        this.fetch();
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.user_id !== this.props.user_id) {
            this.fetch();
        }
    }

    handleProductChange(value){
        if (!!this.props.onChange) {
            this.props.onChange(value, this.appMap[value]);
        }
    };

    render() {
        return (
            <Select
                {...this.props}
                showSearch
                placeholder={_("Select Product")}
                optionFilterProp="children"
                onChange={(v)=>{this.handleProductChange(v)}}
                filterOption={(input, option) =>
                    option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
            >
                {this.state.options}
            </Select>
        );
    };
};
