import React from 'react';
import logo from './logo.svg';
import { Layout, ConfigProvider, Modal, Select, Row, Col, Space, Typography } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Form, Input, Button, Checkbox, message, Result } from 'antd';
import { PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

const { Header, Content, Footer } = Layout;

export class Login extends React.Component {

    loginFormRef = React.createRef();
    registerFormRef = React.createRef();

    constructor(props){
        super();
        this.state = {
            loginVisible: false,
            loginLoading: false,
            registerVisible: false,
            registerLoading: false,
            registerSuccessVisible: false,
            registerActiveEmail: "",
        };
    }

    onFinish = values => {
        this.setState({loginLoading: true});
        PostRequest("/auth", values, undefined, {notRestAPI: true}).then(resp => {
            this.setState({loginLoading: false});
            if (!!values.remember) {
              localStorage.setItem("remember_login", values.username);
            } else {
              localStorage.removeItem("remember_login");
            }
            localStorage.setItem("token", resp.data.token);
            state.dispatch({
                type: "SET_TOKEN",
                payload: resp.data.token
            })
            this.props.history.push("/main/overview");
        }).catch(error => {
          this.setState({loginLoading: false});
          message.error(_("Login Fail"));
        });
    };

    onRegister = values => {
        this.setState({registerLoading: true});
        PostRequest("/register", values, null, {reCAPTCHA: "register", notRestAPI: true}).then(resp => {
          this.setState({
              registerLoading: false,
              loginVisible: false,
              registerSuccessVisible: true,
              registerActiveEmail: resp.data.email
          });
        }).catch(error => {
          this.setState({
              registerLoading: false,
              loginVisible: false,
              registerSuccessVisible: false,
              registerActiveEmail: ""
          });
          if (!!error && !!error.response && !!error.response.data && !!error.response.data.error) {
            message.error(''+error.response.data.error)
          } else {
            message.error(_("Register Fail"));
          }
        });
    }

    render() {

        const layout = {
            labelCol: {
                span: 8,
            },
            wrapperCol: {
                span: 16,
            },
        };

        const tailLayout = {
            wrapperCol: {
                offset: 8,
                span: 16,
            },
        };

        const { Option } = Select;

        const { Title } = Typography;

        const prefixSelector = (<Form.Item name="prefix" noStyle>
            <Select style={{ width: 70 }}>
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        </Form.Item>);

        return (<ConfigProvider locale={zhCN}>
            <div className="Login">
                <Layout className="Login-Layout">
                    <Header className="Login-Header">
                        <Button
                            style={{ float: 'right', lineHeight: '64px', height: '64px',}}
                            type="link"
                            onClick={()=>{this.setState({loginVisible: false, registerVisible:true, registerSuccessVisible: false});}}>{_("Register")}</Button>
                        <Button
                            style={{ float: 'right', lineHeight: '64px', height: '64px',}}
                            type="link"
                            onClick={()=>{this.setState({registerVisible: false, loginVisible:true, registerSuccessVisible: false});}}>{_("Login")}</Button>
                    </Header>
                    <Content className="Login-Content" ref="content">
                        <Space direction="vertical">
                            <Row>
                                <Col span={12} offset={2}>
                                <img
                                    src={logo}
                                    shape="square"
                                    style={{ width: '100%' }}
                                    alt="" />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={18} offset={2}>
                                    <Title className="Login-Text" level={2}>{_("Login Title")}</Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={18} offset={2}>
                                    <Title className="Login-Text" level={4}>{_("Login Description")}</Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={18} offset={2}>
                                <Button className="Login-Button" type="primary" size="large" onClick={()=>{this.setState({registerVisible:true, registerSuccessVisible: false});}}>{_("Login Button")}</Button>
                                </Col>
                            </Row>
                            <Row style={{height: '452px'}} />
                        </Space>
                        <Modal
                            title={this.state.registerSuccessVisible ? _("Register Success") : _("Register")}
                            onCancel={() => {this.setState({registerVisible: false})}}
                            visible={this.state.registerVisible}
                            footer={this.state.registerSuccessVisible ? null : <div style={{textAlign: 'right',}}>
                                <Button onClick={() => {this.setState({registerVisible: false});}} style={{ marginRight: 8 }}>{_("Close")}</Button>
                                <Button loading={this.state.registerLoading} onClick={() => {if (!!this.registerFormRef && !!this.registerFormRef.current) {this.registerFormRef.current.submit();}}} type="primary">{_("Register")}</Button>
                            </div>}
                        >
                            {this.state.registerSuccessVisible ?
                            <Result
                            status="success"
                            title={_("Welcome to Register Title")}
                            subTitle={_("Welcome to Register Subtitle 1") + this.state.registerActiveEmail + _("Welcome to Register Subtitle 2")}
                            extra={null} /> :
                            <Form
                                {...layout}
                                ref={this.registerFormRef}
                                name="basic"
                                initialValues={{
                                    email: "",
                                    first_name: "",
                                    last_name: "",
                                    prefix: "86",
                                    phone: "",
                                    qq: "",
                                    weixin: ""
                                }}
                                onFinish={this.onRegister}>

                                <Form.Item
                                    label={_("E-mail")}
                                    name="email"
                                    rules={[{
                                        type: "email",
                                        message: _('Please input your email!'),
                                    },{
                                        required: true,
                                        message: _('Please input your email!'),
                                        }]}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={_("First Name")}
                                    name="first_name"
                                    rules={[{
                                        required: true,
                                        message: _('Please input your first name!'),
                                        }]}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={_("Last Name")}
                                    name="last_name"
                                    rules={[{
                                        required: true,
                                        message: _('Please input your last name!'),
                                        }]}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={_("Password")}
                                    name="password"
                                    rules={[{
                                        required: true,
                                        message: _('Please input your password!'),
                                      }]}>
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    label={_("Confirm Password")}
                                    name="confirm"
                                    rules={[{
                                        required: true,
                                        message: _('Please input your password!'),
                                    },({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue("password")===value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(_("The two passwords that you entered do not match!"));
                                        }
                                    })]}>
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item
                                    label={_("Phone Number")}
                                    name="phone">
                                    <Input addonBefore={prefixSelector} style={{width:"100%"}} />
                                </Form.Item>

                                <Form.Item
                                    label={_("QQ Number")}
                                    name="qq">
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={_("WeiXin Number")}
                                    name="weixin">
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={_("Has Account")}>
                                    <Button type="link" onClick={()=>{this.setState({registerVisible: false, loginVisible:true});}}>{_("GoTo Login")}</Button>
                                </Form.Item>
                            </Form>}
                        </Modal>
                        <Modal
                            title={_("Login")}
                            onCancel={() => {this.setState({loginVisible: false})}}
                            visible={this.state.loginVisible}
                            footer={<div style={{textAlign: 'right',}}>
                                <Button onClick={() => {this.setState({loginVisible: false});}} style={{ marginRight: 8 }}>{_("Close")}</Button>
                                <Button loading={this.state.loginLoading} onClick={() => {if (!!this.loginFormRef && !!this.loginFormRef.current) {this.loginFormRef.current.submit();}}} type="primary">{_("Login")}</Button>
                            </div>}
                        >
                            <Form
                                {...layout}
                                ref={this.loginFormRef}
                                name="basic"
                                initialValues={{
                                    username: localStorage.getItem("remember_login") || "",
                                    remember: true,
                                }}
                                onFinish={this.onFinish}>

                                <Form.Item
                                    label={_("Username")}
                                    name="username"
                                    rules={[{
                                        required: true,
                                        message: _('Please input your username!'),
                                        }]}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={_("Password")}
                                    name="password"
                                    rules={[{
                                        required: true,
                                        message: _('Please input your password!'),
                                      }]}>
                                    <Input.Password />
                                </Form.Item>

                                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                                    <Checkbox>{_('Remember me')}</Checkbox>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        <p>
                            <span>{_("All Rights Reserved")+" "+moment().format("YYYY")+" "+_("Company YuYouMob")}</span>
                            <span> | </span>
                            <a target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn/">{_("https://beian.miit.gov.cn/")}</a>
                            <span> | </span>
                            <a href="/privacy.html" target="_blank"rel="noopener noreferrer" >{_("privacy")}</a>
                        </p>
                    </Footer>
                </Layout>
            </div>
        </ConfigProvider>);
    };
}
