import React from 'react';
import { Row, Button, Tabs, Table, Form, DatePicker, message, Alert } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GetRequest, DownloadRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

export class FinancialStatement extends React.Component {

    queryRef = React.createRef();

    constructor(props){
        super(props);
        this.unsubscribe = null;
        this.state = {
            loading: false,
            downloading: false,
            query: {
                date: [moment().subtract(6, 'months'), moment().subtract(1, 'months')]
            },
            personal: null,
            company: null,
            statement_list: [],
            pagination: {
                defaultPageSize: 31,
                showSizeChanger: true
            },
        };
    }

    componentDidMount(){
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.handleQuery();
            }
        });
        this.handleQuery();
    };

    componentWillUnmount(){
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = null;
        }
    };

    handleQuery = (values) => {
        if (!!this.queryRef.current) {
            this.queryRef.current.submit();
        }
    }

    handleQueryDo = (values) => {
        if (!this.state.loading) {
            this.setState({
                loading: true,
                query: values
            });
            GetRequest('/financial_statement?start='+values.date[0].format('YYYY-MM-01')+'&end='+values.date[1].format('YYYY-MM-01'))
                .then(resp => {
                    this.setState({
                        loading: false,
                        ...resp.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        personal: null,
                        company: null,
                        statement_list: []
                    });
                });
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { RangePicker } = DatePicker;
        const columns = [{
            title: _("Date"),
            dataIndex: 'date',
        },{
            title: _("Exchange Revenue"),
            render: (txt, entry) => new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(entry.revenue)
        },{
            render: (text, entry) => {
                if (entry.revenue > 0) {
                    return (<Button
                        icon={<DownloadOutlined />}
                        type="link"
                        loading={this.state.downloading}
                        onClick={(btn) => {
                            if (!!this.state.company || !!this.state.personal) {
                                this.setState({downloading: true});
                                DownloadRequest(
                                    "/financial_statement_pdf/"+entry.date+'-01',
                                    {file_name: 'yuyoumob-'+entry.date+".pdf"}
                                ).then(resp => {
                                    this.setState({downloading: false});
                                }).catch(error => {
                                    this.setState({downloading: true});
                                });
                            } else {
                                message.error(_("Create Financial Settings First"));
                            }
                        }}
                    />);
                }
                return "";
            }
        }];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Financial Statement")} key="financial_statement">
                        <Row>
                            <Form layout="inline" ref={this.queryRef}
                                onValuesChange={this.handleQuery}
                                onFinish={this.handleQueryDo}
                                initialValues={this.state.query}>
                                <Form.Item name="date">
                                    <RangePicker
                                        picker="month"
                                        size="large"
                                        style={{width: 300, marginBottom: 16, marginRight: 4}}
                                     />
                                </Form.Item>
                                </Form>
                        </Row>
                        <Alert
                            style={{marginBottom:24}}
                            message={_("Financial Statement Generate Date")}
                            type="warning"
                            showIcon
                            closable />
                        <Alert
                            style={{marginBottom: 24}}
                            message={_("Financial Statement Generate Limit")}
                            type="error"
                            showIcon />
                        {!this.state.company && !this.state.personal && <Alert
                            style={{marginBottom:24}}
                            message={_("Create Financial Settings First")}
                            type="error"
                            showIcon />}
                        <Table
                            rowKey="date"
                            columns={columns}
                            dataSource={this.state.statement_list}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            summary={(data) => {
                                var totalRevenue = 0.0;
                                data.forEach(({revenue,}) => {
                                    totalRevenue += revenue;
                                });
                                return (<Table.Summary.Row>
                                    <Table.Summary.Cell>{_("Total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(totalRevenue)}</Table.Summary.Cell>
                                    <Table.Summary.Cell />
                                </Table.Summary.Row>)
                            }}
                        />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}