import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import './index.css';
import App from './App';
import { Login } from './Login';
import { Active } from './Active.js';
import { Document } from './Document';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<div>
    <Router>
      <Route path="/login" component={ Login } />
      <Route path="/active" component={ Active } />
      <Route path="/main" render={(props) => {
        if (!!localStorage.getItem('token')) {
          return (<App {...props} />);
        }
        if (!!props && !!props.location && !!props.location.pathname && props.location.pathname.indexOf('main/sdk_document') > 0) {
          return (<Document {...props} />);
        }
        return (<Redirect to="/login"/>) }
      } />
      <Route exact path="/" render={() => (
        !!localStorage.getItem('token') ? (
          <Redirect to="/main/overview"/>
        ) : (
          <Redirect to="/login"/>
        )
      )} />
    </Router>
    <div
      className="g-recaptcha"
      data-sitekey="6LfJVuQZAAAAAMqjNIxA3LpdcOO6Ipu220exG_sE"
      data-size="invisible"></div>
  </div>,
  document.getElementById('root'),
  () => {
      const script = document.createElement("script");
      script.src = "https://www.recaptcha.net/recaptcha/api.js?render=6LfJVuQZAAAAAMqjNIxA3LpdcOO6Ipu220exG_sE"
      document.body.appendChild(script);
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
