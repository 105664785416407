import React from 'react';
import { Row, Modal, Button, Tabs, Table, Form, Input, Select, Switch, message, InputNumber } from 'antd';
import { PlusOutlined, EditOutlined, CloseOutlined, SaveOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { AppSelect } from './AppSelect';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import { PlacementSelect } from './PlacementSelect';
import { ADNetworkSelect } from './ADNetworkSelect';
import { PlacementCategorySelect } from './PlacementCategorySelect';

const state = getState();

export class Control extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            user_id: state.getState().userId,
            product_uid: "",
            product: {},
            controls: [],
            template: [],
            id: null,
            category: "",
            rules: {},
            target: "",
            key: "",
            status: true,
            int_val:0,
            float_val: 0,
            str_val: ['1','2','3'],
        };
        this.unsubscribe = null;
    };

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.setState({
                    user_id: state.getState().userId,
                    product_uid: "",
                    controls: []
                });
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleSelectProduct = (puid, entry) => {
        if (!!puid) {
            this.setState({loading: true});
            GetRequest('/control/'+puid)
                .then(resp => {
                    this.setState({
                        loading: false,
                        product_uid: puid,
                        product: entry,
                        ...resp.data
                    });
                }).catch(error => {
                    this.setState({loading: false});
                });
        }
    }

    handleCreateNew = () => {
        this.setState({
            id: null,
            modalVisible: true
        });
    }

    handleEdit = (entry) => {
        if (!!entry.str_val && (typeof entry.str_val === 'string' || entry.str_val instanceof String)) {
            entry.str_val = JSON.parse(entry.str_val);
        }
        var control = this.state.template.filter(e=>e.id===entry.category)[0];
        this.setState({
            modalVisible: true,
            rules: control.rules || {},
            ...entry
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleCategorySelect = (category) => {
        var control = this.state.template.filter(entry=>entry.id===category)[0];
        this.setState({
            modalVisible: true,
            category: category,
            key: "",
            status: true,
            int_val:0,
            float_val: 0,
            str_val: [],
            rules: control.rules || {},
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        var data = {
            category: values.category,
            target: values.target,
            key: values.key,
            status: values.status,
            int_val: values.int_val,
            float_val: values.float_val,
            data: values.str_val || []
        };
        if (!!this.state.rules && !!this.state.rules.key_choices && this.state.rules.key_choices==='same_target') {
            data['key'] = data.target || "";
        }
        this.setState({saveLoading: true});
        var url = '/control/'+this.state.product_uid;
        if (!!data.category) {
            url += '/'+data.category;
        }
        if (!!data.target) {
            url += '/'+data.target;
        }
        if (!!data.key) {
            url += '/'+data.key;
        }
        PostRequest(url, data)
            .then(resp => {
                var controls = [];
                if (!!this.state.controls.filter(entry=>entry.id===resp.data.id)[0]) {
                    controls = this.state.controls.map(entry => {
                        if (entry.id===resp.data.id) {
                            return resp.data;
                        } else {
                            return entry;
                        }
                    });
                } else {
                    controls = [resp.data, ...this.state.controls];
                }
                this.setState({
                    saveLoading: false,
                    controls: controls
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    render(){
        const columns = [
            {
                title: _("Control Name"),
                render: (text, entry) => {
                    var temp = this.state.template.filter(t=>t.id===entry.category)[0] || {};
                    return temp.name || "";
                }
            },
            {
                title: _("Control Target"),
                render: (text, entry) => entry.target || _("All")
            },
            {
                title: _("Control Key"),
                render: (text, entry) => entry.key || _("All")
            },
            {
                title: _("Control Value"),
                render: (text, entry) => {
                    var temp = this.state.template.filter(t=>t.id===entry.category)[0] || {rules:{}};
                    if (temp.rules.value_type==='int_val') {
                        return entry.int_val;
                    } else if (temp.rules.value_type==='float_val') {
                        return Number.parseFloat(entry.float_val).toFixed(2);
                    } else if (temp.rules.value_type==='str_val') {
                        return "..."
                    }
                    return "";
                }
            },
            {
                title: _("Status"),
                render: (text, obj) => {
                    return (<Switch checked={obj.status} onChange={(checked, evt)=>{
                        var url = '/control/'+this.state.product_uid;
                        if (!!obj.category) {
                            url += '/'+obj.category;
                        }
                        if (!!obj.target) {
                            url += '/'+obj.target;
                        }
                        if (!!obj.key) {
                            url += '/'+obj.key;
                        }
                        PostRequest(url, {category: obj.category, target: obj.target, key: obj.key, status: checked})
                            .then(resp => {
                                this.setState({controls: this.state.controls.map(p => {
                                    if (resp.data.id === p.id) {
                                        return resp.data;
                                    }
                                    return p;
                                })});
                            });
                    }} />);
                }
            },
            {
                render: (text, obj) => {
                    return (<Button icon={<EditOutlined />} onClick={(evt)=>{this.handleEdit(obj);}}>{_("Edit")}</Button>);
                }
            }
        ];
        const { TabPane } = Tabs;
        const { Option } = Select;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
              xs: { span: 24, offset: 14 },
              sm: { span: 16, offset: 6 },
            },
          };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage AD Control")} key="manage_control">
                        <Row>
                            <AppSelect
                                size="large"
                                style={{width: 400, marginBottom: 16, marginRight: 4}}
                                user_id={this.state.user_id}
                                value={this.state.product_uid}
                                onChange={this.handleSelectProduct} />
                            <Button
                                icon={<PlusOutlined />}
                                size="large"
                                type="primary"
                                disabled={ !this.state.product_uid || this.state.product_uid.length===0 }
                                onClick={this.handleCreateNew}
                            >{_("Create New Control")}</Button>
                        </Row>
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={this.state.controls}
                            loading={this.state.loading}
                            pagination={false}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={this.state.product.name||this.state.product.uid}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state}>
                                <Form.Item label={_("Control Name")} name="category" rules={[{required: true}]}>
                                    <Select
                                        showSearch
                                        placeholder={_("Select AD Control")}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onSelect={(value, option) => {
                                            this.handleCategorySelect(value);
                                        }}
                                    >
                                        {this.state.template.filter(entry=>!this.state.id || entry.id===this.state.category).map((entry) => (
                                            <Option key={entry.id}>{entry.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                {!!this.state.rules &&
                                    !!this.state.rules.target_choices &&
                                    this.state.rules.target_choices==='placement' &&
                                    <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                        <PlacementSelect
                                            showall="true"
                                            product_uid={this.state.product_uid}
                                            placement_uid={this.state.id ? this.state.target : null} />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.target_choices &&
                                    this.state.rules.target_choices==='category' &&
                                    <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                        <PlacementCategorySelect
                                            value={this.state.id ? this.state.target : null} />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.target_choices &&
                                    this.state.rules.target_choices==='adnetwork' &&
                                    <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                        <ADNetworkSelect />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.target_choices &&
                                    Array.isArray(this.state.rules.target_choices) &&
                                    <Form.Item label={_("Control Target")} name="target" rules={[{required: !!this.state.rules.target_required}]}>
                                        <Select>
                                            {this.state.rules.target_choices.filter(entry=>!this.state.id || entry.value===this.state.key).map(entry=>(
                                                <Option key={entry.value}>{entry.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.key_choices &&
                                    this.state.rules.key_choices==='placement' &&
                                    <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                        <PlacementSelect
                                            showall="true"
                                            product_uid={this.state.product_uid}
                                            placement_uid={this.state.id ? this.state.key : null} />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.key_choices &&
                                    this.state.rules.key_choices==='category' &&
                                    <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                        <PlacementCategorySelect
                                            value={this.state.id ? this.state.key : null} />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.key_choices &&
                                    this.state.rules.key_choices==='adnetwork' &&
                                    <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                        <ADNetworkSelect />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.key_choices &&
                                    Array.isArray(this.state.rules.key_choices) &&
                                    <Form.Item label={_("Control Key")} name="key" rules={[{required: !!this.state.rules.key_required}]}>
                                        <Select>
                                            {this.state.rules.key_choices.filter(entry=>!this.state.id || entry.value===this.state.key).map(entry=>(
                                                <Option key={entry.value}>{entry.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.value_type &&
                                    this.state.rules.value_type==='int_val' &&
                                    <Form.Item label={_("Control Value")} name="int_val">
                                        <InputNumber />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.value_type &&
                                    this.state.rules.value_type==='float_val' &&
                                    <Form.Item label={_("Control Value")} name="float_val">
                                        <InputNumber />
                                    </Form.Item>}
                                {!!this.state.rules &&
                                    !!this.state.rules.value_type &&
                                    this.state.rules.value_type==='str_val' &&
                                    <Form.List name="str_val">
                                        {(fields, {add, remove}) => {
                                            return (<div>
                                                {fields.map((field, idx) => (
                                                    <Form.Item
                                                        {...(idx === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                        label={idx===0?_("Control Value"):""}
                                                        required={false}
                                                        key={idx}
                                                    >
                                                        {this.state.rules.value_choices==='adnetwork' &&
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                        >
                                                            <ADNetworkSelect style={{ width: '86%' }} />
                                                        </Form.Item>}
                                                        {this.state.rules.value_choices===undefined &&
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            noStyle
                                                        >
                                                            <Input style={{ width: '86%' }} />
                                                        </Form.Item>}
                                                        {fields.length > 1 ? (
                                                            <MinusCircleOutlined
                                                                className="form-item-delete-btn"
                                                                style={{ margin: '0 8px' }}
                                                                onClick={() => {
                                                                    remove(field.name);
                                                                }}
                                                             />
                                                        ) : null}
                                                    </Form.Item>
                                                ))}
                                                <Form.Item {...formItemLayoutWithOutLabel}>
                                                    <Button
                                                        type="dashed"
                                                        onClick={()=>{
                                                            add();
                                                        }}
                                                        style={{ width: '86%' }}
                                                    >
                                                        <PlusOutlined />{_("Add")}
                                                    </Button>
                                                </Form.Item>
                                            </div>);
                                        }}
                                    </Form.List>}
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}