import React from 'react';
import { Modal, Table, Tag, Button, Tabs, Form, Input, Switch, message, Alert } from 'antd';
import { SaveOutlined, CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { UserSelect } from './UserSelect';
import { GetRequest, PostRequest } from './Ajax';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

export class Notification extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            notifications: [],
            pagination: {
                defaultPageSize: 25
            },
            notification:{}
        };
    }

    componentDidMount = () => {
        this.fetch();
    }

    fetch = () => {
        this.setState({
            loading: true
        });
        GetRequest("/notification")
            .then(resp => {
                this.setState({
                    loading: false,
                    notifications: resp.data
                });
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
            });
    }

    handleCreateNew = () => {
        this.handleEdit({
            id: null,
            title: "",
            content: "",
            status: true,
            send_email: false,
            email_sender: "",
        });
    }

    handleEdit = (entry) => {
        this.setState({
            modalVisible: true,
            notification: {
                send_email: false,
                email_sender: "",
                ...entry}
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        //console.log("handleModalFinish: ", values);
        if (!Array.isArray(values.receiver_list)) {
            values.receiver_list = [""];
        }
        this.setState({saveLoading: true});
        var url = "/notification";
        if (!!this.state.notification && !!this.state.notification.id) {
            url += "/"+this.state.notification.id;
        }
        PostRequest(url, values)
            .then(resp => {
                this.setState({
                    saveLoading: false,
                    notifications: resp.data
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    render() {
        const { TabPane } = Tabs;
        const columns = [
            {
                title: "ID",
                render: (text, entry) => entry.id
            },
            {
                title: _("Title"),
                render: (text, entry) => entry.title
            },
            {
                title: _("Receiver"),
                render: (text, entry) => {
                    if (!!entry.receiver_email) {
                        return entry.receiver_email;
                    } else {
                        return _("All");
                    }
                }
            },
            {
                title: _("Status"),
                render: (text, entry) => {
                    if (entry.status===true) {
                        return (<Tag color="green">{_("Enable")}</Tag>);
                    } else {
                        return (<Tag color="red">{_("Disable")}</Tag>);
                    }
                }
            },
            {
                title: _("Date"),
                render: (txt, entry) => {
                    return moment(entry.create_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                }
            },
            {
                render: (text, obj) => {
                    return (<Button icon={<EditOutlined />} onClick={(evt)=>{this.handleEdit(obj);}}>{_("Edit")}</Button>);
                }
            }
        ];
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Notification")} key="manage_notification">
                        <Button icon={<PlusOutlined/>} style={{marginBottom: 16}} type="primary" size="large" onClick={this.handleCreateNew}>{_("Create New Notification")}</Button>
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={this.state.notifications}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={this.state.notification.title||_("Create New Notification")}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.notification}>
                                <Form.Item name="title" rules={[{required: true}]} label={_("Title")}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="content" label={_("Content")}>
                                    <Input.TextArea />
                                </Form.Item>
                                {null===this.state.notification.id && <Form.Item label={_("Receiver")} name="receiver_list">
                                    <UserSelect mode="multiple" showkey="email" usevalue="email" />
                                </Form.Item>}
                                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("Send email or not")}>
                                    <Form.Item name="send_email" valuePropName="checked">
                                        <Switch onChange={() => {this.setState({notification:{
                                            ...this.state.notification,
                                            send_email: !this.state.notification.send_email,
                                        }});}} />
                                    </Form.Item>
                                    <Form.Item name="email_sender">
                                        <Input disabled={!this.state.notification.send_email} placeholder={_("Sender")} />
                                    </Form.Item>
                                    <Form.Item>
                                    <Alert
                                        message={_("Notification Send Email Warning")}
                                        type="warning"
                                        showIcon />
                                    </Form.Item>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
};
