import React from 'react';
import { Modal, Space, Typography, Button, Avatar, Tabs, message, Steps, Form, Input, Popover, Alert } from 'antd';
import { SaveOutlined, CloseOutlined, PlusOutlined, RocketOutlined, RightOutlined } from '@ant-design/icons';
import { AppList } from './AppList';
import { AppForm } from './AppForm';
import { GetRequest, PostRequest } from './Ajax';
import { trans as _ } from './i18n';

export class Application extends React.Component {

    productListRef = React.createRef();
    productFormRef = React.createRef();
    appReviewFormRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            loading: false,
            product: {},
            appReviewModalVisible: false,
            appReviewDisable: [true, true, false],
            appReviewStep: 0,
            appReviewInfo: {
                package_info: {},
                admob_id: null,
                url: "",
                sha1: "",
            },
            appReviewLoading: false,
        };
    };

    componentDidMount(){
        document.addEventListener("userSwitch", this.handleUserSwitch);
    };

    handleEditProduct = (product) => {
        this.setState({
            modalVisible: true,
            product: product
        });
        if (!!this.productFormRef && !!this.productFormRef.current) {
            setTimeout(()=>{
                this.productFormRef.current.reset();
            },0);
        }
    };

    handleModalClose = () => {
        this.setState({
            modalVisible: false,
            loading: false
        });
    };

    handleUserSwitch(){
        if (!!this.productListRef && !!this.productListRef.current) {
            this.productListRef.current.refresh();
        }
    };

    handleModalSave = () => {
        this.productFormRef.current.submit();
    };

    handleModalFinish = values => {
        this.setState({loading: true});
        var url = '/application';
        if (!!this.state.product.uid) {
            url += '/'+this.state.product.uid;
        }
        PostRequest(url, values, null, {reCAPTCHA: "application"})
            .then(resp => {
                this.setState({loading: false});
                message.success(_("Save successful"));
                if (!!this.productListRef && !!this.productListRef.current) {
                    this.setState({product: resp.data});
                    if (values.uid) {
                        this.productListRef.current.updateProduct(values);
                    } else {
                        this.productListRef.current.refresh();
                    }
                }
            }).catch(error=>{
                this.setState({
                    loading: false
                });
                message.error(_("Save failure"));
            });
    };

    handleCreateNewProduct = () => {
        this.handleEditProduct({
            title: _("Create New Product")
        });
    };

    handleReviewProduct = (values) => {
        if (!!this.state.product) {
            var data = {...this.state.product, ...values};
            data.status = 4;
            this.setState({appReviewLoading: true});
            PostRequest('/review_application/'+data.uid, data)
                .then(resp=>{
                    message.success(_("Save successful"));
                    this.productListRef.current.updateProduct(resp.data);
                    this.setState({
                        appReviewLoading: false,
                        appReviewModalVisible: false,
                        appReviewDisable: [true, true, false],
                        appReviewStep: 0,
                        appReviewInfo: {
                            package_info: {},
                            admob_id: null,
                            url: "",
                            sha1: "",
                        },

                    });
                })
                .catch(error=>{
                    this.setState({
                        appReviewLoading: false,
                    });
                    message.error(_("Save failure"));
                });
        }
    }

    handleAppReviewModalOpen = (product) => {
        if (!!product) {
            GetRequest("/app_review_info/"+product.uid)
                .then(resp => {
                    var appReviewInfo = {
                        ...resp.data,
                        url: product.url,
                    };
                    var appReviewDisable = [true, true, false];
                    if (!!appReviewInfo.package_info) {
                        appReviewDisable[0] = false;
                        if (!!product && 'appstore'===product.platform) {
                            if (appReviewInfo.package_info.att_status<0) {
                                appReviewDisable[0] = true;
                            }
                        }
                    }
                    if (!!appReviewInfo.admob_id) {
                        appReviewDisable[1] = false;
                    }
                    this.setState({
                        product: product,
                        appReviewModalVisible: true,
                        appReviewStep: 0,
                        appReviewInfo: appReviewInfo,
                        appReviewDisable: appReviewDisable,
                    });
                })
                .catch(error => {
                    this.setState({
                        appReviewModalVisible: false,
                        appReviewStep: 0,
                        appReviewInfo: {
                            package_info: {},
                            admob_id: null,
                            url: "",
                            sha1: "",
                        },
                    });
                });
        }
    }

    handleAppReviewModalClose = () => {
        this.setState({
            appReviewModalVisible: false,
            appReviewStep: 0,
            appReviewInfo: {},
        });
    }

    handleAppReviewModalSave = () => {
        if (this.state.appReviewStep < 2) {
            this.setState({
                appReviewStep: this.state.appReviewStep + 1,
            });
        } else if (!!this.state.product && !!this.appReviewFormRef && !!this.appReviewFormRef.current) {
            this.appReviewFormRef.current.submit();
        }
    }

    render(){
        const { TabPane } = Tabs;
        const { Title, Paragraph, Link, Text } = Typography;
        const { Step } = Steps;
        const appReviewSteps = [{
            title: _("Application Review Step 1"),
        },{
            title: _("Application Review Step 2"),
        },{
            title: _("Application Review Step 3"),
        }];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage Product")} key="manage_product">
                        <Button icon={<PlusOutlined/>} style={{marginBottom: 16}} type="primary" size="large" onClick={this.handleCreateNewProduct}>{_("Create New Product")}</Button>
                        <Alert 
                            style={{marginBottom:24}} 
                            message={_("Application register require reCAPTCHA verify")}
                            type="warning"
                            showIcon
                            closable />
                        <AppList ref={this.productListRef} showreview="true" onReview={this.handleAppReviewModalOpen} onEdit={this.handleEditProduct} />
                        <Modal
                            visible={this.state.modalVisible}
                            title={<Space><Avatar shape="square" src={this.state.product.icon} size={32} alt={this.state.product.uid} /><Title level={3}>{this.state.product.name||this.state.product.title}</Title></Space>}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined/>} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" key="save" icon={<SaveOutlined/>} loading={this.state.loading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <AppForm ref={this.productFormRef} product={this.state.product} onFinish={this.handleModalFinish} />
                        </Modal>
                        <Modal
                            key="application-review-modal"
                            visible={this.state.appReviewModalVisible}
                            title={<Space><Avatar shape="square" src={this.state.product.icon} size={32} alt={this.state.product.uid} /><Title level={3}>{this.state.product.name||this.state.product.title}</Title></Space>}
                            width={"70%"}
                            onCancel={this.handleAppReviewModalClose}
                            onOk={this.handleAppReviewModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined/>} onClick={this.handleAppReviewModalClose}>{_("Close")}</Button>,
                                <Button 
                                    type="primary" 
                                    key="save" 
                                    icon={this.state.appReviewStep===2 ? <RocketOutlined/> : <RightOutlined />} 
                                    loading={this.state.appReviewLoading} 
                                    disabled={this.state.appReviewDisable[this.state.appReviewStep]} 
                                    onClick={this.handleAppReviewModalSave}
                                >
                                    {this.state.appReviewStep===2 ? _("Review Product") : _("Next")}
                                </Button>,
                            ]}
                        >
                            <Steps current={this.state.appReviewStep}>
                                {appReviewSteps.map(entry => <Step key={entry.title} title={entry.title} />)}
                            </Steps>
                            {0===this.state.appReviewStep && <div style={{marginTop:16}}>
                                {!!this.state.appReviewInfo.package_info ? <div>
                                    <Alert
                                        message={_("SDK install info")}
                                        description={<Paragraph>
                                            <ul>
                                                <li>{_("Bundle ID")+" : "+this.state.appReviewInfo.package_info.bundle_id}</li>
                                                <li>{_("Bundle Version")+" : "+this.state.appReviewInfo.package_info.bundle_version}</li>
                                                <li>{_("SDK Version")+" : "+this.state.appReviewInfo.package_info.sdk_version}</li>
                                                <li>{_("AD Adapters Count")+" : "+(""+this.state.appReviewInfo.package_info.ad_adapters).split(",").length}</li>
                                            </ul>
                                        </Paragraph>}
                                        type="info"
                                        showIcon
                                    />
                                    {!!this.state.product && 'appstore'===this.state.product.platform && this.state.appReviewInfo.package_info.att_status<0 && <Alert
                                        message={_("SDK not support iOS 14 ATT")}
                                        description={<Paragraph>
                                            <ul>
                                                <li><Text type="danger">{_("SDK not support iOS 14 ATT description "+this.state.appReviewInfo.package_info.att_status)}</Text></li>
                                            </ul>
                                        </Paragraph>}
                                        style={{marginTop:"8px"}}
                                        type="error"
                                        showIcon
                                    />}
                                </div> : <Alert
                                    message={_("SDK install error title")}
                                    description={_("SDK install error descript")}
                                    type="error"
                                    showIcon />}
                            </div>}
                            {1===this.state.appReviewStep && <div style={{marginTop:16}}>
                                {!!this.state.appReviewInfo.admob_id ? <Alert
                                    message={_("AD Parameter review info title")}
                                    description={<div>
                                        <Paragraph copyable>{this.state.appReviewInfo.admob_id}</Paragraph>
                                        {!!this.state.product && this.state.product.platform==='appstore' ? <Paragraph>{_("AD Parameter review info descript ios")}</Paragraph> : <Paragraph>{_("AD Parameter review info descript")}</Paragraph>}
                                        <Link href="https://developers.google.com/admob/ios/quick-start#update_your_infoplist" target="_blank">{_("AD Parameter review info url")}</Link>
                                    </div>}
                                    type="info"
                                    showIcon
                                /> : <Alert 
                                    message={_("AD Parameter review error title")}
                                    description={_("AD Parameter review error descript")}
                                    type="error"
                                    showIcon
                                />}
                            </div>}
                            {2===this.state.appReviewStep && <div style={{marginTop:16}}>
                                <Alert
                                    message={_("Application Review Check List")}
                                    type="info"
                                    showIcon
                                    description={<div>
                                        <ul>
                                            <li>{_("Application Review Debug Check")}</li>
                                            <li>{_("Application Review Download URL Check")}</li>
                                        </ul>
                                        <Form ref={this.appReviewFormRef} layout="horizontal" labelCol={{span:4}} wrapperCol={{span:14}} onFinish={this.handleReviewProduct} initialValues={this.state.appReviewInfo}>
                                            <Form.Item label={_("Download URL")} name="url" rules={[{required:true, message: _("Required and not empty")}]}>
                                                <Input />
                                            </Form.Item>
                                            {!!this.state.product && 'appstore'!==this.state.product.platform && 
                                                <Popover
                                                    title={_("Application Package SHA1 help title")}
                                                    content={<Paragraph>
                                                        <ul>
                                                            <li>{_("Application Package SHA1 help content 1")}</li>
                                                            <li>{_("Application Package SHA1 help content 2")}</li>
                                                            <li>{_("Application Package SHA1 help content 3")}</li>
                                                            <li>{_("Application Package SHA1 help content 4")}</li>
                                                        </ul>
                                                    </Paragraph>}
                                                ><Form.Item label="SHA1" name="sha1" rules={[{required:true, message: _("Required and not empty")}]}>
                                                    <Input />
                                                </Form.Item></Popover>}
                                        </Form>
                                    </div>}
                                />
                            </div>}
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
};
