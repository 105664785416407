import React from 'react';
import { Select } from 'antd';
import { trans as _ } from './i18n';

const { Option } = Select;

class ScreenOrientationSelect extends React.Component {
    render(){
        return (
            <Select {...this.props} >
                <Option value="horizontal">{_("horizontal")}</Option>
                <Option value="vertical">{_("vertical")}</Option>
            </Select>
        );
    };
};

export { ScreenOrientationSelect };
