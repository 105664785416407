import React from 'react';
import { Modal, Tag, Button, Tabs, Table, Form, Input, Switch, message } from 'antd';
import { SaveOutlined, CloseOutlined, PlusOutlined, EditOutlined, 
    AppleOutlined, AndroidOutlined, GoogleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { GetRequest, PostRequest } from './Ajax';
import { trans as _ } from './i18n';

export class ADNetwork extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            loading: false,
            saving: false,
            adnetworkList: [],
            adnetwork: null
        };
    };

    fetch(){
        this.setState({loading: true});
        GetRequest('/adnetwork')
        .then(resp => {
            this.setState({
                loading: false,
                adnetworkList: resp.data
            });
        })
        .catch(error=>{
            this.setState({
                loading: false,
                adnetworkList: []
            })
        });
    };

    componentDidMount(){
        this.fetch();
    };

    handleCreateNew = () => {
        this.handleEdit({
            uid: null,
            name: "",
            description: "",
            status: true,
            support_ios: true,
            support_googleplay: false,
            support_chinese_android: true,
            support_rewarded_video: true,
            support_interstitial_video: true,
            support_interstitial: true,
            support_banner: false,
            support_splash: false,
            support_native: false,
            required_params: ['app_id',],
            placement_params: ['ad_placement']
        });
    }

    handleEdit = (entry) => {
        var data = {
            ...entry,
            required_params: ['app_id',],
            placement_params: ['ad_placement']
        };
        if (!!data.required_keys) {
            data['required_params'] = JSON.parse(data.required_keys);
        }
        if (!!data.placement_keys) {
            data['placement_params'] = JSON.parse(data.placement_keys);
        }
        this.setState({
            modalVisible: true,
            adnetwork: data
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleModalClose = () => {
        this.setState({
            modalVisible: false
        });
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        this.setState({saving: true});
        var url = '/adnetwork';
        if (!!this.state.adnetwork && this.state.adnetwork.uid) {
            url += '/'+this.state.adnetwork.uid;
        }
        PostRequest(url, values)
            .then(resp => {
                var adnetwork = resp.data;
                var adnetworkList = [];
                if (!!this.state.adnetworkList.filter(entry=>entry.uid===adnetwork.uid)[0]) {
                    adnetworkList = this.state.adnetworkList.map(entry => {
                        if (entry.uid === adnetwork.uid) {
                            return adnetwork;
                        } else {
                            return entry;
                        }
                    });
                } else {
                    adnetworkList = [adnetwork, ...this.state.adnetworkList];
                }
                this.setState({
                    saving: false,
                    adnetwork: adnetwork,
                    adnetworkList: adnetworkList
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saving: false});
                message.error(_("Save failure"));
            })
    }

    render(){
        const { TabPane } = Tabs;
        const columns = [
            // {
            //     title: "ID",
            //     dataIndex: "uid"
            // },
            {
                title: _("AD Network Name"),
                dataIndex: "name"
            },
            {
                title: _("Status"),
                render: (text, entry) => {
                    return (<Tag color={entry.status?"success":"error"} >{entry.status?_("Enable"):_("Disable")}</Tag>);
                }
            },
            {
                title: _("AppStore"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_ios?"success":"default"} ><AppleOutlined /></Tag>);
                }
            },
            {
                title: _("GooglePlay"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_googleplay?"success":"default"} ><GoogleOutlined /></Tag>);
                }
            },
            {
                title: _("ChineseAndroid"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_chinese_android?"success":"default"} ><AndroidOutlined /></Tag>);
                }
            },
            {
                title: _("rewarded_video"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_rewarded_video?"success":"default"}>{_("rewarded_video")}</Tag>)
                }
            },
            {
                title: _("interstitial_video"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_interstitial_video?"success":"default"}>{_("interstitial_video")}</Tag>)
                }
            },
            {
                title: _("interstitial"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_interstitial?"success":"default"}>{_("interstitial")}</Tag>)
                }
            },
            {
                title: _("banner"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_banner?"success":"default"}>{_("banner")}</Tag>)
                }
            },
            {
                title: _("splash"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_splash?"success":"default"}>{_("splash")}</Tag>)
                }
            },
            {
                title: _("native"),
                render: (text, entry) => {
                    return (<Tag color={entry.support_native?"success":"default"}>{_("native")}</Tag>)
                }
            },
            {
                render: (text, entry) => {
                    return (<Button icon={<EditOutlined />} onClick={(evt)=>{this.handleEdit(entry);}}>{_("Edit")}</Button>);
                }
            }
        ];
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
              xs: { span: 24, offset: 14 },
              sm: { span: 16, offset: 6 },
            },
          };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage ADNetwork")} key="manage_adnetwork">
                        <Button icon={<PlusOutlined/>} style={{marginBottom: 16}} type="primary" size="large" onClick={this.handleCreateNew}>{_("Create New ADNetwork")}</Button>
                        <Table
                            rowKey="uid"
                            columns={columns}
                            dataSource={this.state.adnetworkList}
                            loading={this.state.loading}
                            pagination={false}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={!!this.state.adnetwork && !!this.state.adnetwork.name ? this.state.adnetwork.name : _("Create New ADNetwork")}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined/>} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" key="save" icon={<SaveOutlined/>} loading={this.state.saving} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.adnetwork}>
                                <Form.Item name="uid" label="ID" rules={[{required: true}]}>
                                    <Input disabled={!!this.state.adnetwork && !!this.state.adnetwork.uid} />
                                </Form.Item>
                                <Form.Item name="name" label={_("AD Name")} rules={[{required: true}]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="description" label={_("Description")}>
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("AppStore")} name="support_ios" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("GooglePlay")} name="support_googleplay" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("ChineseAndroid")} name="support_chinese_android" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("rewarded_video")} name="support_rewarded_video" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("interstitial_video")} name="support_interstitial_video" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("interstitial")} name="support_interstitial" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("banner")} name="support_banner" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("splash")} name="support_splash" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("native")} name="support_native" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.List name="required_params">
                                    {(fields, {add, remove}) => {
                                        return (<div>
                                            {fields.map((field, idx) => (
                                                <Form.Item
                                                    {...(idx === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                    label={idx===0?_("Required Key"):""}
                                                    key={idx}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                    >
                                                        <Input style={{ width: '86%' }} />
                                                    </Form.Item>
                                                    {fields.length > 1 ? (
                                                        <MinusCircleOutlined
                                                            className="form-item-delete-btn"
                                                            style={{ margin: '0 8px' }}
                                                            onClick={() => {
                                                                remove(field.name);
                                                            }}
                                                         />
                                                    ) : null}
                                                </Form.Item>
                                            ))}
                                            <Form.Item {...formItemLayoutWithOutLabel}>
                                                    <Button
                                                        type="dashed"
                                                        onClick={()=>{
                                                            add();
                                                        }}
                                                        style={{ width: '86%' }}
                                                    >
                                                        <PlusOutlined />{_("Add")}
                                                    </Button>
                                                </Form.Item>
                                            </div>);
                                    }}
                                </Form.List>
                                <Form.List name="placement_params">
                                    {(fields, {add, remove}) => {
                                        return (<div>
                                            {fields.map((field, idx) => (
                                                <Form.Item
                                                    {...(idx === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                    label={idx===0?_("Placement Key"):""}
                                                    key={idx}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        validateTrigger={['onChange', 'onBlur']}
                                                        noStyle
                                                    >
                                                        <Input style={{ width: '86%' }} />
                                                    </Form.Item>
                                                    {fields.length > 1 ? (
                                                        <MinusCircleOutlined
                                                            className="form-item-delete-btn"
                                                            style={{ margin: '0 8px' }}
                                                            onClick={() => {
                                                                remove(field.name);
                                                            }}
                                                         />
                                                    ) : null}
                                                </Form.Item>
                                            ))}
                                            <Form.Item {...formItemLayoutWithOutLabel}>
                                                    <Button
                                                        type="dashed"
                                                        onClick={()=>{
                                                            add();
                                                        }}
                                                        style={{ width: '86%' }}
                                                    >
                                                        <PlusOutlined />{_("Add")}
                                                    </Button>
                                                </Form.Item>
                                            </div>);
                                    }}
                                </Form.List>
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
};
