import React from 'react';
import { Row, Tabs, Table, Form, DatePicker, Divider, Tooltip } from 'antd';
import { Chart, Line, Tooltip as BizTooltip, Axis, Polygon, Interaction, Point } from 'bizcharts';
import { GetRequest } from './Ajax';
import { AppSelect } from './AppSelect';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

export class UserStatisticsAnalysis extends React.Component {

    queryRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            query: {
                user_id: state.getState().userId,
                app_id: "",
                date: [moment().subtract(8, 'days'), moment().subtract(1, 'days')],
            },
            data_list: [],
            registerActiveLineData: [],
            userRetentionPolygonScale: {},
            userRetentionPolygonData: [],
            pagination: {
                defaultPageSize: 31,
                showSizeChanger: true
            },
        };
        this.queryResult = [];
        this.unsubscribe = null;
    }

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(() => {
            if (!!state.getState().token) {
                this.setState({
                    query: {
                        ...this.state.query,
                        user_id: state.getState().userId,
                        app_id: "",
                    },
                    data_list: [],
                    registerActiveLineData: [],
                    userRetentionPolygonData: [],
                });
                if (!!this.queryRef.current) {
                    this.queryRef.current.resetFields();
                }
                this.fetch();
            }
        });
        this.fetch(this.state.query.date);
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleDateSelect = (d) => {
        this.setState({
            query: {
                ...this.state.query,
                date: d
            }
        });
        this.fetch();
    }

    handleApplicationSelect = (puid, entry) => {
        this.setState({
            query: {
                ...this.state.query,
                app_id: puid,
            }
        });
        this.filter();
    }

    fetch = (date) => {
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
            if (!!this.queryRef.current) {
                var form = this.queryRef.current;
                date = form.getFieldValue('date');
            }
            if (!!date) {
                var start = date[0].format('YYYYMMDD');
                var end = date[1].format('YYYYMMDD');
                GetRequest('/user_statistics_analysis?start='+start+'&end='+end)
                    .then(resp => {
                        this.queryResult = resp.data;
                        this.setState({
                            loading: false,
                        });
                        this.filter();
                    })
                    .catch(error => {
                        this.setState({
                            loading: false,
                            data_list: [],
                            registerActiveLineData: [],
                        });
                        this.queryResult = [];
                    });
            }
        }
    }

    filter = () => {
        if (!!this.queryRef.current) {
            var form = this.queryRef.current;
            var appId = form.getFieldValue('app_id');
            var date = form.getFieldValue('date');
            var start = moment(date[0]);
            var until = moment(date[1]);
            var data_list = [];
            while(start.isSameOrBefore(until)) {
                data_list.push({
                    date: start.format('YYYY-MM-DD'),
                    user_register: 0,
                    user_active: 0,
                    user_retention_1: 0,
                    user_retention_2: 0,
                    user_retention_3: 0,
                    user_retention_5: 0,
                    user_retention_7: 0,
                    user_retention_10: 0,
                    user_retention_15: 0,
                    user_retention_30: 0,
                });
                start.add(1, 'day');
            }
            var dataFilterResult = this.queryResult.filter(entry => {
                if (!!appId && entry.product_uid !== appId) {
                    return false;
                }
                return true;
            });
            var dataMap = {}
            dataFilterResult.reduce((cxt, entry) => {
                var date = entry.date;
                if (!cxt[date]) {
                    cxt[date] = {
                        date: date,
                        user_register: 0,
                        user_active: 0,
                        user_retention_1: 0,
                        user_retention_2: 0,
                        user_retention_3: 0,
                        user_retention_5: 0,
                        user_retention_7: 0,
                        user_retention_10: 0,
                        user_retention_15: 0,
                        user_retention_30: 0,
                    };
                }
                cxt[date].user_register += entry.user_register;
                cxt[date].user_active += entry.user_active;
                cxt[date].user_retention_1 += entry.user_retention_1;
                cxt[date].user_retention_2 += entry.user_retention_2;
                cxt[date].user_retention_3 += entry.user_retention_3;
                cxt[date].user_retention_5 += entry.user_retention_5;
                cxt[date].user_retention_7 += entry.user_retention_7;
                cxt[date].user_retention_10 += entry.user_retention_10;
                cxt[date].user_retention_15 += entry.user_retention_15;
                cxt[date].user_retention_30 += entry.user_retention_30;
                return cxt;
            }, dataMap);
            data_list = data_list.map(entry => {
                if (!!dataMap[entry.date]) {
                    return dataMap[entry.date];
                }
                return entry;
            });
            var registerActiveLineData = [...data_list.map(entry => ({
                date: entry.date,
                type: _("Active User"),
                value: entry.user_active,
            })), ...data_list.map(entry => ({
                date: entry.date,
                type: _("Register User"),
                value: entry.user_register,
            }))];
            var userRetentionPolygonScale = {
                date: {
                  type: 'cat',
                  values: data_list.map(entry=>entry.date),
                },
                days: {
                  type: 'cat',
                  values: [
                      _("User Retention Rate 1day"), 
                      _("User Retention Rate 2day"), 
                      _("User Retention Rate 3day"), 
                      _("User Retention Rate 5day"),
                      _("User Retention Rate 7day"),
                      _("User Retention Rate 10day"),
                      _("User Retention Rate 15day"),
                      _("User Retention Rate 30day")
                    ],
                },
                retention: {
                  nice: true,
                }
              };
            var userRetentionPolygonData = [];
            data_list.forEach((entry, idx) => {
                userRetentionPolygonData.push({date: idx, days:0, retention:entry.user_retention_1});
                userRetentionPolygonData.push({date: idx, days:1, retention:entry.user_retention_2});
                userRetentionPolygonData.push({date: idx, days:2, retention:entry.user_retention_3});
                userRetentionPolygonData.push({date: idx, days:3, retention:entry.user_retention_5});
                userRetentionPolygonData.push({date: idx, days:4, retention:entry.user_retention_7});
                userRetentionPolygonData.push({date: idx, days:5, retention:entry.user_retention_10});
                userRetentionPolygonData.push({date: idx, days:6, retention:entry.user_retention_15});
                userRetentionPolygonData.push({date: idx, days:7, retention:entry.user_retention_30});
            });
            this.setState({
                data_list: data_list.reverse(),
                registerActiveLineData: registerActiveLineData,
                userRetentionPolygonScale: userRetentionPolygonScale,
                userRetentionPolygonData: userRetentionPolygonData,
            });
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { RangePicker } = DatePicker;
        const columns = [
            {
                title: _("Date"),
                dataIndex: "date"
            },{
                title: _("Register User"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN').format(entry.user_register)
            },{
                title: _("Active User"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN').format(entry.user_active)
            },{
                title: _("User Retention Rate 1day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_1)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_1 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                }</Tooltip>
            },{
                title: _("User Retention Rate 2day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_2)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_2 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                    }</Tooltip>
            },{
                title: _("User Retention Rate 3day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_3)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_3 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                    }</Tooltip>
            },{
                title: _("User Retention Rate 5day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_5)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_5 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                    }</Tooltip>
            },{
                title: _("User Retention Rate 7day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_7)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_7 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                    }</Tooltip>
            },{
                title: _("User Retention Rate 10day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_10)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_10 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                    }</Tooltip>
            },{
                title: _("User Retention Rate 15day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_15)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_15 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                    }</Tooltip>
            },{
                title: _("User Retention Rate 30day"),
                render: (txt, entry) => <Tooltip title={new Intl.NumberFormat('zh-CN').format(entry.user_retention_30)}>{
                    entry.user_register > 0 ? Number.parseFloat(entry.user_retention_30 * 100.0 / entry.user_register).toFixed(2)+"%" : '0%'
                    }</Tooltip>
            },
        ];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("User Statistics Analysis")} key="user_statistics_analysis">
                        <Row>
                            <Form layout="inline" ref={this.queryRef} initialValues={this.state.query}>
                                <Form.Item name="app_id" label={_("Select Product")}>
                                    <AppSelect 
                                        size="large" 
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        user_id={this.state.query.user_id}
                                        value={this.state.query.app_id}
                                        onChange={this.handleApplicationSelect} />
                                </Form.Item>
                                <Form.Item name="date" label={_("Date")}>
                                    <RangePicker
                                        size="large" 
                                        style={{width: 300, marginBottom: 16, marginRight: 4}} 
                                        onChange={this.handleDateSelect}
                                     />
                                </Form.Item>
                                </Form>
                        </Row>
                        <Table
                            rowKey="date"
                            columns={columns}
                            dataSource={this.state.data_list}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            summary={pageData => {
                                var totalRegister = 0;
                                var totalActivie = 0;
                                var totalRetentionDay1 = 0;
                                var totalRetentionDay2 = 0;
                                var totalRetentionDay3 = 0;
                                var totalRetentionDay5 = 0;
                                var totalRetentionDay7 = 0;
                                var totalRetentionDay10 = 0;
                                var totalRetentionDay15 = 0;
                                var totalRetentionDay30 = 0;
                                pageData.forEach(({user_register, user_active, 
                                    user_retention_1, user_retention_2, user_retention_3, 
                                    user_retention_5, user_retention_7, user_retention_10,
                                    user_retention_15, user_retention_30}) => {

                                    totalRegister += user_register;
                                    totalActivie += user_active;
                                    totalRetentionDay1 += user_retention_1;
                                    totalRetentionDay2 += user_retention_2;
                                    totalRetentionDay3 += user_retention_3;
                                    totalRetentionDay5 += user_retention_5;
                                    totalRetentionDay7 += user_retention_7;
                                    totalRetentionDay10 += user_retention_10;
                                    totalRetentionDay15 += user_retention_15;
                                    totalRetentionDay30 += user_retention_30;

                                });
                                return (<Table.Summary.Row>
                                    <Table.Summary.Cell>{_("Total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN').format(totalRegister)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN').format(totalActivie)}</Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay1}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay1 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay2}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay2 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay3}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay3 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay5}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay5 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay7}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay7 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay10}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay10 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay15}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay15 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                    <Table.Summary.Cell><Tooltip title={totalRetentionDay30}>{totalRegister > 0 ? Number.parseFloat(totalRetentionDay30 * 100.0/totalRegister).toFixed(2)+"%" : "0%"}</Tooltip></Table.Summary.Cell>
                                </Table.Summary.Row>);
                            }}
                        />
                        <Divider style={{marginTop:24, marginBottom:24}}>{_("User Register And Active")}</Divider>
                        <Chart height={480} autoFit data={this.state.registerActiveLineData} >
                            <Tooltip shared />
                            <Point position="date*value" shape="circle" />
                            <Line position="date*value" color="type" />
                        </Chart>
                        <Divider style={{marginTop:24, marginBottom:24}}>{_("User Retention Heat Map")}</Divider>
                        <Chart scale={this.state.userRetentionPolygonScale} height={480} data={this.state.userRetentionPolygonData} autoFit >
                                <Axis name={'date'} tickLine={null} grid={{
                                    alignTick: false,
                                    line: {
                                        style: {
                                            lineWidth: 1,
                                            lineDash: null,
                                            stroke: '#f0f0f0',
                                        },
                                    },
                                }} />
                                <Axis name={'days'} title={null} grid={{
                                    alignTick: false,
                                    line: {
                                        style: {
                                            lineWidth: 1,
                                            lineDash: null,
                                            stroke: '#f0f0f0',
                                        },
                                    },
                                }} />
                                <BizTooltip shared showMarkers={false}/>
                                <Polygon
                                    position={'date*days'}
                                    color={['retention', '#BAE7FF-#1890FF-#0050B3']}
                                    label={['retention', {
                                        offset: -2,
                                        style: {
                                            fill: '#fff',
                                            shadowBlur: 2,
                                            shadowColor: 'rgba(0, 0, 0, .45)',
                                        },
                                    }]}
                                    style={{
                                        lineWidth: 1,
                                        stroke: '#fff',
                                    }} >
                                </Polygon>
                                <Interaction type={'element-active'} />
                            </Chart>
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}