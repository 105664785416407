import React from 'react';
import { Select } from 'antd';
import { trans as _} from './i18n';

const { Option } = Select;

export class CurrencySelect extends React.Component {
    render(){
        return (
            <Select {...this.props}>
                <Option value="cny">{_("CNY")}</Option>
                <Option value="usd">{_("USD")}</Option>
            </Select>
        );
    };
};
