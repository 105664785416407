import React from 'react';
import { Row, Modal, Space, Typography, Button, Avatar, Tabs, Card, Empty, message } from 'antd';
import { PlusOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import { AppSelect } from './AppSelect';
import { PlacementList } from './PlacementList';
import { PlacementForm } from './PlacementForm';
import { PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';

const state = getState();

export class Placement extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            loading: false,
            user_id: state.getState().userId,
            product: null,
            product_uid: null,
            placement: {}
        };
        this.unsubscribe = null;
    };

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(() => {
            if (!!state.getState().token) {
                this.setState({
                    user_id: state.getState().userId
                });
                this.handleSelectProduct(null, null);
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleSelectProduct = (puid, entry) => {
        this.setState({
            product: entry,
            product_uid: puid
        });
        if (this.refs.placementList) {
            this.refs.placementList.fetch({
                product_uid: puid
            });
        }
    };

    handleEditPlacement = (placement) => {
        this.setState({
            placement: placement,
            modalVisible: true
        });
        if (!!this.refs.placementForm) {
            setTimeout(()=>{
                this.refs.placementForm.reset();
            }, 0);
        }
    };

    handleModalClose = () => {
        this.setState({
            modalVisible: false,
            loading: false
        });
    };

    handleModalSave = () => {
        if (!!this.refs.placementForm) {
            this.refs.placementForm.submit();
        }
    }

    handleModalFinish = (values) => {
        this.setState({loading: true});
        values["product_uid"] = this.state.product_uid;
        if (!!this.state.placement) {
            values["uid"] = this.state.placement.uid;
            if (!values["category"]) {
                values["category"] = this.state.placement.category;
            }
        }
        var url = '/placement';
        if (!!values.uid) {
            url += '/'+values.uid;
        }
        PostRequest(url, values)
            .then(resp => {
                this.setState({
                    loading: false,
                    placement: resp.data
                });
                message.success(_("Save successful"));
                if (this.refs.placementList) {
                    this.refs.placementList.updatePlacement(resp.data);
                }
            })
            .catch(error=>{
                this.setState({
                    loading: false
                });
                message.error(_("Save failure"));
            });
    };

    handleCreateNewPlacement = () => {
        this.handleEditPlacement({
            title: _("Create New Placement"),
            status: true,
            product: this.state.product_uid
        });
    };

    render(){
        const { TabPane } = Tabs;
        const { Title, Paragraph } = Typography;
        const { Meta } = Card;
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage Placement")} key="manage_placement">
                        <Row>
                            <AppSelect 
                                size="large" 
                                style={{width: 400, marginBottom: 16, marginRight: 4}} 
                                user_id={this.state.user_id}
                                value={this.state.product_uid}
                                onChange={this.handleSelectProduct} />
                            <Button 
                                icon={<PlusOutlined />} 
                                size="large" 
                                type="primary"
                                disabled={ !this.state.product_uid || this.state.product_uid.length===0 }
                                onClick={this.handleCreateNewPlacement}
                            >{_("Create New Placement")}</Button>
                        </Row>
                        <Card 
                            hoverable 
                            style={{ width: 260, marginTop: 16, marginBottom: 32 }}
                            cover={(!!this.state.product && !!this.state.product.icon && <img alt={this.state.product.name} src={this.state.product.icon} />) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                            <Meta
                                title={<a target="_blank" rel="noopener noreferrer" href={!!this.state.product ? this.state.product.url : "#"}>{!!this.state.product ? this.state.product.name : ""}</a>}
                                description={!!this.state.product && <div>
                                    <Paragraph copyable>{!!this.state.product ? this.state.product.uid : ""}</Paragraph>
                                    <p>{!!this.state.product ? this.state.product.bundle : ""}</p>
                                    <p>{!!this.state.product ? this.state.product.description : ""}</p>
                                </div>}
                            />
                        </Card>
                        <PlacementList ref="placementList" onEdit={this.handleEditPlacement} />
                        <Modal
                            visible={this.state.modalVisible}
                            title={this.state.product ? <Space><Avatar shape="square" src={this.state.product.icon} size={32} alt={this.state.product.uid} /><Title level={3}>{this.state.placement.name||this.state.placement.title}</Title></Space>: ""}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.loading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <PlacementForm ref="placementForm" placement={this.state.placement} onFinish={this.handleModalFinish} />
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
};
