import React from 'react';
import { Form, Input, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { PlatformSelect } from './PlatformSelect';
import { ScreenOrientationSelect } from './ScreenOrientationSelect';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import { PostRequest } from './Ajax';

const state = getState();

export class AppForm extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        var iconImg = null;
        var platform = null;
        if (!!props && !!props.product && !!props.product.icon) {
            iconImg = props.product.icon;
        }
        if (!!props && !!props.product && !!props.product.platform) {
            platform = props.product.platform;
        }
        this.state = {
            iconImg: iconImg,
            iconLoading: false,
            platform: platform,
            product: {...props.product},
            searchLoading: false,
        };
        this.iconUrl = null;
    }

    submit() {
        this.formRef.current.submit();
    }

    reset() {
        this.iconUrl = null;
        this.setState({
            iconImg: this.props.product.icon,
            iconLoading: false,
            platform: this.props.product.platform,
            product: {...this.props.product}
        })
        this.formRef.current.resetFields();
    }

    beforeProductIconUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error(_('You can only upload JPG/PNG file!'));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error(_('Image must smaller than 2MB!'));
        }
        return isJpgOrPng && isLt2M;
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleIconUploadChange = info => {
        if (info.file.status === 'uploading') {
          this.setState({ iconLoading: true });
          return;
        }
        if (info.file.status === 'done') {
          this.iconUrl = info.file.response;
          this.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
              iconImg: imageUrl,
              iconLoading: false,
            }),
          );
        }
      };

    onFinish = (values) => {
        if (!!this.iconUrl) {
            values['icon'] = this.iconUrl;
        } else {
            values['icon'] = this.props.product.icon;
        }
        if (!!this.props.onFinish) {
            this.props.onFinish(values);
        }
    }

    handleSearchDownloadURL = (value) => {
        if (!!value) {
            this.setState({searchLoading: true});
            var data = {
                platform: this.state.platform,
                url: value,
            };
            PostRequest('/search_download_url', data)
                .then(resp => {
                    this.iconUrl = resp.data.icon;
                    this.setState({
                        searchLoading: false,
                        iconImg: resp.data.icon,
                        product: {...this.props.product, ...resp.data, platform: this.state.platform},
                    });
                    if (!!this.formRef && !!this.formRef.current) {
                        this.formRef.current.resetFields();
                    }
                })
                .catch(error => {
                    this.iconUrl = null;
                    this.setState({
                        searchLoading: false,
                        iconImg: this.props.product.icon,
                        product: {...this.props.product, url: value, platform: this.state.platform},
                    });
                    if (!!this.formRef && !!this.formRef.current) {
                        this.formRef.current.resetFields();
                    }
                    message.error(_("Search Fail"));
                });
        }
    }

    render() {
        const { Search } = Input;
        const formItemLayout = {
            labelCol: {
              xs: { span: 12 },
              sm: { span: 4 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const uploadButton = (
            <div>
              {this.state.iconLoading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">Upload</div>
            </div>
          );
        return (
            <Form {...formItemLayout} ref={this.formRef} onFinish={this.onFinish} initialValues={this.state.product} >
                <Form.Item label={_("Platform")}
                    name='platform'
                    rules={[
                        {
                            required: true,
                            message: _("Required and not empty")
                        }
                    ]}
                >
                    <PlatformSelect onChange={(val) => {this.setState({platform: val});}} />
                </Form.Item>
                <Form.Item label={_("Download URL")}
                    name='url'
                    rules={[
                        {
                            required: true,
                            message: _("Required and not empty")
                        }
                    ]}
                >
                    {this.state.platform==='appstore' ? <Search
                        allowClear
                        enterButton={_("Search From AppStore")}
                        placeholder="https://apps.apple.com/cn/app/id1234567890"
                        size="large"
                        loading={this.state.searchLoading}
                        onSearch={this.handleSearchDownloadURL}
                     /> : <Input />}
                </Form.Item>
                <Form.Item label={_("Product Name")}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: _("Required and not empty")
                        }
                    ]}><Input />
                </Form.Item>
                <Form.Item label={_("Screen Orientation")}
                    name='orientation'
                    rules={[
                        {
                            required: true,
                            message: _("Required and not empty")
                        }
                    ]}><ScreenOrientationSelect />
                </Form.Item>
                <Form.Item label={_("Bundle")}
                    name='bundle'
                    rules={[
                        {
                            required: true,
                            message: _("Required and not empty")
                        }
                    ]}><Input />
                </Form.Item>
                <Form.Item label={_("Product Icon")} >
                    <Upload
                        name="icon"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        headers={{Authorization: "Token "+state.getState().token}}
                        action="/restapi/icon/upload"
                        beforeUpload={this.beforeProductIconUpload}
                        onChange={this.handleIconUploadChange}
                    >
                        {this.state.iconImg ? <img src={this.state.iconImg} alt="icon" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item label={_("Product Description")}
                    name='description'
                    rules={[
                        {
                            required: true,
                            message: _("Required and not empty")
                        }
                    ]}><Input.TextArea />
                </Form.Item>
            </Form>
        );
    };
};
