import React from 'react';
import { Row, Modal, Button, Tabs, Table, Avatar, Tooltip, Form, InputNumber, DatePicker, message, Switch, Popconfirm } from 'antd';
import { CloseOutlined, SaveOutlined, EditOutlined, ScheduleOutlined } from '@ant-design/icons';
import { GetRequest, PostRequest } from './Ajax';
import { AppSelect } from './AppSelect';
import { ADNetworkSelect } from './ADNetworkSelect'
import { ECPMModelSelect } from './ECPMModelSelect';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

export class BillReview extends React.Component {

    formRef = React.createRef();
    queryRef = React.createRef();
    filterRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            divideRecordsLoading: false,
            loading: false,
            query: {
                user_id: state.getState().userId,
                app_id: "",
                date: [moment().subtract(1, 'days'), moment()]
            },
            filter: {
                ads: [],
                reviewed: false,
                has_revenue: false,
            },
            bills: [],
            bill_list: [],
            divide_records: [],
            pagination: {
                defaultPageSize: 31,
                showSizeChanger: true
            },
            bill: {}
        };
        this.unsubscribe = null;
    }

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(() => {
            if (!!state.getState().token) {
                this.setState({
                    query: {
                        ...this.state.query,
                        user_id: state.getState().userId,
                        app_id: ""
                    },
                    bills: [],
                    bill_list: []
                });
                if (!!this.queryRef.current) {
                    this.queryRef.current.submit();
                }
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleSelectUser = (userId) => {

    }

    handleSelectApplication = (puid, entry) => {
        this.setState({
            bill: {...this.state.bill, appid: puid, product: entry}
        })
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleBillQuery = (values) => {
        if (!!this.queryRef.current) {
            this.queryRef.current.submit();
        }
    }

    handleBillQueryDo = (values) => {
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
            var start = values.date[0].format('YYYYMMDD');
            var end = values.date[1].format('YYYYMMDD');
            var appId = values.app_id || "";
            var userId = this.state.query.user_id || "";
            var url = '/bill_review?user_id='+userId+'&app_id='+appId+'&start='+start+'&end='+end;
            GetRequest(url)
                .then(resp => {
                    this.setState({
                        loading: false,
                        bills: [...resp.data],
                        bill_list: [...resp.data],
                        filter: {
                            ads: resp.data.filter((entry, idx) => resp.data.indexOf(entry)===idx).map(entry=>entry.ad_network),
                            reviewed: false,
                            has_revenue: false,
                        },
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        bills: [],
                        bill_list: [],
                        filter: {
                            ads: [],
                            reviewed: false,
                            has_revenue: false,
                        },
                    });
                });
        }
    }

    handleBillFilter = (values) => {
        if (!!this.filterRef && !!this.filterRef.current && Array.isArray(this.state.bill_list)) {
            var filterForm = this.filterRef.current;
            var adNetwork = filterForm.getFieldValue('ad_network');
            var hasRevenue = filterForm.getFieldValue('has_revenue');
            var onlyReviewed = filterForm.getFieldValue('reviewed');
            var bill_list = this.state.bills.filter(entry => {
                if (!!adNetwork && adNetwork!==entry.ad_network) {
                    return false;
                }
                if (hasRevenue===true && entry.bill_revenue<=0) {
                    return false;
                }
                if (onlyReviewed===true && entry.status<=0) {
                    return false;
                }
                return true;
            });
            this.setState({
                bill_list: bill_list,
            })
        }
    }

    handleReviewAllBills = () => {
        if (Array.isArray(this.state.bill_list) && this.state.bill_list.length > 0) {
            var billIds = this.state.bill_list.filter(entry=>entry.status===0).map(entry=>entry.id);
            if (billIds.length > 0) {
                this.setState({
                    saveLoading: true,
                    loading: true,
                });
                PostRequest("/bill_review_all", {user_id: this.state.query.user_id || "", bill_ids: billIds})
                    .then(resp => {
                        var bills = this.state.bills.map(entry => {
                            var found = billIds.filter(bid=>bid===entry.id)[0];
                            if (!!found) {
                                return {...entry, status:2};
                            }
                            return entry;
                        });
                        var bill_list = this.state.bill_list.map(entry=>{
                            var found = billIds.filter(bid=>bid===entry.id)[0];
                            if (!!found) {
                                return {...entry, status:2};
                            }
                            return entry;
                        });
                        this.setState({
                            saveLoading: false,
                            loading: false,
                            bills: bills,
                            bill_list: bill_list,
                        });
                        message.success(_("Save successful"));
                    })
                    .catch(error => {
                        this.setState({
                            saveLoading: false,
                            loading: false,
                        });
                        message.error(_("Save failure"));
                    });
            }
        }
    }

    handleBillReviewEdit = (evt, entry) => {
        this.setState({
            modalVisible: true,
            bill: entry
        });
        setTimeout(()=>{
            if (!!this.formRef.current) {
                this.formRef.current.resetFields();
            }
            this.setState({divideRecordsLoading: true});
            GetRequest('/divide_record/'+entry.product_uid+'/'+entry.id)
                .then(resp => {
                    this.setState({
                        divideRecordsLoading: false,
                        divide_records: resp.data
                    });
                })
                .catch(error => {
                    this.setState({
                        divideRecordsLoading: false,
                        divide_records: []
                    });
                });
        }, 0);
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        var data = {
            ...this.state.bill,
            ...values
        };
        if (!!values.is_publish) {
            data['status'] = 2;
        } else {
            data['status'] = 0;
        }
        this.setState({saveLoading: true});
        PostRequest('/bill_review/'+this.state.bill.product_uid+'/'+data.id, data)
            .then(resp => {
                this.setState({
                    saveLoading: false,
                    bills: this.state.bills.map(entry => {
                        if (entry.id===resp.data.id){
                            return resp.data;
                        } else {
                            return entry;
                        }
                    }),
                    bill_list: this.state.bill_list.map(entry => {
                        if (entry.id===resp.data.id){
                            return resp.data;
                        } else {
                            return entry;
                        }
                    })
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    handleUpdateBillStatus = (bill, stat) => {
        if (!!stat) {
            bill.status = 2;
        } else {
            bill.status = 0;
        }
        this.setState({loading: true});
        PostRequest('/bill_review/'+bill.product_uid+'/'+bill.id, bill)
            .then(resp => {
                this.setState({
                    loading: false,
                    bills: this.state.bills.map(entry => {
                        if (entry.id===resp.data.id){
                            return resp.data;
                        } else {
                            return entry;
                        }
                    }),
                    bill_list: this.state.bill_list.map(entry => {
                        if (entry.id===resp.data.id){
                            return resp.data;
                        } else {
                            return entry;
                        }
                    })
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({loading: false});
                message.error(_("Save failure"));
            });
    }

    handleECPMModelChange = () => {
        var ecpm = this.formRef.current.getFieldValue('ecpm');
        var model = this.formRef.current.getFieldValue('ecpm_model');
        var complete = this.formRef.current.getFieldValue('bill_complete');
        var show = this.formRef.current.getFieldValue('bill_show');
        var impression = Number.parseInt(complete);
        if (!impression) {
            impression = Number.parseInt(show);
        }
        var oriComplete = this.formRef.current.getFieldValue('complete');
        var oriShow = this.formRef.current.getFieldValue('show');
        var oriImpression = Number.parseInt(oriComplete);
        if (!oriImpression) {
            oriImpression = Number.parseInt(oriShow);
        }
        var revenue = this.formRef.current.getFieldValue('bill_revenue');
        var oriRevenue = this.formRef.current.getFieldValue('revenue');
        var divide = 0.0;
        if ('revenue'===model) {
            divide = Number.parseFloat(((revenue * 100000.0)/ecpm)/oriImpression).toFixed(2);
            this.formRef.current.setFieldsValue({
                show_divide: divide,
                bill_show: Number.parseInt(oriShow*divide/100.0),
                complete_divide: divide,
                bill_complete: Number.parseInt(oriComplete*divide/100.0)
            });
        } else if ('impression'===model) {
            divide = Number.parseFloat((ecpm*(impression/10.0))/oriRevenue).toFixed(2);
            this.formRef.current.setFieldsValue({
                revenue_divide: divide,
                bill_revenue: Number.parseFloat(oriRevenue*divide/100.0).toFixed(2)
            });
        } else {
            if (complete > 0) {
                this.formRef.current.setFieldsValue({
                    ecpm: Number.parseFloat(revenue / (complete / 1000.0)).toFixed(2)
                });
            } else if (show > 0)  {
                this.formRef.current.setFieldsValue({
                    ecpm: Number.parseFloat(revenue / (show / 1000.0)).toFixed(2)
                });
            } else {
                this.formRef.current.setFieldsValue({
                    ecpm: 0.00
                });
            }
        }
    }

    refreshECPM = () => {
        var complete = this.formRef.current.getFieldValue('bill_complete');
        var show = this.formRef.current.getFieldValue('bill_show');
        var impression = Number.parseInt(complete);
        var revenue = this.formRef.current.getFieldValue('bill_revenue');
        if (!impression) {
            impression = Number.parseInt(show);
        }
        if (impression > 0) {
            this.formRef.current.setFieldsValue({
                ecpm: Number.parseFloat(revenue/(impression/1000.0)).toFixed(2)
            })
        } else {
            this.formRef.current.setFieldsValue({
                ecpm: 0.00
            });
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { RangePicker } = DatePicker;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const formNumberItemLayout = {
            style: {marginBottom: 0}
        };
        const formNumberItemInputLayout = {
            style: { display: 'inline-block', width: 'calc(30% - 8px)' }
        };
        const columns = [
            {
                title: _("Date"),
                render: (txt, entry) => {
                    return moment(entry.day_id, 'YYYYMMDD').format('YYYY-MM-DD')
                }
            },
            {
                render: (txt, entry) => <Avatar shape="square" size={48} src={entry.product_icon} alt={entry.product_name} />
            },
            {
                title: _("Product Name"),
                render: (txt, entry) => {
                return (<Tooltip title={entry.product_uid}>
                    <span>{entry.product_name}</span>
                </Tooltip>);
                }
            },
            {
                title: _("Placement Name"),
                render: (txt, entry) => {
                    return (<Tooltip title={entry.placement.uid}>
                        <span>{entry.placement.name}</span>
                    </Tooltip>);
                }
            },
            {
                title: _("AD Plan"),
                render: (txt, entry) => {
                    return (<div>
                        {entry.ad_plan_name}<br/>{entry.ad_plan_target}
                    </div>);
                }
            },
            {
                title: _("AD Network"),
                render: (txt, entry) => {
                    return entry.ad_network;
                }
            },
            {
                title: _("Show"),
                render: (txt, entry) => {
                    return (<div>
                        {entry.bill_show}<br/>({entry.show})
                    </div>);
                }
            },
            {
                title: _("Revenue"),
                render: (txt, entry) => {
                    var billRevenue = new Intl.NumberFormat('zh-CN', {style: 'currency', currency: entry.currency}).format(entry.bill_revenue);
                    var revenue = new Intl.NumberFormat('zh-CN', {style: 'currency', currency: entry.currency}).format(entry.revenue);
                    return (<div>
                        {billRevenue}<br/>{revenue}
                    </div>);
                }
            },
            {
                title: _("eCPM"),
                render: (txt, entry) => entry.ecpm.toFixed(2)
            },
            {
                title: _("Status"),
                render: (txt, entry) => {
                    return (<Tooltip title={entry.status===0?_("Unreview"):entry.status===1?_("Autoreviewed"):_("Manualreviewed")}>
                        <Switch
                            checked={entry.status>0}
                            onChange={() => {this.handleUpdateBillStatus(entry, !(entry.status>0)); }}
                        />
                    </Tooltip>);
                }
            },
            {
                render: (text, entry) => {
                    return (<Button 
                        icon={<EditOutlined />} 
                        onClick={(evt)=>{this.handleBillReviewEdit(evt, entry);}}>{_("Edit")}</Button>);
                }
            }
        ];
        const recordsColumn = [
            {
                title: _("Show Divide"),
                render: (txt, entry) => Number.parseFloat(entry.show_divide).toFixed(2)+'%'
            },
            {
                title: _("Play Divide"),
                render: (txt, entry) => Number.parseFloat(entry.play_divide).toFixed(2)+'%'
            },
            {
                title: _("Complete Divide"),
                render: (txt, entry) => Number.parseFloat(entry.complete_divide).toFixed(2)+'%'
            },
            {
                title: _("Click Divide"),
                render: (txt, entry) => Number.parseFloat(entry.click_divide).toFixed(2)+'%'
            },
            {
                title: _("Revenue Divide"),
                render: (txt, entry) => Number.parseFloat(entry.revenue_divide).toFixed(2)+'%'
            },
            {
                title: _("Review User"),
                render: (txt, entry) => (<div>{entry.user.username}<br/>{entry.user.email}</div>)
            },
            {
                title: _("Date"),
                render: (txt, entry) => entry.update_time
            }
        ];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Bill Review")} key="bill_review">
                        <Row>
                            <Form layout="inline" ref={this.queryRef} 
                                onValuesChange={this.handleBillQuery} 
                                onFinish={this.handleBillQueryDo}
                                initialValues={this.state.query}>
                                <Form.Item name="app_id">
                                    <AppSelect 
                                        size="large" 
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        user_id={this.state.query.user_id}
                                        value={this.state.query.app_id}
                                        onChange={this.handleSelectApplication} />
                                </Form.Item>
                                <Form.Item name="date">
                                    <RangePicker
                                        size="large" 
                                        style={{width: 300, marginBottom: 16, marginRight: 4}} 
                                     />
                                </Form.Item>
                                
                            </Form>
                        </Row>
                        {Array.isArray(this.state.bills) && this.state.bills.length > 0 && <Row>
                            <Form layout="inline" ref={this.filterRef}
                                onValuesChange={this.handleBillFilter}
                                initialValues={this.state.filter}>
                                <Form.Item name="ad_network">
                                    <ADNetworkSelect
                                        size="large"
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        ad_include={this.state.filter.ads} />
                                </Form.Item>
                                <Form.Item name="has_revenue" valuePropName="checked">
                                    <Switch
                                        checkedChildren={_("Only Revenue")}
                                        unCheckedChildren={_("Only Revenue")} />
                                </Form.Item>
                                <Form.Item name="reviewed" valuePropName="checked">
                                    <Switch
                                        checkedChildren={_("Only Reviewed")}
                                        unCheckedChildren={_("Only Reviewed")} />
                                </Form.Item>
                                <Form.Item>
                                    <Popconfirm 
                                        title={_("Are you sure to review all ad bills?")}
                                        onConfirm={this.handleReviewAllBills}
                                        okText="Yes"
                                        cancelText="No">
                                        <Button 
                                            icon={<ScheduleOutlined />} 
                                            loading={this.state.saveLoading} 
                                            type="primary" size="large">{_("Review all bills")}</Button>
                                    </Popconfirm>
                                </Form.Item>
                            </Form>
                        </Row>}
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={this.state.bill_list}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            summary={pageData => {
                                if (!Array.isArray(pageData) || pageData.length <= 0) {
                                    return (<></>);
                                }
                                var totalBillShow = 0;
                                var totalShow = 0;
                                var totalBillRevenue = 0.0;
                                var totalRevenue = 0.0;
                                var totalECPM = 0.0;
                                pageData.forEach(({show, bill_show, revenue, bill_revenue}) => {
                                    totalBillShow += bill_show;
                                    totalShow += show;
                                    totalBillRevenue += bill_revenue;
                                    totalRevenue += revenue;
                                });
                                if (totalBillShow>0) {
                                    totalECPM = totalBillRevenue/(totalBillShow/1000.0);
                                }
                                var currencyList = pageData.map(entry=>entry.currency);
                                var currencies = currencyList.filter((cur,idx)=>currencyList.indexOf(cur)===idx);
                                var currency = undefined;
                                if (Array.isArray(currencies) && 1===currencies.length) {
                                    currency = currencies[0];
                                }
                                if (!!currency) {
                                    totalBillRevenue = new Intl.NumberFormat('zh-CN', {style: 'currency', currency: currency}).format(totalBillRevenue);
                                    totalRevenue = new Intl.NumberFormat('zh-CN', {style: 'currency', currency: currency}).format(totalRevenue);
                                    totalECPM = new Intl.NumberFormat('zh-CN', {style: 'currency', currency: currency}).format(totalECPM);
                                } else {
                                    totalBillRevenue = Number.parseFloat(totalBillRevenue).toFixed(2);
                                    totalRevenue = Number.parseFloat(totalRevenue).toFixed(2);
                                    totalECPM = Number.parseFloat(totalECPM).toFixed(2);
                                }
                                return (<Table.Summary.Row>
                                    <Table.Summary.Cell>{_("Total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>{<div>{totalBillShow}<br/>({totalShow})</div>}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{<div>{totalBillRevenue}<br/>{totalRevenue}</div>}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalECPM}</Table.Summary.Cell>
                                </Table.Summary.Row>);
                            }}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={_("Edit Bill")}
                            width={"60%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.bill}>
                                <Form.Item label={_("Request")} {...formNumberItemLayout} >
                                    <Form.Item name="request" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_show: Number.parseInt(v * (this.state.bill.request_divide / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="request_divide" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_request: Number.parseInt(this.state.bill.request * (v / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="bill_request" {...formNumberItemInputLayout} ><InputNumber disabled /></Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Fill")} {...formNumberItemLayout} >
                                    <Form.Item name="fill" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_fill: Number.parseInt(v * (this.state.bill.fill_divide / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="fill_divide" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_fill: Number.parseInt(this.state.bill.fill * (v / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="bill_fill" {...formNumberItemInputLayout} ><InputNumber disabled /></Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Show")} {...formNumberItemLayout} >
                                    <Form.Item name="show" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_show: Number.parseInt(v * (this.state.bill.show_divide / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="show_divide" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_show: Number.parseInt(this.state.bill.show * (v / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="bill_show" {...formNumberItemInputLayout} ><InputNumber disabled /></Form.Item>
                                </Form.Item>
                                {!!this.state.bill.placement && this.state.bill.placement.category.indexOf("video") > 0 && (<Form.Item label={_("Play")} {...formNumberItemLayout} >
                                    <Form.Item name="play" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_play: Number.parseInt(v * (this.state.bill.play_divide / 100.0))
                                                });
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="play_divide" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_play: Number.parseInt(this.state.bill.play * (v / 100.0))
                                                });
                                            }} />
                                        </Form.Item>
                                    <Form.Item name="bill_play" {...formNumberItemInputLayout} ><InputNumber disabled /></Form.Item>
                                </Form.Item>)}
                                {!!this.state.bill.placement && this.state.bill.placement.category.indexOf("video") > 0 && (<Form.Item label={_("Complete")} {...formNumberItemLayout} >
                                    <Form.Item name="complete" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_complete: Number.parseInt(v * (this.state.bill.complete_divide / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="complete_divide" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_complete: Number.parseInt(this.state.bill.complete * (v / 100.0))
                                                });
                                                this.refreshECPM();
                                            }} />
                                        </Form.Item>
                                    <Form.Item name="bill_complete" {...formNumberItemInputLayout} ><InputNumber disabled /></Form.Item>
                                </Form.Item>)}
                                <Form.Item label={_("Click")} {...formNumberItemLayout} >
                                    <Form.Item name="click" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_click: Number.parseInt(v * (this.state.bill.click_divide / 100.0))
                                                });
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="click_divide" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_click: Number.parseInt(this.state.bill.click * (v / 100.0))
                                                });
                                            }} />
                                        </Form.Item>
                                    <Form.Item name="bill_click" {...formNumberItemInputLayout} ><InputNumber disabled /></Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Revenue")} {...formNumberItemLayout} >
                                    <Form.Item name="revenue" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => Number.parseFloat(value).toFixed(2)}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_revenue: Number.parseFloat(v * (this.state.bill.revenue_divide / 100.0)).toFixed(2)
                                                });
                                                this.refreshECPM();
                                            }} />
                                    </Form.Item>
                                    <Form.Item name="revenue_divide" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            onChange={(v)=>{
                                                this.formRef.current.setFieldsValue({
                                                    bill_revenue: Number.parseFloat(this.state.bill.revenue * (v / 100.0)).toFixed(2)
                                                });
                                                this.refreshECPM();
                                            }} />
                                        </Form.Item>
                                    <Form.Item name="bill_revenue" {...formNumberItemInputLayout} >
                                        <InputNumber
                                            formatter={value => Number.parseFloat(value).toFixed(2)}
                                            disabled />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("eCPM")} {...formNumberItemLayout}>
                                    <Form.Item name="ecpm" {...formNumberItemInputLayout}>
                                        <InputNumber
                                            formatter={value => Number.parseFloat(value).toFixed(2)}
                                        />
                                    </Form.Item>
                                    <Form.Item name="ecpm_model" style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
                                        <ECPMModelSelect onChange={this.handleECPMModelChange} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Publish or not")} name="is_publish" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Form>
                            <Table
                                rowKey="id"
                                columns={recordsColumn}
                                dataSource={this.state.divide_records}
                                loading={this.state.divideRecordsLoading}
                                pagination={false}
                            />
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}