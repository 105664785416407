import React from 'react';
import { Row, Modal, Button, Tabs, Table, Form, AutoComplete, Input, InputNumber, DatePicker, message } from 'antd';
import { PlusOutlined, CloseOutlined, SaveOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { ADNetworkSelect } from './ADNetworkSelect';
import { CurrencySelect } from './CurrencySelect';
import { PlacementCategorySelect } from './PlacementCategorySelect';
import { AppSelect } from './AppSelect';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

export class BillFetch extends React.Component {

    formRef = React.createRef();
    queryRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            query: {
                ad: "",
                date: [moment().subtract(1, 'days'), moment()]
            },
            autoComplete: {
                user_id: state.getState().userId,
                app_id: "",
                adAppIdOptions: [],
                adPlacementIdOptions: [],
            },
            bill_list: [],
            pagination: {
                defaultPageSize: 31,
                showSizeChanger: true
            },
            bill: {
                ad_network: "",
                ad_app_id: "",
                ad_app_name: "",
                country: "CN",
                timezone: "UTC+08:00",
                date: moment().subtract(1, 'days'),
                ad_placement: "",
                ad_type: "",
                original_type: "",
                requests: 0,
                available: 0,
                impressions: 0,
                completes: 0,
                clicks: 0,
                installs: 0,
                revenue: 0.0,
                currency: "CNY",
                ecpm: 0.0
            }
        };
        this.productParameterData = null;
        this.unsubscribe = null;
    }

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(() => {
            if (!!state.getState().token) {
                this.setState({
                    autoComplete: {
                        ...this.state.autoComplete,
                        user_id: state.getState().userId,
                        app_id: ""
                    }
                });
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    refreshAdAutoComplete = () => {
        var adAppIdOptions = [];
        var adPlacementIdOptions = [];
        var adPlacementIdOptionsMap = {};
        if (!!this.formRef && !!this.productParameterData) {
            var form = this.formRef.current;
            if (!!form) {
                var adNetwork = form.getFieldValue("ad_network");
                if (!!adNetwork) {
                    var adParameters = this.productParameterData.parameters.filter(e => !!e && e.ad===adNetwork)[0];
                    if (!!adParameters && !!adParameters.parameters) {
                        var parameters = JSON.parse(adParameters.parameters);
                        if (!!parameters && !!parameters.required && Array.isArray(parameters.required.app_id)) {
                            adAppIdOptions = parameters.required.app_id.map(e => {return {value: e};});
                        }
                    }
                    if (!!parameters && !!parameters.placements && Array.isArray(this.productParameterData.placements)) {
                        this.productParameterData.placements.forEach(e => {
                            if (!!e.uid && Array.isArray(parameters.placements[e.uid])) {
                                var adPlacementIdOptions = parameters.placements[e.uid].map(ap => {return {value: ap.ad_placement};});
                                if (!Array.isArray(adPlacementIdOptionsMap[e.category])) {
                                    adPlacementIdOptionsMap[e.category] = adPlacementIdOptions;
                                } else {
                                    adPlacementIdOptionsMap[e.category].push(...adPlacementIdOptions);
                                }
                            }
                        });
                    }
                }
                var placementCategory = form.getFieldValue("ad_type");
                if (!!placementCategory && Array.isArray(adPlacementIdOptionsMap[placementCategory])) {
                    adPlacementIdOptions = adPlacementIdOptionsMap[placementCategory];
                }
            }
        }
        this.setState({
            autoComplete: {
                ...this.state.autoComplete,
                adAppIdOptions: adAppIdOptions,
                adPlacementIdOptions: adPlacementIdOptions,
            }
        });
    }

    handleSelectApplication = (puid, entry) => {
        this.setState({
            autoComplete: {
                ...this.state.autoComplete,
                app_id: puid,
            },
            bill: {
                ...this.state.bill,
                ad_app_id: "",
                ad_placement: ""
            }
        });
        GetRequest("/parameter/"+puid)
            .then(resp => {
                this.productParameterData = resp.data;
                if (!!this.formRef.current) {
                    setTimeout(()=>{
                        this.formRef.current.resetFields();
                        this.refreshAdAutoComplete();
                    }, 0);
                }
            })
            .catch(err => {
                this.productParameterData = null;
                if (!!this.formRef.current) {
                    setTimeout(()=>{
                        this.formRef.current.resetFields();
                        this.refreshAdAutoComplete();
                    }, 0);
                }
            });
    }

    handlePlacementCategory = (category) => {
        this.setState({
            bill: {...this.state.bill, ad_type: category, ad_placement: ""}
        })
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
                this.refreshAdAutoComplete();
            }, 0);
        }
    }

    handleSelectAdNetwork = (ad, entry) => {
        this.setState({
            bill: {...this.state.bill,
                ad_network: ad,
                ad_template: entry,
                ad_app_id: "",
                ad_placement: ""
            }
        })
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
                this.refreshAdAutoComplete();
            }, 0);
        }
    }

    handleBillQuery = (values) => {
        if (!!this.queryRef.current) {
            this.queryRef.current.submit();
        }
    }

    handleBillQueryDo = (values) => {
        if (!this.state.loading && !!values.ad) {
            this.setState({
                loading: true,
                query: values
            });
            GetRequest('/bill_fetch/'+values.ad+'?start='+values.date[0].format('YYYY-MM-DD')+'&end='+values.date[1].format('YYYY-MM-DD'))
                .then(resp => {
                    this.setState({
                        loading: false,
                        bill_list: resp.data.map((entry, idx)=>{
                            entry.id=idx;
                            return entry;
                        })
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        bill_list: []
                    });
                });
        }
    }

    handleFetchBill = () => {
        var form = this.queryRef.current;
        if (!!form) {
            var ad = form.getFieldValue("ad");
            var date = form.getFieldValue('date');
            if (!!ad && !!date) {
                var start = moment(date[0]).format('YYYY-MM-DD');
                var end = moment(date[1]).format('YYYY-MM-DD');
                this.setState({loading: true});
                GetRequest('/fetch_bill/'+ad+'/'+start+'/'+end)
                    .then(resp => {
                        this.setState({
                            loading: false,
                            bill_list: resp.data.map((entry, idx)=>{
                                entry.id=idx;
                                return entry;
                            })
                        })
                    })
                    .catch(error => {
                        this.setState({
                            loading: false,
                            bill_list: []
                        });
                    });
            }
        }
    }

    handleCreateNewBill = () => {
        this.setState({modalVisible: true});
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        values['date'] = values.date.format("YYYYMMDD");
        PostRequest('/bill_fetch/'+values.ad_network, values)
            .then(resp => {
                this.setState({saveLoading: false});
                message.success(_("Save successful"));
                this.handleBillQueryDo(this.state.query);
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    render() {
        const { TabPane } = Tabs;
        const { RangePicker } = DatePicker;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const columns = [
            {
                title: _("Date"),
                render: (txt, entry) => {
                    return moment(entry.date, 'YYYYMMDD').format('YYYY-MM-DD')
                }
            },
            {
                title: _("Product ID"),
                dataIndex: "ad_app_id"
            },
            {
                title: _("Product Name"),
                dataIndex: "ad_app_name"
            },
            {
                title: _("Placement ID"),
                dataIndex: "ad_placement"
            },
            {
                title: _("Show"),
                dataIndex: "impressions"
            },
            {
                title: _("Complete"),
                dataIndex: "completes"
            },
            {
                title: _("Click"),
                dataIndex: "clicks"
            },
            {
                title: _("Revenue"),
                dataIndex: "revenue"
            },
            {
                title: _("Currency"),
                dataIndex: "currency"
            }
        ];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Bill Fetch")} key="bill_fetch">
                        <Row>
                            <Form layout="inline" ref={this.queryRef}
                                onValuesChange={this.handleBillQuery}
                                onFinish={this.handleBillQueryDo}
                                initialValues={this.state.query}>
                                <Form.Item name="ad">
                                    <ADNetworkSelect
                                        size="large"
                                        style={{width: 200, marginBottom: 16, marginRight: 4}}
                                        onChange={this.handleSelectAdNetwork} />
                                </Form.Item>
                                <Form.Item name="date">
                                    <RangePicker
                                        size="large"
                                        style={{width: 300, marginBottom: 16, marginRight: 4}}
                                     />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        icon={<CloudDownloadOutlined />}
                                        size="large"
                                        type="primary"
                                        onClick={this.handleFetchBill}
                                    >{_("Fetch Bill")}</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        icon={<PlusOutlined />}
                                        size="large"
                                        type="default"
                                        onClick={this.handleCreateNewBill}
                                    >{_("Create New Bill")}</Button>
                                </Form.Item>
                            </Form>
                        </Row>
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={this.state.bill_list}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            summary={(data) => {
                                var totalImpressions = 0;
                                var totalCompletes = 0;
                                var totalClicks = 0;
                                var totalRevenue = 0.0;
                                data.forEach(({impressions, completes, clicks, revenue}) => {
                                    totalImpressions += impressions;
                                    totalCompletes += completes;
                                    totalClicks += clicks;
                                    totalRevenue += revenue;
                                });
                                return (<Table.Summary.Row>
                                    <Table.Summary.Cell>{_("Total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell />
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN').format(totalImpressions)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN').format(totalCompletes)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN').format(totalClicks)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN').format(totalRevenue)}</Table.Summary.Cell>
                                    <Table.Summary.Cell />
                                </Table.Summary.Row>);
                            }}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={_("Create New Bill")}
                            width={"60%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.bill}>
                                <Form.Item label={_("Select AD Network")} name="ad_network" rules={[{required: true}]}>
                                    <ADNetworkSelect get_all="true" onChange={this.handleSelectAdNetwork} />
                                </Form.Item>
                                <Form.Item
                                    label={_("Select Product")}>
                                    <AppSelect
                                        user_id={this.state.autoComplete.user_id}
                                        value={this.state.autoComplete.app_id}
                                        onChange={this.handleSelectApplication} />
                                </Form.Item>
                                <Form.Item label={_("Placement Category")} name="ad_type" rules={[{required: true}]} >
                                    <PlacementCategorySelect
                                        onSelect={this.handlePlacementCategory}/>
                                </Form.Item>
                                <Form.Item
                                    label={_("AD Network APP ID")}
                                    name="ad_app_id"
                                    rules={[
                                        {required: (!!this.state.bill.ad_template && !!this.state.bill.ad_template.required && !!this.state.bill.ad_template.required.filter(e=>(e==='app_id'||e==='appid'||e==='publish_id'))[0])}
                                    ]}>
                                    <AutoComplete
                                        placeholder={_("AD Network APP ID placeholder")}
                                        options={this.state.autoComplete.adAppIdOptions}
                                        onChange={(v) => {this.setState({bill: {...this.state.bill, ad_app_id: v}})}} />
                                </Form.Item>
                                <Form.Item label={_("AD Network Placement")} name="ad_placement" rules={[{required: true}]}>
                                    <AutoComplete
                                        options={this.state.autoComplete.adPlacementIdOptions} />
                                </Form.Item>
                                <Form.Item label={_("AD Network APP Name")} name="ad_app_name">
                                    <Input placeholder={_("AD Network APP Name placeholder")} />
                                </Form.Item>
                                <Form.Item label={_("AD Network AD Category")} name="original_type" >
                                    <Input placeholder={_("AD Network AD Category placeholder")} />
                                </Form.Item>
                                <Form.Item label={_("Country")} name="country">
                                    <Input placeholder={_("Country placeholder")} />
                                </Form.Item>
                                <Form.Item label={_("Timezone")} name="timezone">
                                    <Input />
                                </Form.Item>
                                <Form.Item label={_("Date")} name="date">
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item label={_("Requests")} name="requests">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label={_("Available")} name="available">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label={_("Show")} name="impressions">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label={_("Complete")} name="completes">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label={_("Click")} name="clicks">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label={_("Installs")} name="installs">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label={_("Revenue")} name="revenue">
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label={_("Currency")} name="currency">
                                    <CurrencySelect />
                                </Form.Item>
                                <Form.Item label={_("eCPM")} name="ecpm">
                                    <InputNumber min={0} />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}