import React from 'react';
import { Row, Modal, Button, Tabs, Table, Tag, Form, Input, InputNumber, DatePicker, Switch, Avatar, message } from 'antd';
import { PlusOutlined, EditOutlined, CloseOutlined, SaveOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { AppSelect } from './AppSelect';
import { PlacementSelect } from './PlacementSelect';
import { ADNetworkSelect } from './ADNetworkSelect';
import { ECPMModelSelect } from './ECPMModelSelect';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

export class IncomeDivide extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            user_id: state.getState().userId,
            product_uid: "",
            income_divide: {
                id: null,
                product_uid: "",
                placement_uid: "",
                ad_network: "",
                request_divide: 0.0,
                request_volatility: 0.0,
                fill_divide: 0.0,
                fill_volatility: 0.0,
                show_divide: 0.0,
                show_volatility: 0.0,
                play_divide: 0.0,
                play_volatility: 0.0,
                complete_divide: 0.0,
                complete_volatility: 0.0,
                click_divide: 0.0,
                click_volatility: 0.0,
                revenue_divide: 0.0,
                revenue_volatility: 0.0,
                ecpm_price: 0.0,
                ecpm_model: "default",
                status: true,
                description: null,
                start: null,
                end: null,
                date: [moment(), moment().add(7, 'days')]
            },
            income_divide_list: []
        };
        this.unsubscribe = null;
    };

    fetch = (puid) => {
        var url = "/income_divide";
        if (!!puid) {
            url += "/"+puid;
        }
        this.setState({loading: true});
        GetRequest(url)
            .then(resp => {
                this.setState({
                    loading: false,
                    income_divide_list: resp.data
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    income_divide_list: []
                });
            })
    }

    componentDidMount(){
        this.fetch();
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.setState({
                    user_id: state.getState().userId,
                    product_uid: "",
                    income_divide_list: []
                });
                this.fetch("");
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleSelectProduct = (puid) => {
        this.fetch(puid);
        setTimeout(()=>{
            this.setState({product_uid: puid});
        },0);
    }

    handleCreateNewIncomeDivide = () => {
        var entry = {
            ...this.state.income_divide,
            product_uid: this.state.product_uid,
            placement_uid: "",
            id: null
        };
        this.handleEdit(entry);
    }

    handleEdit = (entry) => {
        if (!!entry.start_date && !!entry.end_date) {
            entry['date'] = [
                moment(entry.start_date, "YYYY-MM-DD"), 
                moment(entry.end_date, "YYYY-MM-DD")];
        }
        this.setState({
            modalVisible: true,
            income_divide: entry
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0)
        }
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        values['start_str'] = values.date[0].format("YYYY-MM-DD");
        values['end_str'] = values.date[1].format("YYYY-MM-DD");
        if (!!this.state.income_divide.id) {
            values['id'] = this.state.income_divide.id;
        }
        this.setState({saveLoading: true});
        PostRequest('/income_divide', values)
            .then(resp => {
                var income_divide_list = [];
                if (!!this.state.income_divide_list.filter((entry)=>entry.id===resp.data.id)[0]) {
                    income_divide_list = this.state.income_divide_list.map(entry => {
                        if (entry.id===resp.data.id) {
                            return resp.data;
                        } else {
                            return entry;
                        }
                    });
                } else {
                    income_divide_list = [resp.data, ...this.state.income_divide_list];
                }
                this.setState({
                    saveLoading: false,
                    income_divide: resp.data,
                    income_divide_list: income_divide_list
                });
                message.success(_("Save successful"));
            })
            .catch(errors => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            })
    }

    render() {
        const { TabPane } = Tabs;
        const { RangePicker } = DatePicker;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const divideVolatilityInputLayout = {
            style: { display: 'inline-block', width: 'calc(50% - 8px)' }
        };
        const columns = [
            {
                title: _("Product Name"),
                render: (txt, entry) => {
                    if (!!entry && !!entry.product) {
                        return (<div>
                            <Avatar style={{marginRight:'8px'}} shape="square" size={32} src={entry.product.icon} alt={entry.product.name} />{entry.product.name}<br/>{entry.product_uid}
                        </div>);
                    } else {
                        return (<div>
                            <Avatar style={{marginRight:'8px'}} shape="square" size={32} icon={<UnorderedListOutlined />} />{_("All")}
                        </div>);
                    }
                }
            },
            {
                title: _("Placement Name"),
                render: (txt, entry) => {
                    if (!!entry && !!entry.placement) {
                        return (<div>
                            {entry.placement.name}<br/>{entry.placement_uid}
                        </div>);
                    } else {
                        return _("All");
                    }
                }
            },
            {
                title: _("AD Network"),
                render: (txt, entry) => {
                    if (!!entry && !!entry.ad_network) {
                        return entry.ad_network;
                    } else {
                        return _("All");
                    }
                }
            },
            {
                title: _("Start Date"),
                dataIndex: "start_date"
            },
            {
                title: _("End Date"),
                dataIndex: "end_date"
            },
            {
                title: _("Enable or not"),
                render: (txt, entry) => {
                    if (!!entry && !!entry.status) {
                        return (<Tag color="green">{_("Enable")}</Tag>);
                    } else {
                        return (<Tag color="red">{_("Disable")}</Tag>);
                    }
                }
            },
            {
                title: _("Show Divide"),
                render: (txt, entry) => {
                    return entry.show_divide + "% +/-" + entry.show_volatility;
                }
            },
            {
                title: _("Revenue Divide"),
                render: (txt, entry) => {
                    return entry.revenue_divide + "% +/-" + entry.revenue_volatility;
                }
            },
            {
                title: _("eCPM Price"),
                dataIndex: "ecpm_price"
            },
            {
                title: _("eCPM Model"),
                render: (txt, entry) => {
                    if (entry.ecpm_model==='default') {
                        return _("eCPM Model Default");
                    } else if (entry.ecpm_model==='revenue') {
                        return _("eCPM Model Revenue");
                    } else if (entry.ecpm_model==='impression') {
                        return _("eCPM Model Impression");
                    } else {
                        return entry.ecpm_model;
                    }
                }
            },
            {
                render: (text, obj) => {
                    return (<Button icon={<EditOutlined />} onClick={(evt)=>{this.handleEdit(obj);}}>{_("Edit")}</Button>);
                }
            }
        ];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage Income Divide")} key="manage_income_divide">
                        <Row>
                            <AppSelect 
                                size="large" 
                                style={{width: 400, marginBottom: 16, marginRight: 4}} 
                                user_id={this.state.user_id}
                                value={this.state.product_uid}
                                showall="true"
                                onChange={this.handleSelectProduct} />
                            <Button 
                                icon={<PlusOutlined />} 
                                size="large" 
                                type="primary"
                                onClick={this.handleCreateNewIncomeDivide}
                            >{_("Create New Income Divide")}</Button>
                        </Row>
                        <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={this.state.income_divide_list}
                            loading={this.state.loading}
                            pagination={false}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={_("Manage Income Divide")}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state.income_divide}>
                                <Form.Item label={_("Select Product")} name="product_uid">
                                    <AppSelect
                                        showall="true"
                                        disabled={!!this.state.income_divide.id}
                                        user_id={this.state.user_id}
                                        value={this.state.product_uid}
                                        onChange={(puid)=>{
                                            this.setState({
                                                income_divide: {
                                                    ...this.state.income_divide,
                                                    product_uid: puid,
                                                    placement_uid: ""
                                                }
                                            });
                                            if (!!this.formRef.current) {
                                                setTimeout(()=>{
                                                    this.formRef.current.resetFields();
                                                }, 0);
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label={_("Select Placement")} name="placement_uid">
                                    <PlacementSelect 
                                        disabled={!!this.state.income_divide.id}
                                        product_uid={this.state.income_divide.product_uid} 
                                        showall="true" />
                                </Form.Item>
                                <Form.Item label={_("Select AD Network")} name="ad_network">
                                    <ADNetworkSelect showall="true" />
                                </Form.Item>
                                <Form.Item label={_("Request Divide")}  style={{ marginBottom: 0 }}>
                                    <Form.Item name="request_divide" {...divideVolatilityInputLayout}>
                                        <Input addonAfter="%" />
                                    </Form.Item>
                                    <Form.Item name="request_volatility" {...divideVolatilityInputLayout}>
                                        <Input addonBefore={_("Volatility")}  />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Fill Divide")}  style={{ marginBottom: 0 }}>
                                    <Form.Item name="fill_divide" {...divideVolatilityInputLayout}>
                                        <Input addonAfter="%" />
                                    </Form.Item>
                                    <Form.Item name="fill_volatility" {...divideVolatilityInputLayout}>
                                        <Input addonBefore={_("Volatility")}  />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Show Divide")}  style={{ marginBottom: 0 }}>
                                    <Form.Item name="show_divide" {...divideVolatilityInputLayout}>
                                        <Input addonAfter="%" />
                                    </Form.Item>
                                    <Form.Item name="show_volatility" {...divideVolatilityInputLayout}>
                                        <Input addonBefore={_("Volatility")}  />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Play Divide")}  style={{ marginBottom: 0 }}>
                                    <Form.Item name="play_divide" {...divideVolatilityInputLayout}>
                                        <Input addonAfter="%"/>
                                    </Form.Item>
                                    <Form.Item name="play_volatility" {...divideVolatilityInputLayout}>
                                        <Input addonBefore={_("Volatility")} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Complete Divide")}  style={{ marginBottom: 0 }}>
                                    <Form.Item name="complete_divide" {...divideVolatilityInputLayout}>
                                        <Input addonAfter="%" />
                                    </Form.Item>
                                    <Form.Item name="complete_volatility" {...divideVolatilityInputLayout}>
                                        <Input addonBefore={_("Volatility")} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Click Divide")}  style={{ marginBottom: 0 }}>
                                    <Form.Item name="click_divide" {...divideVolatilityInputLayout}>
                                        <Input addonAfter="%" />
                                    </Form.Item>
                                    <Form.Item name="click_volatility" {...divideVolatilityInputLayout}>
                                        <Input addonBefore={_("Volatility")} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("Revenue Divide")}  style={{ marginBottom: 0 }} >
                                    <Form.Item name="revenue_divide" {...divideVolatilityInputLayout}>
                                        <Input addonAfter="%" />
                                    </Form.Item>
                                    <Form.Item name="revenue_volatility" {...divideVolatilityInputLayout}>
                                        <Input addonBefore={_("Volatility")} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={_("eCPM Price")} name="ecpm_price">
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item label={_("eCPM Model")} name="ecpm_model">
                                    <ECPMModelSelect />
                                </Form.Item>
                                <Form.Item label={_("Date")} name="date"
                                    rules={[
                                        {type: 'array', required: true, message: 'Please select time!'}
                                    ]}
                                >
                                    <RangePicker />
                                </Form.Item>
                                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                <Form.Item label={_("Description")} name="description"
                                    rules={[
                                        {required: true, message: _("Required and not empty")}
                                    ]}
                                >
                                <Input.TextArea rows={6} />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}