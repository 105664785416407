import React from 'react';
import { Table, Avatar, Tag, Button, Typography, Popconfirm } from 'antd';
import { EditOutlined, RocketOutlined } from '@ant-design/icons';
import { GetRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';

const state = getState();

class AppList extends React.Component {
    constructor(props) {
        super(props);
        this.unsubscribe = null;
        this.data = [];
        this.state = {
            data: [],
            pagination: {
                defaultPageSize: 10
            },
            loading: false
        };
    };

    componentDidMount(){
        this.fetch();
        this.unsubscribe = state.subscribe(() => {
            if (!!state.getState().token) {
                this.fetch();
            }
        });
    };

    componentWillUnmount() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    };

    fetch = () => {
        this.setState({loading: true});
        GetRequest('/application')
            .then(resp => {
                const pagination = { ...this.state.pagination };
                pagination.total = resp.data.length;
                if (!!resp.data) {
                    for (var idx in resp.data) {
                        if (!resp.data[idx]['key']) {
                            resp.data[idx]['key'] = idx;
                        }
                    }
                    this.data = resp.data;
                }
                var data = [];
                var current = pagination.current||1;
                var size = pagination.defaultPageSize;
                for (var i = 0; i < size; i++) {
                    var j = (current-1)*size + i;
                    if (!!this.data && !!this.data[j]) {
                        data.push(this.data[j]);
                    }
                }
                this.setState({
                    loading: false,
                    data: data,
                    pagination
                });
            });
    };

    updateProduct(product){
        var products = this.state.data;
        if (!!product && !!products) {
            for (var i in products) {
                if (products[i]['uid']===product.uid) {
                    for (var key in product) {
                        products[i][key] = product[key];
                    }
                    this.setState({data: products});
                    break;
                }
            }
        }
    };

    refresh = () => {
        const pagination = { ...this.state.pagination };
        this.fetch({page: pagination.current});
    };

    handleTableChange = (pagination, filter, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        var data = [];
        for (var i = 0; i < pagination.pageSize; i++) {
            var j = (pagination.current-1)*pagination.pageSize + i;
            if (!!this.data && !!this.data[j]) {
                data.push(this.data[j]);
            }
        }
        this.setState({
            pagination: pager,
            data: data
        });
    };

    handleProductEdit = (evt, product) => {
        if (!!this.props.onEdit) {
            this.props.onEdit(product);
        }
    };

    handleProductReview = (entry) => {
        if (!!this.props.onReview) {
            this.props.onReview(entry);
        }
    }

    render() {
        var self = this;
        const { Paragraph } = Typography;
        const columns = [
            {
                key: 'icon',
                render: (text, product) => (
                    <Avatar shape="square" size={64} src={product.icon} alt={product.name} />
                )
            },
            {
                key: 'uid',
                title: _("Product ID"),
                render: (text, product) => (<div>
                    <Paragraph copyable>{product.uid}</Paragraph>
                    <Paragraph>{product.bundle}</Paragraph>
                </div>)
            },
            {
                key: 'name',
                title: _("Product Name"),
                render: (text, product) => (
                    <a target="_blank" rel="noopener noreferrer" href={product.url}>{product.name}</a>
                )
            },
            {
                key: 'platform',
                title: _("Platform"),
                render: (text, product) => {
                    if (product.platform==="appstore") {
                        return _("AppStore");
                    } else if (product.platform==="googleplay") {
                        return _("GooglePlay");
                    } else if (product.platform==="chinese_android") {
                        return _("ChineseAndroid");
                    } else {
                        return product.platform;
                    }
                }
            },
            {
                key: 'origentation',
                title: _("Screen Orientation"),
                render: (text, product) => {
                    if (product.orientation==="horizontal") {
                        return _("horizontal");
                    } else if (product.orientation==="vertical") {
                        return _("vertical");
                    } else {
                        return product.orientation;
                    }
                }
            },
            {
                key: 'status',
                title: _("Product Status"),
                render: (text, product) => {
                    if (product.status===1) {
                        return (<Tag>{_("Unreview")}</Tag>);
                    } else if (product.status===2) {
                        return (<Tag color="green">{_("Running")}</Tag>);
                    } else if (product.status===0) {
                        return (<Tag color="red">{_("Disable")}</Tag>);
                    } else if (product.status===3) {
                        return (<Tag color="warning">{_("Reject")}</Tag>)
                    } else if (product.status===4) {
                        return (<Tag color="blue">{_("Reviewing")}</Tag>)
                    } else {
                        return (<Tag>{product.status}</Tag>);
                    }
                }
            },
            {
                render: (text, product) => (
                    <Button icon={<EditOutlined />} onClick={(evt)=>{self.handleProductEdit(evt, product);}}>{_("Edit")}</Button>
                )
            }
        ];
        if (this.props.showreview==='true') {
            columns.push({
                render: (txt, entry) => {
                    if (entry.status===1 || entry.status===3) {
                        return (<Popconfirm
                            placement="topRight"
                            title={_("Product review confirm")}
                            onConfirm={() => {this.handleProductReview(entry);}}
                            okText="Yes"
                            cancelText="No">
                                <Button type="primary" icon={<RocketOutlined />} >{_("Review Product")}</Button>
                        </Popconfirm>);
                    }
                    return <span/>;
                }
            });
        }
        return (
            <Table
                columns={columns}
                dataSource={this.state.data}
                pagination={this.state.pagination}
                loading={this.state.loading}
                onChange={this.handleTableChange}
             />
        );
    };
}

export { AppList }
