import React from 'react';
import { Card, Table, Collapse, Alert, Tabs, Typography, Button, Row, Modal } from 'antd';
import { AppleOutlined, AndroidOutlined, DownloadOutlined } from '@ant-design/icons';
import { trans as _ } from './i18n';
import ad_work_flow from './ad_work_flow.svg'

export class Document extends React.Component {

    handleDownloadButton = () => {
        const { Text, Link } = Typography;
        Modal.info({
            title: _("Download SDK Tips Title"),
            content: (<div>
                <Text>{_("Download SDK Tips Content")}</Text>
                <br/>
                <Link href="mailto:developer@yuyoumob.com"  target="_blank">{_("Download SDK Tips Email")}</Link>
            </div>)
        });
    }

    render(){
        const { TabPane } = Tabs;
        const { Title, Text, Paragraph } = Typography;
        const { Panel } = Collapse;
        const t1 = 3;
        const t2 = 4;
        const skAdNetworks = [
            {
                name: "AdMob",
                sk: ['cstr6suwn9.skadnetwork', '4fzdc2evr5.skadnetwork', '2fnua5tdw4.skadnetwork',
                'ydx93a7ass.skadnetwork', '5a6flpkh64.skadnetwork', 'p78axxw29g.skadnetwork',
                'v72qych5uu.skadnetwork', 'c6k4g5qg8m.skadnetwork', 's39g8k73mm.skadnetwork',
                '3qy4746246.skadnetwork', '3sh42y64q3.skadnetwork', 'f38h382jlk.skadnetwork',
                'hs6bdukanm.skadnetwork', 'prcb7njmu6.skadnetwork', 'v4nxqhlyqp.skadnetwork',
                'wzmmz9fp6w.skadnetwork', 'yclnxrl5pm.skadnetwork', 't38b2kh725.skadnetwork',
                '7ug5zh24hu.skadnetwork', '9rd848q2bz.skadnetwork', 'n6fk4nfna4.skadnetwork',
                'kbd757ywx3.skadnetwork', '9t245vhmpl.skadnetwork', '4468km3ulz.skadnetwork',
                '2u9pt9hc89.skadnetwork', '8s468mfl3y.skadnetwork', 'av6w8kgt66.skadnetwork',
                'klf5c3l5u5.skadnetwork', 'ppxm28t8ap.skadnetwork', '424m5254lk.skadnetwork',
                'uw77j35x4d.skadnetwork', '578prtvx9j.skadnetwork', '4dzt52r2t5.skadnetwork',
                'e5fvkxwrpn.skadnetwork', '8c4e2ghe7u.skadnetwork', 'zq492l623r.skadnetwork',
                '3qcr597p9d.skadnetwork']
            },{
                name: "Chartboost",
                sk: "f38h382jlk.skadnetwork",
            },{
                name: "Mintegral",
                sk: "kbd757ywx3.skadnetwork",
            },{
                name: "SigMob",
                sk: ["58922nb4gd.skadnetwork", "f7s53z58qe.skadnetwork", "27a282f54n.skadnetwork"]
            },{
                name: _("GuangDianTong"),
                sk: " f7s53z58qe.skadnetwork"
            },{
                name: _("TouTiao"),
                sk: ["238da6jt44.skadnetwork", "22mmun2rn5.skadnetwork"]
            },{
                name: "UnityAds",
                sk: ["4dzt52r2t5.skadnetwork", "bvpn9ufa9b.skadnetwork"],
            },{
                name: "Vungle",
                sk: "gta9lk7p23.skadnetwork",
            },{
                name: _("KuaiShou"),
                sk: "r3y5dwb26t.skadnetwork",
            },{
                name: "IronSource",
                sk: "su67r6k2v3.skadnetwork",
            }
        ];
        const columns = [{
            title: _("AD Name"),
            dataIndex: "name",
        },{
            title: "SKAdNetworkIdentifier",
            key: "sk",
            render: (txt, entry, idx) => {
                if (Array.isArray(entry.sk)) {
                    return entry.sk.map(sk => (<p key={sk}>{sk}</p>));
                } else {
                    return (<p key={entry.sk}>{entry.sk}</p>);
                }
            }
        }];
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={<span><AppleOutlined />{_("iOS Document")}</span>} key="ios_sdk_document">
                        <Button style={{marginBottom:12}} icon={<DownloadOutlined/>} type="primary" onClick={this.handleDownloadButton} size="large">{_("Download SDK")}</Button>
                        <Title level={t1}>{_("Import SDK")}</Title>
                        <Paragraph>{_("Import iOS SDK descript text")}</Paragraph>
                        <Title level={t2}>{_("Add Implementations")}</Title>
                        <Collapse style={{marginBottom:12}} >
                            <Panel header={_("Add iOS implementations descript text")} key="1">
                                <p>libc++abi.tbd</p>
                                <p>libc++.tbd</p>
                                <p>libbz2.tbd</p>
                                <p>libiconv.tbd</p>
                                <p>libresolv.9.tbd</p>
                                <p>libsqlite3.tbd</p>
                                <p>libxml2.tbd</p>
                                <p>libz.tbd</p>
                                <p>Accelerate.framework</p>
                                <p>AdSupport.framework</p>
                                <p>AppTrackingTransparency.framework</p>
                                <p>AudioToolbox.framework</p>
                                <p>AVFoundation.framework</p>
                                <p>CFNetwork.framework</p>
                                <p>CoreFoundation.framework</p>
                                <p>CoreGraphics.framework</p>
                                <p>CoreLocation.framework</p>
                                <p>CoreTelephony.framework</p>
                                <p>CoreMedia.framework</p>
                                <p>CoreMotion.framework</p>
                                <p>CoreVideo.framework</p>
                                <p>DeviceCheck.framework</p>
                                <p>EventKit.framework</p>
                                <p>Foundation.framework</p>
                                <p>GLKit.framework</p>
                                <p>iAd.framework</p>
                                <p>ImageIO.framework</p>
                                <p>MediaPlayer.framework</p>
                                <p>MessageUI.framework</p>
                                <p>MobileCoreServices.framework</p>
                                <p>QuartzCore.framework</p>
                                <p>SafariServices.framework</p>
                                <p>Security.framework</p>
                                <p>Social.framework</p>
                                <p>StoreKit.framework</p>
                                <p>SystemConfiguration.framework</p>
                                <p>VideoToolbox.framework</p>
                                <p>WatchConnectivity.framework</p>
                                <p>WebKit.framework</p>
                                <p>JavaScriptCore.framework</p>
                                <p>UIKit.framework</p>
                            </Panel>
                        </Collapse>
                        <Title level={t2}>{_("Add Other Linker Flags")}</Title>
                        <Paragraph>{_("Add iOS linker flags descript text")}</Paragraph>
                        <Alert style={{marginTop:10, marginBottom:10}} message={_("iOS linker flags alert text")} type="warning" showIcon />
                        <Title level={t2}>{_("Support HTTP")}</Title>
                        <Paragraph>{_("Support iOS HTTP descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Support iOS HTTP descript text 2")}
                            description={<div>
                                <p>&lt;key&gt;NSAppTransportSecurity&lt;/key&gt;</p>
                                <p>&lt;dict&gt;</p>
                                <p style={{paddingLeft:16}}>&lt;key&gt;NSAllowsArbitraryLoads&lt;/key&gt;</p>
                                <p style={{paddingLeft:16}}>&lt;true/&gt;</p>
                                <p>&lt;dict&gt;</p>
                            </div>}
                            type="success" showIcon />
                        <Title level={t1}>{_("Support iOS 14")}</Title>
                        <Paragraph>{_("Support iOS 14 descript text")}</Paragraph>
                        <Title level={t2}>{_("Add AppTrackingTransparency")}</Title>
                        <Paragraph>{_("Add AppTrackingTransparency descript text")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text>Privacy - Tracking Usage Description</Text></li>
                            </ul>
                        </Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Add AppTrackingTransparency code message")}
                            description={<div>
                                <p>&lt;key&gt;NSUserTrackingUsageDescription&lt;/key&gt;</p>
                                <p>&lt;string&gt;权限仅限于用了向您投放个性化广告&lt;string&gt;</p>
                                </div>}
                            type="success" showIcon />
                        <Title level={t2}>{_("Add SKAdNetworks")}</Title>
                        <Paragraph>{_("Add SKAdNetworks descript text 1")}</Paragraph>
                        <Paragraph>{_("Add SKAdNetworks descript text 2")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text><Text code>SKAdNetworkItems</Text> 类型选择： <Text code>Array</Text></Text></li>
                            </ul>
                        </Paragraph>
                        <Paragraph>{_("Add SKAdNetworks descript text 3")}</Paragraph>
                        <Paragraph>
                            <Collapse style={{marginBottom:12}}>
                                <Panel header={_("Add SKAdNetworks descript text 4")} key="2">
                                    <Table rowKey="sk" dataSource={skAdNetworks} columns={columns} pagination={false} />
                                    <Alert
                                        style={{marginTop:10, marginBottom:10}}
                                        message={_("Add SKAdNetworks code message")}
                                        description={<div>
                                            <p>&lt;key&gt;SKAdNetworkItems&lt;/key&gt;</p>
                                            <p>&lt;array&gt;</p>
                                            {skAdNetworks.map(item => <div>
                                                <p style={{paddingLeft:16}}>&lt;!-- {item.name} --&gt;</p>
                                                {Array.isArray(item.sk) ? item.sk.map(sk => <div>
                                                <p style={{paddingLeft:16}}>&lt;dict&gt;</p>
                                                <p style={{paddingLeft:32}}>&lt;key&gt;SKAdNetworkIdentifier&lt;/key&gt;</p>
                                                <p style={{paddingLeft:32}}>&lt;string&gt;{sk}&lt;/string&gt;</p>
                                                <p style={{paddingLeft:16}}>&lt;/dict&gt;</p>
                                                </div>) : <div>
                                                <p style={{paddingLeft:16}}>&lt;dict&gt;</p>
                                                <p style={{paddingLeft:32}}>&lt;key&gt;SKAdNetworkIdentifier&lt;/key&gt;</p>
                                                <p style={{paddingLeft:32}}>&lt;string&gt;{item.sk}&lt;/string&gt;</p>
                                                <p style={{paddingLeft:16}}>&lt;/dict&gt;</p>
                                                </div>}
                                            </div>)}
                                            <p>&lt;/array&gt;</p>
                                        </div>}
                                        type="success" showIcon />
                                </Panel>
                            </Collapse>
                        </Paragraph>
                        <Title level={t1}>{_("AD Work Flow")}</Title>
                        <Paragraph>{_("AD Work Flow descript text")}</Paragraph>
                        <Paragraph>
                            <Card
                                hoverable
                                style={{width: 240, height: 480}}
                                cover={<img alt="ad_work_flow" src={ad_work_flow} />}>

                            </Card>
                        </Paragraph>
                        <Title level={t1}>{_("Initialize SDK")}</Title>
                        <Paragraph>{_("Initialize iOS SDK descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Initialize iOS SDK code message")}
                            description={<div>
                                <br/>
                                <p>{"#import <ADMediationSDK/ADMediationSDK.h>"}</p>
                                <br/>
                                <p>{"@implementation ViewController"}</p>
                                <br/>
                                <p>{"- (void)viewDidLoad {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"[super viewDidLoad];"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"[[ADMediationSDK sharedInstance] requirePermissions:^(NSInteger s) {"}</p>
                                <p style={{paddingLeft:64}}>{"[[ADMediationSDK sharedInstance] initSDKWithAppID:@\"Your Application ID\""}</p>
                                <p style={{paddingLeft:324}}>{"AndSecretKey:@\"Your Secret Key\""}</p>
                                <p style={{paddingLeft:338}}>{"AndIsDebug:YES];"}</p>
                                <p style={{paddingLeft:16}}>{"}];"}</p>
                                <p>{"}"}</p>
                                <br/>
                                <p>{"@end"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>AppID: </Text>{_("SDK Parameter App ID")}</li>
                                <li><Text code>SecretKey: </Text>{_("SDK Parameter Secret Key")}</li>
                                <li><Text code>IsDebug: </Text>{_("SDK Parameter Is Debug")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Rewarded Video AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"#import <ADMediationSDK/ADMediationSDK.h>"}</p>
                                <br/>
                                <p>{"ADMRewardedVideoAd* rewardedVideoAd = [[ADMediationSDK sharedInstance]"}</p>
                                <p style={{paddingLeft:64}}>{"makeRewardedVideoAd:@\"Your Rewarded Video AD Placement ID\"];"}</p>
                                <br/>
                                <p>{"[rewardedVideoAd setVideoAdDelegate: self];"}</p>
                                <br/>
                                <p>{"[rewardedVideoAd setRewardedVideoAdDelegate: self];"}</p>
                                <br/>
                                <p>{"[rewardedVideoAd load:self];"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"#import <ADMediationSDK/ADMediationSDK.h>"}</p>
                                <br/>
                                <p>{"if ([rewardedVideoAd ready]) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"[rewardedVideoAd show:self];"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Interstitial AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"#import <ADMediationSDK/ADMediationSDK.h>"}</p>
                                <br/>
                                <p>{"ADMRewardedVideoAd* interstitialAd = [[ADMediationSDK sharedInstance]"}</p>
                                <p style={{paddingLeft:64}}>{"makeInterstitialAd:@\"Your Interstitial AD Placement ID\"];"}</p>
                                <br/>
                                <p>{"[interstitialAd load:self];"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"#import <ADMediationSDK/ADMediationSDK.h>"}</p>
                                <br/>
                                <p>{"if ([interstitialAd ready]) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"[interstitialAd show:self];"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Interstitial Video AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"#import <ADMediationSDK/ADMediationSDK.h>"}</p>
                                <br/>
                                <p>{"ADMRewardedVideoAd* interstitialVideoAd = [[ADMediationSDK sharedInstance]"}</p>
                                <p style={{paddingLeft:64}}>{"makeInterstitialVideoAd:@\"Your Interstitial Video AD Placement ID\"];"}</p>
                                <br/>
                                <p>{"[interstitialVideoAd setVideoAdDelegate: self];"}</p>
                                <br/>
                                <p>{"[interstitialVideoAd load:self];"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"#import <ADMediationSDK/ADMediationSDK.h>"}</p>
                                <br/>
                                <p>{"if ([interstitialVideoAd ready]) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"[interstitialVideoAd show:self];"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Add AD Delegate")}</Title>
                        <Paragraph>{_("Add iOS AD Delegate descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"@protocol ADMediationSDKADDelegate <NSObject>"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdLoaded")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAdLoaded:(NSString*)placementId;"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdLoadFail")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAd:(NSString*)placementId LoadFail:(NSError*) error;"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdShow")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAdShow:(NSString*)placementId;"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdShowFail")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAd:(NSString*)placementId ShowFail:(NSError*) error;"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdClick")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAdClick:(NSString*)placementId;"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdClose")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAdClose:(NSString*)placementId WithReward:(BOOL)reward;"}</p>
                            <br/>
                            <p>{"@end"}</p>
                            </div>}
                            type="success" showIcon />
                        <Title level={t1}>{_("Add Video AD Delegate")}</Title>
                        <Paragraph>{_("Add iOS Video AD Delegate descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"@protocol ADMediationSDKVideoADDelegate <NSObject>"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdVideoStartPlay")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAdVideoStartPlay:(NSString*)placementId;"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdVideoPlayComplete")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAdVideoPlayComplete:(NSString*)placementId;"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onAdVideoSkip")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onAdVideoSkip:(NSString*)placementId;"}</p>
                            <br/>
                            <p>{"@end"}</p>
                            </div>}
                            type="success" showIcon />
                        <Title level={t1}>{_("Add Reward AD Delegate")}</Title>
                        <Paragraph>{_("Add iOS Rewarded Video AD Delegate descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"@protocol ADMediationSDKRewardedVideoADDelegate <NSObject>"}</p>
                            <br/>
                            <p>{"/*"}</p>
                            <p>{" * "+_("onRewardedToUser")}</p>
                            <p>{"*/"}</p>
                            <p>{"-(void) onRewardedToUser:(NSString*)placementId;"}</p>
                            <br/>
                            <p>{"@end"}</p>
                            </div>}
                            type="success" showIcon />
                    </TabPane>
                    <TabPane tab={<span><AndroidOutlined />{_("Android Document")}</span>} key="android_sdk_document">
                        <Alert
                            style={{marginBottom:12}}
                            message={_("Android SDK only support chinese market Warning")}
                            type="warning"
                            closable
                            showIcon />
                        <Button style={{marginBottom:12}} icon={<DownloadOutlined/>} type="primary" onClick={this.handleDownloadButton} size="large">{_("Download SDK")}</Button>
                        <Title level={t1}>{_("Import SDK")}</Title>
                        <Paragraph>{_("Import Android SDK descript text")}</Paragraph>
                        <Title level={t2}>{_("Add Implementations")}</Title>
                        <Paragraph>{_("Add Android implementations descript text 1")}</Paragraph>
                        <Paragraph>{_("Add Android implementations descript text 2")}</Paragraph>
                        <Title level={t2}>{_("Add Permissions")}</Title>
                        <Paragraph>{_("Add Android permissions descript text")}</Paragraph>
                        <Title level={t2}>{_("Add Proguard")}</Title>
                        <Paragraph>{_("Add Android proguard descript text")}</Paragraph>
                        <Title level={t1}>{_("AD Work Flow")}</Title>
                        <Paragraph>{_("AD Work Flow descript text")}</Paragraph>
                        <Paragraph>
                            <Card
                                hoverable
                                style={{width: 240, height: 480}}
                                cover={<img alt="ad_work_flow" src={ad_work_flow} />}>

                            </Card>
                        </Paragraph>
                        <Title level={t1}>{_("Initialize SDK")}</Title>
                        <Paragraph>{_("Initialize Android SDK descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Initialize Android SDK code message")}
                            description={<div>
                                <br/>
                                <p>{"import com.yuyoumob.admediation.sdk.AdMediationSDK;"}</p>
                                <br/>
                                <p>{"public class MainActivity extends AppCompatActivity {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"@Override"}</p>
                                <p style={{paddingLeft:16}}>{"protected void onCreate(Bundle savedInstanceState) {"}</p>
                                <br/>
                                <p style={{paddingLeft:32}}>{"super.onCreate(savedInstanceState);"}</p>
                                <p style={{paddingLeft:32}}>{"setContentView(R.layout.activity_main);"}</p>
                                <br/>
                                <p style={{paddingLeft:32}}>{"AdMediationSDK.init("}</p>
                                <p style={{paddingLeft:64}}>{"this,"}</p>
                                <p style={{paddingLeft:64}}>{"\"AppID\","}</p>
                                <p style={{paddingLeft:64}}>{"\"SecretKey\","}</p>
                                <p style={{paddingLeft:64}}>{"IsDebug);"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"}"}</p>
                                <p>{"}"}</p>
                                <br/>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>AppID: </Text>{_("SDK Parameter App ID")}</li>
                                <li><Text code>SecretKey: </Text>{_("SDK Parameter Secret Key")}</li>
                                <li><Text code>IsDebug: </Text>{_("SDK Parameter Is Debug")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Rewarded Video AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"import com.yuyoumob.admediation.sdk.RewardedVideoAd;"}</p>
                                <p>{"import com.yuyoumob.admediation.sdk.AdListener;"}</p>
                                <p>{"import com.yuyoumob.admediation.sdk.RewardedVideoAdListener;"}</p>
                                <p>{"import com.yuyoumob.admediation.sdk.VideoAdListener;"}</p>
                                <br/>
                                <p>{"RewardedVideoAd rewardedVideoAd = AdMediationSDK.makeRewardedVideoAd(REWARDED_VIDEO_AD_PLACEMENT);"}</p>
                                <br/>
                                <p>{"rewardedVideoAd.setAdListener(adListener);"}</p>
                                <br/>
                                <p>{"rewardedVideoAd.setVideoAdListener(videoAdListener);"}</p>
                                <br/>
                                <p>{"rewardedVideoAd.setRewardedVideoAdListener(rewardedVideoAdListener);"}</p>
                                <br/>
                                <p>{"rewardedVideoAd.load(MainActivity.this);"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"if (null != rewardedVideoAd && rewardedVideoAd.ready(MainActivity.this)) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"rewardedVideoAd.show(MainActivity.this);"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Interstitial AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"import com.yuyoumob.admediation.sdk.InterstitialAd;"}</p>
                                <p>{"import com.yuyoumob.admediation.sdk.AdListener;"}</p>
                                <br/>
                                <p>{"InterstitialAd interstitialAd = AdMediationSDK.makeInterstitialAd(INTERSTITIAL_AD_PLACEMENT);"}</p>
                                <br/>
                                <p>{"interstitialAd.setAdListener(adListener);"}</p>
                                <br/>
                                <p>{"interstitialAd.load(MainActivity.this);"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"if (null != interstitialAd && interstitialAd.ready(MainActivity.this)) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"interstitialAd.show(MainActivity.this);"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Interstitial Video AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"import com.yuyoumob.admediation.sdk.InterstitialVideoAd;"}</p>
                                <p>{"import com.yuyoumob.admediation.sdk.AdListener;"}</p>
                                <p>{"import com.yuyoumob.admediation.sdk.VideoAdListener;"}</p>
                                <br/>
                                <p>{"InterstitialVideoAd interstitialVideoAd = AdMediationSDK.makeInterstitialVideoAd(INTERSTITIAL_VIDEO_AD_PLACEMENT);"}</p>
                                <br/>
                                <p>{"interstitialVideoAd.setAdListener(adListener);"}</p>
                                <br/>
                                <p>{"interstitialVideoAd.setVideoAdListener(videoAdListener);"}</p>
                                <br/>
                                <p>{"interstitialVideoAd.load(MainActivity.this);"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"if (null != interstitialVideoAd && interstitialVideoAd.ready(MainActivity.this)) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"interstitialVideoAd.show(MainActivity.this);"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Add AD Delegate")}</Title>
                        <Paragraph>{_("Add Android AD Delegate descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"package com.yuyoumob.admediation.sdk;"}</p>
                            <br/>
                            <p>{"public interface AdListener {"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdLoaded")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdLoaded(String placement);"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdLoadFail")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdLoadFail(String placement, String error);"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdShow")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdShow(String placement);"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdShowFail")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdShowFail(String placement, String error);"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdClick")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdClick(String placement);"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdClose")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdClose(String placement);"}</p>
                            <br/>
                            <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Title level={t1}>{_("Add Video AD Delegate")}</Title>
                        <Paragraph>{_("Add Android Video AD Delegate descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"package com.yuyoumob.admediation.sdk;"}</p>
                            <br/>
                            <p>{"public interface VideoAdListener {"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdVideoStartPlay")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdVideoStartPlay(String placement);"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdVideoPlayComplete")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdVideoPlayComplete(String placement);"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onAdVideoSkip")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onAdVideoSkip(String placement);"}</p>
                            <br/>
                            <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Title level={t1}>{_("Add Reward AD Delegate")}</Title>
                        <Paragraph>{_("Add Android Rewarded Video AD Delegate descript text")}</Paragraph>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"package com.yuyoumob.admediation.sdk;"}</p>
                            <br/>
                            <p>{"public interface RewardedVideoAdListener {"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"/*"}</p>
                            <p style={{paddingLeft:16}}>{" * "+_("onRewardedToUser")}</p>
                            <p style={{paddingLeft:16}}>{"*/"}</p>
                            <p style={{paddingLeft:16}}>{"void onRewardedToUser(String placement);"}</p>
                            <br/>
                            <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                    </TabPane>
                    <TabPane tab={<span>{_("Unity Document")}</span>} key="unity_sdk_document">
                        <Alert
                            style={{marginBottom:12}}
                            message={_("Android SDK only support chinese market Warning")}
                            type="warning"
                            closable
                            showIcon />
                        <Row>
                            <Button style={{marginBottom:12}} icon={<AppleOutlined/>} type="primary" onClick={this.handleDownloadButton} size="large">{_("Download SDK")}</Button>
                        </Row>
                        <Row>
                            <Button style={{marginBottom:12}} icon={<AndroidOutlined/>} type="primary" onClick={this.handleDownloadButton} size="large">{_("Download SDK")}</Button>
                        </Row>
                        <Title level={t1}>{_("Import SDK")}</Title>
                        <Paragraph>{_("Import Unity SDK descript text 1")}</Paragraph>
                        <Paragraph>{_("Import Unity SDK descript text 2")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>{"Assets/AdMediationSDK/Demo/Demo.unity"}</Text></li>
                            </ul>
                        </Paragraph>
                        <Paragraph>{_("Import Unity SDK descript text 3")}</Paragraph>
                        <Paragraph>{_("Import Unity SDK descript text 4")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>{"Assets/AdMediationSDK/Demo/Demo.cs"}</Text></li>
                            </ul>
                        </Paragraph>
                        <Paragraph>{_("Import Unity SDK descript text 5")}</Paragraph>
                        <Title level={t1}>{_("AD Work Flow")}</Title>
                        <Paragraph>{_("AD Work Flow descript text")}</Paragraph>
                        <Paragraph>
                            <Card
                                hoverable
                                style={{width: 240, height: 480}}
                                cover={<img alt="ad_work_flow" src={ad_work_flow} />}>

                            </Card>
                        </Paragraph>
                        <Title level={t1}>{_("Initialize SDK")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Initialize Android SDK code message")}
                            description={<div>
                                <br/>
                                <p>{"using AdMediation;"}</p>
                                <br/>
                                <p>{"public class Demo : MonoBehaviour {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"void Awak() {"}</p>
                                <br/>
                                <p style={{paddingLeft:32}}>{"AdMediationSDK.InitWithRequirePermissions(AppID, SecretKey, IsDebug);"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"}"}</p>
                                <p>{"}"}</p>
                                <br/>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>AppID: </Text>{_("SDK Parameter App ID")}</li>
                                <li><Text code>SecretKey: </Text>{_("SDK Parameter Secret Key")}</li>
                                <li><Text code>IsDebug: </Text>{_("SDK Parameter Is Debug")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Rewarded Video AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"using AdMediation;"}</p>
                                <br/>
                                <p>{"AdMediationSDK.LoadRewardedVideoAd(placementID);"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"using AdMediation;"}</p>
                                <br/>
                                <p>{"if (AdMediationSDK.RewardedVideoAdIsReady(placementID)) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"AdMediationSDK.ShowRewardedVideoAd(placementID);"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Interstitial AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"using AdMediation;"}</p>
                                <br/>
                                <p>{"AdMediationSDK.LoadInterstitialAd(placementID);"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"using AdMediation;"}</p>
                                <br/>
                                <p>{"if (AdMediationSDK.InterstitialAdIsReady(placementID)) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"AdMediationSDK.ShowInterstitialAd(placementID);"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Use Interstitial Video AD")}</Title>
                        <Title level={t2}>{_("Load AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Load AD code message")}
                            description={<div>
                                <br/>
                                <p>{"using AdMediation;"}</p>
                                <br/>
                                <p>{"AdMediationSDK.LoadInterstitialVideoAd(placementID);"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t2}>{_("Show AD")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("Show AD code message")}
                            description={<div>
                                <br/>
                                <p>{"using AdMediation;"}</p>
                                <br/>
                                <p>{"if (AdMediationSDK.InterstitialVideoAdIsReady(placementID)) {"}</p>
                                <br/>
                                <p style={{paddingLeft:16}}>{"AdMediationSDK.ShowInterstitialVideoAd(placementID);"}</p>
                                <br/>
                                <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Paragraph>{_("SDK Parameters")}</Paragraph>
                        <Paragraph>
                            <ul>
                                <li><Text code>Placement ID: </Text>{_("SDK Parameter AD Placement")}</li>
                            </ul>
                        </Paragraph>
                        <Title level={t1}>{_("Add AD Delegate")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"using AdMediation;"}</p>
                            <br/>
                            <p>{"public class Demo : MonoBehaviour {"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"void Awake() {"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdLoaded = (string placementId) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdLoaded")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdLoadFail = (string placementId, string err) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdLoadFail")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdShow = (string placementId) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdShow")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdShowFail = (string placementId, string err) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdShowFail")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdClick = (string placementId) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdClick")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdClose = (string placementId, bool reward) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdClose")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"}"}</p>
                            <br/>
                            <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Title level={t1}>{_("Add Video AD Delegate")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"using AdMediation;"}</p>
                            <br/>
                            <p>{"public class Demo : MonoBehaviour {"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"void Awake() {"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdVideoStartPlay = (string placementId) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdVideoStartPlay")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdVideoPlayComplete = (string placementId) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdVideoPlayComplete")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnAdVideoSkip = (string placementId) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onAdVideoSkip")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"}"}</p>
                            <br/>
                            <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                        <Title level={t1}>{_("Add Reward AD Delegate")}</Title>
                        <Alert
                            style={{marginTop:10, marginBottom:10}}
                            message={_("AD Delegate code message")}
                            description={<div><br/>
                            <p>{"using AdMediation;"}</p>
                            <br/>
                            <p>{"public class Demo : MonoBehaviour {"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"void Awake() {"}</p>
                            <br/>
                            <p style={{paddingLeft:32}}>{"AdMediationSDK.OnRewardedToUser = (string placementId) => {"}</p>
                            <p style={{paddingLeft:64}}>{"// "+_("onRewardedToUser")}</p>
                            <p style={{paddingLeft:32}}>{"};"}</p>
                            <br/>
                            <p style={{paddingLeft:16}}>{"}"}</p>
                            <br/>
                            <p>{"}"}</p>
                            </div>}
                            type="success" showIcon />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}