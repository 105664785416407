import React from 'react';
import { Select } from 'antd';
import { trans as _ } from './i18n';

const { Option } = Select;

class PlatformSelect extends React.Component {
    render() {
        return (
            <Select {...this.props} >
                <Option value="chinese_android">{_("ChineseAndroid")}</Option>
                <Option value="appstore">{_("AppStore")}</Option>
                <Option value="googleplay">{_("GooglePlay")}</Option>
            </Select>
        );
    };
};

export { PlatformSelect };
