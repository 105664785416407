import axios from 'axios';
import { getState } from './Reducer'; 

const state = getState();

function _reCAPTCHA(action) {
    return new Promise((resolve, reject) => {
        if (!!window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute("6LfJVuQZAAAAAMqjNIxA3LpdcOO6Ipu220exG_sE", {action: ''+action})
                .then(token => {
                    resolve(token); 
                })
                .catch(error => {
                    reject(error);
                });
            });
        } else {
            reject('reCAPTCHA not exist');
        }
    });
}

function _getRequest(path, config, options) {
    if (!options || !options.notRestAPI) {
        path = "/restapi"+path;
    }
    return axios.get(path, config).catch(error => {
        if (!!error && !!error.response && error.response.status===401) {
            state.dispatch({
                type: 'NEED_LOGIN'
            });
        }
        throw error;
    });
}

export function GetRequest(path, config, options) {
    if (!config) {
        config = {};
    }
    if (!config.headers) {
        config.headers = {}
    }
    var token = state.getState().token;
    if (!!token) {
        config.headers["Authorization"] = "Token "+token;
    }
    var xRealUser = state.getState().userId;
    if (!!xRealUser) {
        config.headers['X-REAL-USER'] = xRealUser;
    }
    if (!!options && !!options.reCAPTCHA) {
        return _reCAPTCHA(options.reCAPTCHA).then(token => {
            config.headers['X-RE-CAPTCHA'] = token;
            return _getRequest(path, config, options);
        });
    }
    return _getRequest(path, config, options);
};

function _postRequest(path, data, config, options) {
    if (!options || !options.notRestAPI) {
        path = "/restapi"+path;
    }
    return axios.post(path, data, config).catch(error => {
        if (!!error && !!error.response && error.response.status===401) {
            state.dispatch({
                type: 'NEED_LOGIN'
            });
        }
        throw error;
    });
}

export function PostRequest(path, data, config, options) {
    if (!config) {
        config = {};
    }
    if (!config.headers) {
        config.headers = {}
    }
    config.headers['Content-Type'] = 'application/json';
    var token = state.getState().token;
    if (!!token) {
        config.headers["Authorization"] = "Token "+token;
    }
    var xRealUser = state.getState().userId;
    if (!!xRealUser) {
        config.headers['X-REAL-USER'] = xRealUser;
    }
    if (!!options && !!options.reCAPTCHA) {
        return _reCAPTCHA(options.reCAPTCHA).then(token => {
            config.headers['X-RE-CAPTCHA'] = token;
            return _postRequest(path, data, config, options);

        });
    }
    return _postRequest(path, data, config, options);
};

function _downloadRequest(path, config, options) {
    if (!options || !options.notRestAPI) {
        path = "/restapi"+path;
    }
    return axios({
        method: 'GET',
        url: path,
        headers: config.headers || {},
        responseType: 'blob'
    }).then(resp => {
        if (!!resp.data) {
            var href = window.URL.createObjectURL(new Blob([resp.data]));
            var a = document.createElement('a');
            a.style.display = 'none';
            a.href = href;
            a.setAttribute('download', config.file_name || '');
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        }
    }).catch(error => {
        if (!!error && !!error.response && error.response.status===401) {
            state.dispatch({
                type: 'NEED_LOGIN'
            });
        }
        return error;
    });
}

export function DownloadRequest(path, config, options) {
    if (!config) {
        config = {};
    }
    if (!config.headers) {
        config.headers = {}
    }
    var token = state.getState().token;
    if (!!token) {
        config.headers["Authorization"] = "Token "+token;
    }
    var xRealUser = state.getState().userId;
    if (!!xRealUser) {
        config.headers['X-REAL-USER'] = xRealUser;
    }
    if (!!options && !!options.reCAPTCHA) {
        return _reCAPTCHA(options.reCAPTCHA).then(token => {
            config.headers['X-RE-CAPTCHA'] = token;
            return _downloadRequest(path, config, options);
        });
    }
    return _downloadRequest(path, config, options);
};
