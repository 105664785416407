import React from 'react';
import { Row, Tabs, Typography, Col, Card, Empty, Statistic, Alert } from 'antd';
import { Chart, Line, Tooltip } from 'bizcharts';
import { GetRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';

const state = getState();

export class Overview extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            data: {
                top_products: [],
                last_1days_revenue: 0.0,
                last_7days_revenue: 0.0,
                last_30days_revenue: 0.0,
                last_30days_bills: []
            }
        };
        this.unsubscribe = null;
    }

    componentDidMount = () => {
        this.unsubscribe = state.subscribe((evt) => {
            if (!!state.getState().token) {
                this.fetch();
            }
        });
        this.fetch();
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    fetch = () => {
        this.setState({loading: true});
        GetRequest('/overview')
            .then(resp => {
                this.setState({
                    loading: false,
                    data: resp.data
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    data: {
                        top_products: [],
                        last_1days_revenue: 0.0,
                        last_7days_revenue: 0.0,
                        last_30days_revenue: 0.0,
                        last_30days_bills: []
                    }
                });
            });
    }

    render() {
        const { TabPane } = Tabs;
        const { Meta } = Card;
        const { Title } = Typography;
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Overview")} key="overview">
                        <Alert 
                            style={{marginBottom:24}} 
                            message={_("Statistics Analysis Revenue Warning")}
                            type="warning"
                            closable
                            showIcon />
                        <Alert 
                            style={{marginBottom:24}} 
                            message={_("Statistics Analysis Currency Warning")}
                            type="warning"
                            closable
                            showIcon />
                        <Title level={3}>{_("Top 30 Days")}</Title>
                        <div className="site-card-wrapper">
                            <Row gutter={32}>
                                {this.state.data.top_products.map((entry, idx) => (
                                    <Col key={idx} span={4}>
                                        <Card hoverable loading={this.state.loading} cover={(!!entry.icon && <img alt={entry.name} src={entry.icon} />) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
                                            <Meta title={entry.name || _("Empty Product")} description={<Statistic title={_("Revenue")} value={entry.revenue} prefix={_("Currency Symbol")}  precision={2} />} />
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <Title level={3} style={{marginTop:32}}>{_("Estimaated Revenue")}</Title>
                        <div className="site-card-wrapper">
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Card loading={this.state.loading} title={_("Last Day Revenue")} bordered={true} >
                                        <Statistic title={_("Estimaated Revenue")} value={this.state.data.last_1days_revenue} prefix={_("Currency Symbol")} precision={2} />
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card loading={this.state.loading} title={_("7 Days Revenue")} bordered={true} >
                                        <Statistic title={_("Estimaated Revenue")} value={this.state.data.last_7days_revenue} prefix={_("Currency Symbol")} precision={2} />
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card loading={this.state.loading} title={_("30 Days Revenue")} bordered={true}>
                                        <Statistic title={_("Estimaated Revenue")} value={this.state.data.last_30days_revenue} prefix={_("Currency Symbol")} precision={2} />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <Title level={3} style={{marginTop:32}}>{_("30 Days charts")}</Title>
                        <Chart height={480} autoFit data={this.state.data.last_30days_bills} >
                            <Tooltip title={_("Revenue")} />
                            <Line position="day*revenue" tooltip={['day*revenue', (d, v) => ({name:d, value: new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(v)})]} />
                        </Chart>
                    </TabPane>
                </Tabs>
            </div>
        );
    };
}