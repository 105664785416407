import { createStore } from 'redux';

const defaultState = {
    token: localStorage.getItem("token"),
    userId: ""
};

const reducer = (state = defaultState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case 'USER_SWITCH':
            newState['userId'] = action.payload;
            return newState;
        case 'SET_TOKEN':
            newState['token'] = action.payload;
            newState['userId'] = "";
            return newState;
        case 'NEED_LOGIN':
            localStorage.removeItem('token');
            newState['token'] = null;
            newState['userId'] = "";
            return newState;
        default:
            return state;
    }
};

const state = createStore(reducer);

export function getState() {
    return state;
};
