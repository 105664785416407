import React from 'react';
import { GetRequest } from './Ajax';
import { Avatar, Menu, Dropdown, Badge } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { trans as _ } from './i18n';

class UserInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showName: "",
            user: {}
        };
    };

    fetch(){
        GetRequest('/user/me')
            .then(resp => {
                var user = resp.data;
                var name = user.username;
                if (user.first_name && user.last_name) {
                    name = user.first_name + " . "+user.last_name
                }
                this.setState({
                    user: user,
                    showName: name
                });
            });
    };

    componentDidMount(){
        this.fetch();
    };

    handleClick = (evt) => {
        if (this.props.onMenuItemClick) {
            this.props.onMenuItemClick(evt);
        }
    };

    render(){
        const menu = (
            <Menu>
              <Menu.Item key="0" onClick={()=>{this.handleClick("notification");}}>
                  {_("Notification")}<Badge count={this.props.badge_count} overflowCount={10}/>
                </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="2" onClick={()=>{this.handleClick("logout");}}>{_("Logout")}</Menu.Item>
            </Menu>
        );
        return (
            <div style={this.props.style}>
                <Avatar style={{marginRight: '12px'}} icon={ <UserOutlined/> } />
                <Badge count={this.props.badge_count} dot>
                <Dropdown overlay={menu} trigger={['click']}>
                    <a href="./" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    {this.state.showName} <DownOutlined />
                    </a>
                </Dropdown>
                </Badge>
            </div>
        );
    };
};

export { UserInfo };
