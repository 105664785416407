import React from 'react';
import { Select } from 'antd';
import { trans as _} from './i18n';

const { Option } = Select;

export class ECPMModelSelect extends React.Component {
    render(){
        return (
            <Select {...this.props}>
                <Option value="default">{_("eCPM Model Default")}</Option>
                <Option value="revenue">{_("eCPM Model Revenue")}</Option>
                <Option value="impression">{_("eCPM Model Impression")}</Option>
            </Select>
        );
    };
};
