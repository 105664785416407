import React from 'react';
import { Row, Col, Tabs, Table, Form, DatePicker, Divider, Alert, Button, Typography } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Chart, Line, Interval, Tooltip, Axis, Coordinate, Interaction } from 'bizcharts';
import { GetRequest, DownloadRequest } from './Ajax';
import { AppSelect } from './AppSelect';
import { PlacementSelect } from './PlacementSelect';
import { ADPlanSelect } from './ADPlanSelect';
import { ADPlanTargetSelect } from './ADPlanTargetSelect';
import { ADNetworkSelect } from './ADNetworkSelect';
import { getState } from './Reducer';
import { trans as _ } from './i18n';
import moment from 'moment';
import 'moment/locale/zh-cn';

const state = getState();

export class ADStatisticsAnalysis extends React.Component {

    queryRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            downloading: false,
            query: {
                user_id: state.getState().userId,
                app_id: "",
                placement_id: "",
                ad_plan_id: "",
                ad_plan_target: "",
                ad_network: "",
                date: [moment().subtract(8, 'days'), moment().subtract(1, 'days')]
            },
            plan_list: [],
            ad_list: [],
            bill_list: [],
            show_times_user: [],
            user_ad_view: [],
            userAdViewLineData: [],
            impressionLineData: [],
            impressionPieData: [],
            revenueLineData: [],
            showTimesUserPieData: [],
            pagination: {
                defaultPageSize: 31,
                showSizeChanger: true
            },
        };
        this.queryResult = {};
        this.unsubscribe = null;
    }

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(() => {
            if (!!state.getState().token) {
                this.setState({
                    query: {
                        ...this.state.query,
                        user_id: state.getState().userId,
                        app_id: "",
                        placement_id: "",
                        ad_plan_id: "",
                        ad_plan_target: "",
                    },
                    bill_list: []
                });
                if (!!this.queryRef.current) {
                    this.queryRef.current.resetFields();
                }
                this.fetch();
            }
        });
        this.fetch(this.state.query.date);
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleDateSelect = (d) => {
        this.setState({
            query: {
                ...this.state.query,
                date: d
            }
        });
        this.fetch();
    }

    handleApplicationSelect = (puid, entry) => {
        this.setState({
            query: {
                ...this.state.query,
                app_id: puid,
                placement_id: "",
                ad_plan_id: "",
                ad_plan_target: "",
            }
        });
        this.filter();
    }

    handleADPlacementSelect = (puid, entry) => {
        this.setState({
            query: {
                ...this.state.query,
                placement_id: puid,
                ad_plan_id: "",
                ad_plan_target: "",
            }
        });
        this.filter();
    }

    handleADPlanSelect = (value, entry) => {
        this.setState({
            query: {
                ...this.state.query,
                ad_plan_id: value,
                ad_plan_target: ""
            }
        });
        this.filter();
    }

    handleADPlanTargetSelect = (value, entry) => {
        this.setState({
            query: {
                ...this.state.query,
                ad_plan_target: entry.target || "",
            }
        });
        this.filter();
    }

    handleADNetworkSelect = (value, entry) => {
        this.setState({
            query: {
                ...this.state.query,
                ad_network: value
            }
        });
        this.filter();
    }

    handleDownloadExcel = () => {
        var form = this.queryRef.current;
        var date = form.getFieldValue('date');
        var appId = form.getFieldValue('app_id');
        var placementId = form.getFieldValue('placement_id');
        var start = date[0].format('YYYYMMDD');
        var end = date[1].format('YYYYMMDD');
        this.setState({
            downloading: true
        });
        DownloadRequest(
            "/ad_statistics_analysis/download?start="+start+'&end='+end+"&appid="+appId+"&placementid="+placementId,
            {file_name: 'yuyoumob-'+start+"-"+end+".xlsx"}
        ).then(resp => {
            this.setState({downloading: false});
        }).catch(error => {
            this.setState({downloading: true});
        });
    }

    fetch = (date) => {
        if (!this.state.loading) {
            this.setState({
                loading: true
            });
            if (!!this.queryRef.current) {
                var form = this.queryRef.current;
                date = form.getFieldValue('date');
            }
            if (!!date) {
                var start = date[0].format('YYYYMMDD');
                var end = date[1].format('YYYYMMDD');
                GetRequest('/ad_statistics_analysis?start='+start+'&end='+end)
                    .then(resp => {
                        this.queryResult = resp.data;
                        var allAdList = resp.data.bills.map(entry=>entry.ad_network);
                        var adList = allAdList.filter((entry, i) => !!entry && allAdList.indexOf(entry)===i);
                        var allPlanList = resp.data.bills.map(entry=>entry.plan_id);
                        var planList = allPlanList.filter((entry, i) => !!entry && allPlanList.indexOf(entry)===i);
                        this.setState({
                            loading: false,
                            plan_list: planList,
                            ad_list: adList,
                        });
                        this.filter();
                    })
                    .catch(error => {
                        this.setState({
                            loading: false,
                            plan_list: [],
                            ad_list: [],
                            bill_list: [],
                            show_times_user: [],
                            user_ad_view: [],
                            userAdViewLineData: [],
                            impressionLineData: [],
                            impressionPieData: [],
                            revenueLineData: [],
                            showTimesUserPieData: [],
                        });
                        this.queryResult = [];
                    });
            }
        }
    }

    filter = () => {
        if (!!this.queryRef.current) {
            var form = this.queryRef.current;
            var appId = form.getFieldValue('app_id');
            var placementId = form.getFieldValue('placement_id');
            var adPlanId = form.getFieldValue('ad_plan_id');
            var adPlanTarget = form.getFieldValue('ad_plan_target');
            var adNetwork = form.getFieldValue('ad_network');
            var date = form.getFieldValue('date');
            var bill_list = [];
            var user_ad_view_list = [];
            var start = moment(date[0]);
            var until = moment(date[1]);
            while(start.isSameOrBefore(until)) {
                bill_list.push({
                    date: start.format('YYYY-MM-DD'),
                    requests: 0,
                    fills: 0,
                    impressions: 0,
                    clicks: 0,
                    revenue: 0.00,
                });
                user_ad_view_list.push({
                    date: start.format('YYYY-MM-DD'),
                    active: 0,
                    view: 0,
                });
                start.add(1, 'day');
            }
            var bill_map = {};
            var showTimesUserFilterResult = this.queryResult.show_times_user.filter(entry => {
                if (!!appId && entry.product_uid!==appId) {
                    return false;
                }
                if (!!placementId && entry.placement!==placementId) {
                    return false;
                }
                return true;
            });
            var userAdViewMap = {};
            var userAdViewFilterResult = this.queryResult.user_ad_view.filter(entry => {
                if (!!appId && entry.product_uid!==appId) {
                    return false;
                }
                return true;
            });
            userAdViewFilterResult.reduce((cxt, entry) => {
                var date = moment(entry.day_id, 'YYYYMMDD').format('YYYY-MM-DD');
                if (!cxt[date]) {
                    cxt[date] = {
                        date: date,
                        view: 0,
                        active: 0,
                    };
                }
                cxt[date].active += entry.active;
                cxt[date].view += entry.view;
                return cxt;
            }, userAdViewMap);
            user_ad_view_list = user_ad_view_list.map(entry=>{
                if (!!userAdViewMap[entry.date]) {
                    return userAdViewMap[entry.date];
                }
                return entry;
            });
            var filterResult = this.queryResult.bills.filter(entry => {
                if (!!appId && entry.product_uid !== appId) {
                    return false;
                }
                if (!!placementId && entry.placement_uid !== placementId) {
                    return false;
                }
                if (!!adPlanId && entry.plan_id !== "adplan_"+adPlanId) {
                    return false;
                }
                if (!!adPlanTarget && entry.plan_target !== adPlanTarget) {
                    return false;
                }
                if (!!adNetwork && entry.ad_network !== adNetwork) {
                    return false;
                }
                return true;
            });
            filterResult.reduce((cxt, entry) => {
                var date = moment(entry.day_id, 'YYYYMMDD').format('YYYY-MM-DD');
                if (!cxt[date]) {
                    cxt[date] = {
                        date: date,
                        requests: 0,
                        fills: 0,
                        impressions: 0,
                        clicks: 0,
                        revenue: 0.00
                    };
                }
                if (!!adNetwork && !!entry.fetch) {
                    cxt[date].requests += entry.fetch;
                } else {
                    cxt[date].requests += entry.bill_request;
                }
                if (!!adNetwork && !!entry.load_success) {
                    cxt[date].fills += entry.load_success;
                } else {
                    cxt[date].fills += entry.bill_fill;
                }
                cxt[date].impressions += entry.bill_impression;
                cxt[date].clicks += entry.bill_click;
                cxt[date].revenue += entry.ad_revenue;
                return cxt;
            }, bill_map);
            bill_list = bill_list.map(entry => {
                if (!!bill_map[entry.date]) {
                    return bill_map[entry.date];
                }
                return entry;
            });
            var impressionPieData = null;
            var impressionTotal = filterResult.reduce((total, entry) => total + entry.bill_impression, 0);
            var impressionPieReduce = (key, nkey, total) => {
                return (ret, entry) => {
                    var item = ret.filter(e => e.id===entry[key])[0];
                    if (undefined === item) {
                        item = {
                            id: entry[key],
                            item: entry[nkey],
                            count: 0,
                            percent: 0
                        }
                        ret.push(item);
                    }
                    item.count = item.count + entry.bill_impression;
                    if (total > 0) {
                        item.percent = item.count / total;
                    }
                    return ret;
                };
            };
            if (appId === "") {
                impressionPieData = filterResult.reduce(
                    impressionPieReduce('product_uid', 'product_name', impressionTotal),[]);
            } else if (placementId === "") {
                impressionPieData = filterResult.reduce(
                    impressionPieReduce('placement_uid', 'placement_name', impressionTotal), []);
            } else if (Array.isArray(this.state.plan_list) && this.state.plan_list.length > 0) {
                if (adPlanId === "") {
                    impressionPieData = filterResult.reduce(
                        impressionPieReduce('plan_id', 'ad_plan_name', impressionTotal), []);
                } else if (adPlanTarget === "") {
                    impressionPieData = filterResult.reduce(
                        impressionPieReduce('plan_target', 'plan_target_name', impressionTotal), []);
                } else if (adNetwork === "") {
                    impressionPieData = filterResult.reduce(
                        impressionPieReduce('ad_network', 'ad_network', impressionTotal), []);
                } else {
                    impressionPieData = filterResult.reduce(
                        impressionPieReduce('ad_network', 'ad_network', impressionTotal), []);
                }
            } else {
                impressionPieData = filterResult.reduce(
                    impressionPieReduce('placement_uid', 'placement_name', impressionTotal), []);
            }
            var impressionLineData = [...bill_list.map(entry => ({
                date: entry.date,
                type: _("Request"),
                value: entry.requests
            })), ...bill_list.map(entry => ({
                date: entry.date,
                type: _("Fill"),
                value: entry.fills
            })), ...bill_list.map(entry => ({
                date: entry.date,
                type: _("Show"),
                value: entry.impressions
            }))];
            var revenueLineData = bill_list.map(entry=>({
                date: entry.date,
                type: _("Revenue"),
                value: Number.parseFloat(entry.revenue)
            }));
            var userAdViewLineData = [...user_ad_view_list.map(entry => ({
                date: entry.date,
                type: _("Active User"),
                value: entry.active,
            })), ...user_ad_view_list.map(entry => ({
                date: entry.date,
                type: _("AD View User"),
                value: entry.view,
            }))];
            var showTimesUserTotalCount = showTimesUserFilterResult.reduce((total, entry)=>total+entry.total, 0);
            var showTimesUserPieData = showTimesUserFilterResult.reduce((ret, entry) => {
                var showTimes = entry.show_times;
                if (5 < showTimes && showTimes <= 10) {
                    showTimes = "5-10";
                } else if (10 < showTimes && showTimes <= 20) {
                    showTimes = "10-20";
                } else if (20 < showTimes && showTimes <= 30) {
                    showTimes = "20-30";
                } else if (30 < showTimes) {
                    showTimes = "30+";
                }
                var itemId = ''+showTimes;
                var item = ret.filter(e => e.id===itemId)[0];
                if (undefined === item) {
                    item = {
                        id: itemId,
                        item: showTimes+_("Times"),
                        count: 0,
                        percent: 0
                    }
                    ret.push(item);
                }
                item.count = item.count + entry.total;
                if (showTimesUserTotalCount > 0) {
                    item.percent = item.count / showTimesUserTotalCount;
                }
                return ret;
            }, []);
            this.setState({
                bill_list: bill_list.reverse(),
                user_ad_view: user_ad_view_list.reverse(),
                userAdViewLineData: userAdViewLineData,
                impressionLineData: impressionLineData,
                impressionPieData: impressionPieData,
                revenueLineData: revenueLineData,
                showTimesUserPieData: showTimesUserPieData,
            });
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { RangePicker } = DatePicker;
        const { Title } = Typography;
        const columns = [
            {
                title: _("Date"),
                dataIndex: "date"
            },
            {
                title: _("Request"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN').format(entry.requests)
            },
            {
                title: _("Fill"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN').format(entry.fills)
            },
            {
                title: _("Fill Rate"),
                render: (txt, entry) => {
                    if (entry.requests > 0) {
                        return Number.parseFloat((100.0*entry.fills)/entry.requests).toFixed(2)+'%';
                    } else {
                        return '0%';
                    }
                }
            },
            {
                title: _("Show"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN').format(entry.impressions)
            },
            {
                title: _("Show Rate"),
                render: (txt, entry) => {
                    if (entry.fills > 0) {
                        return Number.parseFloat((100.0*entry.impressions)/entry.fills).toFixed(2)+'%';
                    } else {
                        return '0%';
                    }
                }
            },
            {
                title: _("Revenue"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(entry.revenue)
            },
            {
                title: _("eCPM"),
                render: (txt, entry) => {
                    if (entry.impressions > 0) {
                        return new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(entry.revenue/(entry.impressions/1000.0));
                    } else {
                        return '0.00';
                    }
                }
            }
        ];
        const userAdViewColumns = [
            {
                title: _("Date"),
                dataIndex: "date"
            },
            {
                title: _("AD View User"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN').format(entry.view)
            },
            {
                title: _("Active User"),
                render: (txt, entry) => new Intl.NumberFormat('zh-CN').format(entry.active)
            },
            {
                title: _("AD View Rate"),
                render: (txt, entry) => {
                    if (entry.active > 0) {
                        return Number.parseFloat((100.0*entry.view)/entry.active).toFixed(2)+'%';
                    } else {
                        return '0%';
                    }
                }
            },
        ];
        const pieCols = {
            percent: {
                formatter: val => {
                    return Number.parseFloat(val * 100.0).toFixed(2) + '%';
                },
            },
        };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("AD Statistics Analysis")} key="ad_statistics_analysis">
                        <Row>
                            <Form layout="inline" ref={this.queryRef} initialValues={this.state.query}>
                                <Form.Item name="date" label={_("Date")}>
                                    <RangePicker
                                        size="large" 
                                        style={{width: 300, marginBottom: 16, marginRight: 4}} 
                                        onChange={this.handleDateSelect}
                                     />
                                </Form.Item>
                                <Divider orientation="left">{_("Filter by application and placement")}</Divider>
                                <Form.Item name="app_id" label={_("Select Product")}>
                                    <AppSelect 
                                        size="large" 
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        user_id={this.state.query.user_id}
                                        value={this.state.query.app_id}
                                        onChange={this.handleApplicationSelect} />
                                </Form.Item>
                                <Form.Item name="placement_id" label={_("Select Placement")}>
                                    <PlacementSelect
                                        size="large"
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        product_uid={this.state.query.app_id}
                                        value={this.state.query.placement_id}
                                        onChange={this.handleADPlacementSelect} />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        icon={<CloudDownloadOutlined />}
                                        size="large"
                                        type="primary"
                                        loading={this.state.downloading}
                                        onClick={this.handleDownloadExcel}
                                    >{_("Download Excel")}</Button>
                                </Form.Item>
                                {Array.isArray(this.state.plan_list) && this.state.plan_list.length > 0 && (
                                <Divider orientation="left">{_("Filter by AD plan and target")}</Divider>
                                )}
                                {Array.isArray(this.state.plan_list) && this.state.plan_list.length > 0 && (
                                <Form.Item name="ad_plan_id" label={_("Select AD Plan")}>
                                    <ADPlanSelect
                                        size="large"
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        product_uid={this.state.query.app_id}
                                        placement_uid={this.state.query.placement_id}
                                        value={this.state.query.ad_plan_id}
                                        onChange={this.handleADPlanSelect} />
                                </Form.Item>
                                )}
                                {Array.isArray(this.state.plan_list) && this.state.plan_list.length > 0 && (
                                <Form.Item name="ad_plan_target" label={_("Select AD Plan Target")}>
                                    <ADPlanTargetSelect
                                        size="large"
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        product_uid={this.state.query.app_id}
                                        ad_plan_id={this.state.query.ad_plan_id}
                                        value={this.state.query.ad_plan_target}
                                        onChange={this.handleADPlanTargetSelect} />
                                </Form.Item>
                                )}
                                {Array.isArray(this.state.ad_list) && this.state.ad_list.length > 0 && (
                                <Divider orientation="left">{_("Filter by AD network")}</Divider>
                                )}
                                {Array.isArray(this.state.ad_list) && this.state.ad_list.length > 0 && (
                                <Form.Item name="ad_network" label={_("Select AD Network")}>
                                    <ADNetworkSelect
                                        size="large"
                                        style={{width: 200, marginBottom: 16, marginRight: 4}} 
                                        showall="true"
                                        ad_include={this.state.ad_list}
                                        value={this.state.query.ad_network}
                                        onChange={this.handleADNetworkSelect} />
                                </Form.Item>
                                )}
                            </Form>
                        </Row>
                        <Alert 
                            style={{marginBottom:24}} 
                            message={_("Statistics Analysis Revenue Warning")}
                            type="warning"
                            closable
                            showIcon />
                        <Alert 
                            style={{marginBottom:24}} 
                            message={_("Statistics Analysis Currency Warning")}
                            type="warning"
                            closable
                            showIcon />
                        <Title level={3} style={{marginTop:24, marginBottom:24}}>{_("AD Analysis")}</Title>
                        <Table
                            rowKey="date"
                            columns={columns}
                            dataSource={this.state.bill_list}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            summary={pageData => {
                                var totalRequests = 0;
                                var totalFills = 0;
                                var totalImpressions = 0;
                                var totalRevenue = 0.0;
                                pageData.forEach(({requests, fills, impressions, revenue}) => {
                                    totalRequests += requests;
                                    totalFills += fills;
                                    totalImpressions += impressions;
                                    totalRevenue += revenue;
                                });
                                return (<Table.Summary.Row>
                                    <Table.Summary.Cell>{_("Total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalRequests}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalFills}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalRequests > 0 ? Number.parseFloat((100.0*totalFills)/totalRequests).toFixed(2)+'%' : '0%'}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalImpressions}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalFills > 0 ? Number.parseFloat((100.0*totalImpressions)/totalFills).toFixed(2)+'%' : '0%'}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(totalRevenue)}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalImpressions > 0 ? new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(totalRevenue/(totalImpressions/1000)) : new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(0)}</Table.Summary.Cell>
                                </Table.Summary.Row>);
                            }}
                        />
                        <Divider style={{marginTop:24, marginBottom:24}}>{_("AD Analysis")}</Divider>
                        <Chart height={480} autoFit data={this.state.impressionLineData} >
                            <Tooltip shared />
                            <Interval position="date*value" color="type" />
                        </Chart>
                        <Title level={3} style={{marginTop:24, marginBottom:24}}>{_("AD Analysis AD View User")}</Title>
                        <Table
                            rowKey="date"
                            columns={userAdViewColumns}
                            dataSource={this.state.user_ad_view}
                            loading={this.state.loading}
                            pagination={this.state.pagination}
                            summary={pageData => {
                                var totalActive = 0;
                                var totalView = 0;
                                pageData.forEach(({active, view}) => {
                                    totalActive += active;
                                    totalView += view;
                                });
                                return (<Table.Summary.Row>
                                    <Table.Summary.Cell>{_("Total")}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalView}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalActive}</Table.Summary.Cell>
                                    <Table.Summary.Cell>{totalActive > 0 ? Number.parseFloat((100.0*totalView)/totalActive).toFixed(2)+'%' : '0%'}</Table.Summary.Cell>
                                </Table.Summary.Row>);
                            }}
                        />
                        <Divider style={{marginTop:24, marginBottom:24}}>{_("AD Analysis AD View User")}</Divider>
                        <Chart height={480} autoFit data={this.state.userAdViewLineData} >
                            <Tooltip shared />
                            <Interval position="date*value" color="type" />
                        </Chart>
                        <Row>
                            <Col span={12}>
                        <Divider style={{marginTop:24, marginBottom:24}}>{_("AD Impression Percentage")}</Divider>
                        <Chart height={400} data={this.state.impressionPieData} scale={pieCols} autoFit>
                            <Coordinate type="theta" radius={0.75} />
                            <Tooltip showTitle={false} />
                            <Axis visible={false} />
                            <Interval
                                position="percent"
                                adjust="stack"
                                color="item"
                                style={{
                                    lineWidth: 1,
                                    stroke: '#fff',
                                }}
                                label={['count', {
                                    content: (data) => {
                                        return `${data.item} - ${_("Show")} : ${data.count}`;
                                    },
                                }]}
                            />
                            <Interaction type='element-single-selected' />
                        </Chart>
                        </Col>
                        <Col span={12}>
                        <Divider style={{marginTop:24, marginBottom:24}}>{_("AD Show Times User Percentage")}</Divider>
                        <Chart height={400} data={this.state.showTimesUserPieData} scale={pieCols} autoFit>
                            <Coordinate type="theta" radius={0.75} />
                            <Tooltip showTitle={false} />
                            <Axis visible={false} />
                            <Interval
                                position="percent"
                                adjust="stack"
                                color="item"
                                style={{
                                    lineWidth: 1,
                                    stroke: '#fff',
                                }}
                                label={['count', {
                                    content: (data) => {
                                        return `${data.item} : ${data.count} ${_("Users")}`;
                                    },
                                }]}
                            />
                            <Interaction type='element-single-selected' />
                        </Chart>
                        </Col>
                        </Row>
                        <Title level={3} style={{marginTop:24, marginBottom:24}}>{_("Revenue Analysis")}</Title>
                        <Divider style={{marginTop:24, marginBottom:24}}>{_("Revenue Analysis")}</Divider>
                        <Chart height={480} autoFit data={this.state.bill_list} >
                            <Tooltip title={_("Revenue")} />
                            <Line position="date*revenue" tooltip={['date*revenue', (d, v) => ({name:d, value: new Intl.NumberFormat('zh-CN', {style: 'currency', currency: 'CNY'}).format(v)})]} />
                        </Chart>
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}
