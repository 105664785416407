import React from 'react';
import { Select } from 'antd';
import { GetRequest } from './Ajax';
import { trans as _ } from './i18n';

const { Option } = Select;

export class ADNetworkSelect extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            options: []
        };
        this.unmount = false;
        this.adNetworkMap = {};
    };

    fetch = ()=>{
        var url = '/adnetwork?only_enable=yes';
        if (!!this.props.get_all) {
            url = '/adnetwork';
        }
        GetRequest(url)
        .then(resp => {
            var options = [];
            if (this.props.showall==='true') {
                options.push(
                    <Option key="adnetwork_all" value="">
                        {_("All")}
                    </Option>
                );
            }
            this.adNetworkMap = {};
            for (var i in resp.data) {
                var entry = resp.data[i];
                this.adNetworkMap[entry.uid] = entry;
                if (Array.isArray(this.props.ad_include) && this.props.ad_include.indexOf(entry.uid) < 0) {
                    continue;
                }
                options.push(
                    <Option key={entry.uid} value={entry.uid}>
                        {entry.name}
                    </Option>
                );
            }
            if (!this.unmount) {
                this.setState({
                    options: options
                });
            }
        });
    };

    componentDidMount(){
        this.fetch();
    };

    componentWillUnmount(){
        this.unmount = true;
    }

    handleProductChange(value){
        if (!!this.props.onChange) {
            this.props.onChange(value, this.adNetworkMap[value]);
        }
    };

    render() {
        return (
            <Select
                {...this.props}
                showSearch
                placeholder={_("Select AD Network")}
                optionFilterProp="children"
                onChange={(v)=>{this.handleProductChange(v)}}
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
            >
                {this.state.options}
            </Select>
        );
    };
};
