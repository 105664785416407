import React from 'react';
import { Row, Modal, Button, Tabs, Table, Form, Input, Select, Switch, Space, Tooltip, message, Typography } from 'antd';
import { PlusOutlined, EditOutlined, CloseOutlined, SaveOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { AppSelect } from './AppSelect';
import { GetRequest, PostRequest } from './Ajax';
import { getState } from './Reducer';
import { trans as _ } from './i18n';

const state = getState();

export class Parameter extends React.Component {

    formRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            saveLoading: false,
            loading: false,
            user_id: state.getState().userId,
            product: {},
            placements: [],
            parameters: [],
            adnetworks: [],
            ad: null,
            status: true,
            required_parameters: [],
            placement_parameters: [],
            placement_keys: [],
            packageInfoList: [],
            packageInfoLoading: false
        };
        this.unsubscribe = null;
    };

    componentDidMount = () => {
        this.unsubscribe = state.subscribe(()=>{
            if (!!state.getState().token) {
                this.setState({
                    user_id: state.getState().userId,
                    product: {},
                    parameters: [],
                    placements: []
                });
            }
        });
    }

    componentWillUnmount = () => {
        if (!!this.unsubscribe) {
            this.unsubscribe();
        }
    }

    handleSelectProduct = (puid) => {
        if (!!puid) {
            this.setState({loading: true});
            GetRequest('/parameter/'+puid)
                .then(resp => {
                    this.setState({
                        loading: false,
                        ...resp.data
                    });
                }).catch(error => {
                    this.setState({loading: false});
                });
            this.setState({packageInfoLoading: true});
            GetRequest('/packageinfo/'+puid)
                .then(resp => {
                    this.setState({
                        packageInfoLoading: false,
                        packageInfoList: resp.data
                    });
                }).catch(error => {
                    this.setState({loading: false});
                });
        }
    }

    handleCreateNew = () => {
        this.handleEdit("");
    }

    handleEdit = (ad) => {
        var parameters = {};
        var adParameters = this.state.parameters.filter((e,i) => e.ad===ad)[0];
        if (!!adParameters && adParameters.parameters) {
            parameters =  JSON.parse(adParameters.parameters);
        }
        var required_parameters = [];
        var placement_parameters = [];
        var placement_keys = [];
        if (!!ad) {
            var ad_template = this.state.adnetworks.filter(entry=>entry.uid===ad)[0];
            if (!!ad_template) {
                if (!!ad_template.required_keys) {
                    ad_template["required_params"] = JSON.parse(ad_template.required_keys);
                } else {
                    ad_template["required_params"] = [];
                }
                if (!!ad_template.placement_keys) {
                    ad_template["placement_params"] = JSON.parse(ad_template.placement_keys);
                } else {
                    ad_template["placement_params"] = [];
                }
                required_parameters = ad_template.required_params.map(k => {
                    var v = "";
                    if (!!parameters && !!parameters.required) {
                        v = parameters.required[k];
                    }
                    if (!!v) {
                        if (Array.isArray(v)) {
                            v = v[0];
                        }
                    } else {
                        v = "";
                    }
                    return {key: k, value: v};
                });
                placement_keys = ad_template.placement_params;
                var placements = this.state.placements || [];
                placement_parameters = placements.map(placement => {
                    var placementParams = [];
                    if (!!parameters && !!parameters.placements) {
                        placementParams = parameters.placements[placement.uid] || [];
                    }
                    if (!Array.isArray(placementParams)) {
                        placementParams = (""+placementParams).split(",");
                    }
                    placementParams = placementParams.map(entry=>{
                        if (typeof(entry)==="object") {
                            return entry;
                        }
                        if (typeof(entry)==="string") {
                            return Object.fromEntries(ad_template.placement_params.map(k=>[k, entry]));
                        }
                        return Object.fromEntries(ad_template.placement_params.map(k=>[k, ""]));
                    });
                    if (placementParams.length === 0) {
                        placementParams = [
                            Object.fromEntries(ad_template.placement_params.map(k=>[k, ""]))
                        ];
                    }
                    return {
                        key: placement.uid,
                        value: placementParams,
                        name: placement.name
                    };
                });
            }
        }
        var status = true;
        if (!!adParameters) {
            status = adParameters.status;
        }
        this.setState({
            modalVisible: true,
            ad: ad,
            status: status,
            required_parameters: required_parameters,
            placement_parameters: placement_parameters,
            placement_keys: placement_keys
        });
        if (!!this.formRef.current) {
            setTimeout(()=>{
                this.formRef.current.resetFields();
            }, 0);
        }
    }

    handleModalClose = () => {
        this.setState({modalVisible: false});
    }

    handleModalSave = () => {
        this.formRef.current.submit();
    }

    handleModalFinish = (values) => {
        var data = {
            ad: values.ad,
            status: values.status
        }
        var parameters = {
            required: {},
            placements: {}
        };
        for (var j in values.required_parameters) {
            var ad_template = this.state.adnetworks.filter(entry=>entry.uid===values.ad)[0];
            var required = ad_template.required_params[j];
            if (!!required) {
                parameters.required[required] = [values.required_parameters[j].value];
            }
        }
        for (var i in values.placement_parameters) {
            var placement = this.state.placements[i];
            if (!!placement) {
                var params = values.placement_parameters[i];
                if (!!params && !!params.value) {
                    parameters.placements[placement.uid] = params.value.filter((v,i)=>!!v);
                }
            }
        }
        data["data"] = parameters;
        this.setState({saveLoading: true});
        PostRequest('/parameter/'+this.state.product.uid, data)
            .then(resp => {
                var parameters = [];
                if (!!this.state.parameters.filter(p=>p.ad===resp.data.ad)[0]) {
                    parameters = this.state.parameters.map(p => {
                        if (p.ad===resp.data.ad) {
                            return resp.data;
                        } else {
                            return p;
                        }
                    })
                } else {
                    parameters = [resp.data, ...this.state.parameters];
                }
                this.setState({
                    saveLoading: false,
                    parameters: parameters
                });
                message.success(_("Save successful"));
            })
            .catch(error => {
                this.setState({saveLoading: false});
                message.error(_("Save failure"));
            });
    }

    render(){
        const columns = [
            {
                title: _("AD Name"),
                dataIndex: "ad"
            },
            {
                title: _("Status"),
                render: (text, obj) => {
                    return (<Switch checked={obj.status} onChange={(checked, evt)=>{
                        PostRequest('/parameter/'+this.state.product.uid+'/'+obj.ad, {status: checked})
                            .then(resp => {
                                this.setState({parameters: this.state.parameters.map(p => {
                                    if (resp.data.ad === p.ad) {
                                        return resp.data;
                                    }
                                    return p;
                                })});
                            });
                    }} />);
                }
            },
            {
                render: (text, obj) => {
                    return (<Button icon={<EditOutlined />} onClick={(evt)=>{this.handleEdit(obj.ad);}}>{_("Edit")}</Button>);
                }
            }
        ];
        const packageInfoColumns = [
            {
                title: _("Bundle ID"),
                dataIndex: "bundle_id"
            },
            {
                title: _("Bundle Version"),
                dataIndex: "bundle_version"
            },
            {
                title: _("SDK Version"),
                dataIndex: "sdk_version"
            },
            {
                title: _("AD Adapters"),
                dataIndex: "ad_adapters"
            },
            {
                title: _("App Sign"),
                dataIndex: "app_sign"
            },
            {
                title: _("Date"),
                dataIndex: "update_time"
            }
        ];
        const { TabPane } = Tabs;
        const { Option } = Select;
        const { Title }= Typography;
        const formItemLayout = {
            labelCol: {
              xs: { span: 14 },
              sm: { span: 6 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
              xs: { span: 24, offset: 14 },
              sm: { span: 16, offset: 6 },
            },
          };
        return (
            <div className="card-container">
                <Tabs type="card">
                    <TabPane tab={_("Manage AD Parameter")} key="manage_parameter">
                        <Row>
                            <AppSelect
                                size="large"
                                style={{width: 400, marginBottom: 16, marginRight: 4}}
                                user_id={this.state.user_id}
                                value={this.state.product.uid}
                                onChange={this.handleSelectProduct} />
                            <Button
                                icon={<PlusOutlined />}
                                size="large"
                                type="primary"
                                disabled={ !this.state.product.uid || this.state.product.uid.length===0 || !this.state.placements|| this.state.placements.length===0 }
                                onClick={this.handleCreateNew}
                            >{_("Create New Parameter")}</Button>
                        </Row>
                        <Title level={2}>{_("Product Package Info")}</Title>
                        <Table
                            rowKey="id"
                            columns={packageInfoColumns}
                            dataSource={this.state.packageInfoList}
                            loading={this.state.packageInfoLoading}
                            pagination={false}
                        />
                        <Title level={2} style={{marginTop:'24px'}}>{_("Manage AD Parameter")}</Title>
                        <Table
                            rowKey="ad"
                            columns={columns}
                            dataSource={this.state.parameters}
                            loading={this.state.loading}
                            pagination={false}
                        />
                        <Modal
                            visible={this.state.modalVisible}
                            title={this.state.product?this.state.product.name||this.state.product.uid:""}
                            width={"70%"}
                            onCancel={this.handleModalClose}
                            onOk={this.handleModalSave}
                            footer={[
                                <Button key="close" icon={<CloseOutlined />} onClick={this.handleModalClose}>{_("Close")}</Button>,
                                <Button type="primary" icon={<SaveOutlined />} key="save" loading={this.state.saveLoading} onClick={this.handleModalSave}>{_("Save")}</Button>,
                            ]}
                        >
                            <Form {...formItemLayout} ref={this.formRef} onFinish={this.handleModalFinish} initialValues={this.state}>
                                <Form.Item label={_("AD Name")} name="ad">
                                    <Select
                                        showSearch
                                        placeholder={_("Select AD Network")}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onSelect={(value, option) => {
                                            this.handleEdit(value);
                                        }}
                                    >
                                        {this.state.adnetworks.map((entry) => (
                                            <Option key={entry.uid}>{entry.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label={_("Enable or not")} name="status" valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                                {this.state.required_parameters.map((param, i) => (
                                <Form.Item label={param.key} key={param.key} name={["required_parameters", i, "value"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: _("Required and not empty")
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                ))}
                                {this.state.placement_parameters.map((param, i) => {
                                return (<Form.List
                                    key={param.key}
                                    name={["placement_parameters", i, "value"]}
                                    >
                                    {(fields, {add, remove}) => {
                                        return (<div>
                                            {fields.map((field, idx) => (
                                                <Form.Item
                                                    {...(idx === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                    label={idx === 0 ? <Tooltip title={param.key}>{param.name||param.key}</Tooltip> : ''}
                                                    required={false}
                                                    key={param.key+"__"+idx}
                                                >
                                                    <Space key={field.key} style={{ display: 'flex', marginBottom: 4 }} align="start">
                                                        {this.state.placement_keys.map(pk=>{
                                                            return (<Tooltip key={pk+"_"+idx} placement="topLeft" title={pk}>
                                                                <Form.Item
                                                                    {...field}
                                                                    key={pk+"_"+idx}
                                                                    name={[field.name, pk]}
                                                                    fieldKey={[field.fieldKey, pk]}>

                                                                    <Input placeholder={pk} />
                                                                </Form.Item>
                                                            </Tooltip>);
                                                        })}
                                                        {fields.length > 1 ? (
                                                            <MinusCircleOutlined
                                                                className="form-item-delete-btn"
                                                                style={{ margin: '0 8px' }}
                                                                onClick={() => {
                                                                    remove(field.name);
                                                                }}
                                                             />
                                                        ) : null}
                                                    </Space>
                                                </Form.Item>
                                            ))}
                                            <Form.Item {...formItemLayoutWithOutLabel}>
                                                <Button
                                                    type="dashed"
                                                    onClick={()=>{
                                                        add();
                                                    }}
                                                    style={{ width: '86%' }}
                                                >
                                                    <PlusOutlined />{_("Add")}
                                                </Button>
                                            </Form.Item>
                                        </div>);
                                    }}
                                </Form.List>
                                );})}
                            </Form>
                        </Modal>
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}